const locale = {
	ADD_NEW: 'Új hozzáadása',
	ADD_NEW_SHORT: 'Új',
	SEARCH: 'Keresés',
	BACK: 'Vissza',
	FILTER: 'Szűrés',
	FILTERS: 'Szűrők',
	FILTER_CONDITIONS: 'Szűrési feltételek',
	CLEAR_FILTERS: 'Szűrők törlése',
	REFRESH: 'Frissítés',
	IMPORT: 'Importálás',
	IMPORT_FILE_UPLOADING_ERROR: 'Hiba történt a feltöltés során.',
	IMPORT_DONE: 'Sikeres importálás',
	EXPORT: 'Exportálás',
	EXPORT_TO_PDF: 'Exportálás PDF-be',
	NEW_FOLDER: 'Új mappa',
	NEW_FILE: 'Új fájl',
	NO_DATA: 'Nincsenek adatok',
	ROWS_PER_PAGE: 'Egyszerre látható',
	PREV_PAGE: 'Előző oldal',
	NEXT_PAGE: 'Következő oldal',
	DELETE: 'Törlés',
	DELETE_DESC: 'Biztosan törli a kiválasztott tételt?',
	DELETE_FAILED: 'A tétel törlése nem sikerült.',
	OPEN: 'Megnyitás',
	MODIFY: 'Módosítás',
	SEARCH_FIELDS: 'Keresőmezők',
	SORT: 'Rendez',
	ASC: 'Növekvő',
	DESC: 'Csökkenő',
	DATE_FROM: 'Dátumtól',
	DATE_TO: 'Dátumig'
};

export default locale;
