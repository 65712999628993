import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DistanceService from 'app/admin/valueset/service/DistanceService';
import { useEffect, useState } from 'react';

const childDistance = ['újonc', 'gyermek', 'serdülő'];

const getDistanceNameWithGender = (distance, male) => {
	let isChild = false;
	childDistance.forEach(cd => {
		if (distance.name.startsWith(cd)) isChild = true;
	});
	if (male) {
		if (isChild) return `${distance.name} fiú`;
		return `${distance.name} férfi`;
	}
	if (isChild) return `${distance.name} lány`;
	return `${distance.name} nő`;
};

const findDistanceTableRow = (data, distanceItem, male) => {
	const licenseTypeList = [];
	let found = false;
	data.raceList.forEach(race => {
		const genderValid = (male && race.isMale) || (!male && race.isFemale);
		if (genderValid) {
			race.competitionPartKeyList.forEach(comPartKey => {
				const competitionPart = data.competitionPartList.find(d => d.key === comPartKey);
				if (competitionPart && competitionPart.distance.id === distanceItem.distance.id) {
					found = true;
					race.licenseTypeList.forEach(licenseType => {
						if (licenseTypeList.indexOf(licenseType) === -1) licenseTypeList.push(licenseType);
					});
				}
			});
		}
	});
	if (found) {
		return {
			...distanceItem,
			name: getDistanceNameWithGender(distanceItem.distance, male),
			licenseTypeList
		};
	}
	return null;
};

export default function CalendarViewDistanceList(props) {
	const { data } = props;
	const [distanceValuesSet, setDistanceValueSet] = useState(null);
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		if (distanceValuesSet == null) {
			DistanceService.findAll().then(resp => {
				setDistanceValueSet(resp);
			});
		}
	}, []);

	useEffect(() => {
		if (data && distanceValuesSet) {
			const _tableData = [];
			distanceValuesSet.forEach(distanceValue => {
				const distance = data.distanceList.find(d => d.distance.id === distanceValue.id);
				if (distance) {
					const maleResult = findDistanceTableRow(data, distance, true);
					if (maleResult) _tableData.push(maleResult);
					const femaleResult = findDistanceTableRow(data, distance, false);
					if (femaleResult) _tableData.push(femaleResult);
				}
			});
			setTableData(_tableData);
		} else {
			setTableData([]);
		}
	}, [data, distanceValuesSet]);

	if (tableData) {
		return (
			<TableContainer>
				<div className="bg-gray-200 rounded-8 m-16 shadow-xl">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell className="font-bold">Korcsoport</TableCell>
								<TableCell className="font-bold text-center" colSpan={2}>
									Úszás
									<br />
									táv(km) / kör
								</TableCell>
								<TableCell className="font-bold text-center" colSpan={3}>
									Kerékpár
									<br />
									táv(km) / kör / szint (m)
								</TableCell>
								<TableCell className="font-bold text-center" colSpan={3}>
									Futás
									<br />
									táv(km) / kör / szint (m)
								</TableCell>
								<TableCell className="font-bold">Lic.</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableData.map((row, rowNum) => (
								<TableRow key={row.name} className={`${(rowNum + 1) % 2 === 0 ? '' : 'bg-gray-100'}`}>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.swimmingDistance}</TableCell>
									<TableCell>{row.swimmingRound}</TableCell>
									<TableCell>{row.cyclingDistance}</TableCell>
									<TableCell>{row.cyclingRound}</TableCell>
									<TableCell>{row.cyclingLevelRise}</TableCell>
									<TableCell>{row.runningDistance}</TableCell>
									<TableCell>{row.runningRound}</TableCell>
									<TableCell>{row.runningLevelRise}</TableCell>
									<TableCell>{row.licenseTypeList.join(', ')}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</TableContainer>
		);
	}
	return <></>;
}
