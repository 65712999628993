import NamedDto from 'modules/base/dto/NamedDto';

export class PersonQueryDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.email = null;
		/** @type {string} */
		this.phoneNumber = null;
	}

	/**
	 * @typedef {Object} _PersonQueryDtoProps
	 * @property {string} email
	 * @property {string} phoneNumber
	 * @typedef {import("../NamedDto").NamedDtoProps & _PersonQueryDtoProps} PersonQueryDtoProps
	 */
	/**
	 *
	 * @param {PersonQueryDtoProps} props
	 * @returns {PersonQueryDto}
	 */
	setup(props) {
		super.setup(props);
		this.email = props.email;
		this.phoneNumber = props.phoneNumber;
		return this;
	}
}

export default PersonQueryDto;
