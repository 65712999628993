const regHu = {
	athleteRegTitle: 'Versenyzői regisztráció',
	back: 'Vissza',
	login: 'Bejelentkezés',
	lastName: 'Vezetéknév',
	firstName: 'Keresztnév',
	birthPlace: 'Születési hely',
	birthDate: 'Születési idő',
	gender: 'Nem',
	email: 'E-mail cím',
	phoneNumber: 'Telefonszám',
	nationality: 'Állampolgárság',
	nationalityOther: 'Állampolgárság egyéb',
	sepMothersName: 'Anyja neve',
	motherLastName: 'Vezetéknév',
	motherFirstName: 'Keresztnév',
	sepPassword: 'Jelszó',
	password: 'Jelszó',
	passwordAgain: 'Jelszó mégegyszer',
	sepAddress: 'Cím',
	addressZipcode: 'Irányítószám',
	addressCity: 'Város',
	addressCounty: 'Megye',
	addressStreet: 'Utca',
	addressStreetType: 'Típus',
	addressNumber: 'Házszám',
	addressFloor: 'Emelet',
	addressDoor: 'Ajtó',
	profilePicture: 'Profilkép',
	competition: 'Verseny',
	accept: 'A Magyar Triatlon Szövetség alapszabályzatát megismertem, tartalmát elfogadom, és magamra kötelező érvényűnek tekintem.',
	registration: 'Regisztráció'
};

export default regHu;
