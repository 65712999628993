import NamedDto from 'modules/base/dto/NamedDto';

export default class EmailTemplateTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.locale = null;
		/** @type {string} */
		this.subject = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Date} */
		this.updated = null;
	}

	/**
	 * @typedef {Object} _EmailTemplateTableDtoProps
	 * @property {string} locale
	 * @property {string} subject
	 * @property {Date} created
	 * @property {Date} updated
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _EmailTemplateTableDtoProps} EmailTemplateTableDtoProps
	 */
	/**
	 *
	 * @param {EmailTemplateTableDtoProps} props
	 * @returns {EmailTemplateTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.locale = props.locale;
		this.subject = props.subject;
		this.created = props.created != null ? new Date(props.created) : null;
		this.updated = props.updated != null ? new Date(props.updated) : null;
		return this;
	}
}
