/* eslint import/no-extraneous-dependencies: off*/
import { createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import AccessManagementService from 'app/admin/user/service/AccessManagementService';
import UserLoginService from 'app/admin/user/service/UserLoginService';
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice';

export const getUserData = () => async (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		AccessManagementService.getAccessConfig().then(accessConfig => {
			const usersSettings = JSON.parse(localStorage.getItem('userSettings'));
			dispatch(
				setUserData({
					role: accessConfig.serviceList,
					data: { ...accessConfig.user, ...{ settings: usersSettings || {} } },
					roleList: accessConfig.roleList,
					isSuperAdmin: accessConfig.isSuperAdmin,
					isClub: accessConfig.isClub,
					isOrganizer: accessConfig.isOrganizer,
					isAthlete: accessConfig.isAthlete,
					isCompetitor: accessConfig.isCompetitor,
					isReferee: accessConfig.isReferee,
					isTrainer: accessConfig.isTrainer,
					isTechnicalCommittee: accessConfig.isTechnicalCommittee,
					accessType: accessConfig.accessType,
					athleteRegType: accessConfig.athleteRegType
				})
			);
			resolve();
		});
	});
};

export const setUserData = user => async (dispatch, getState) => {
	/*
        You can redirect the logged-in user to a specific route depending on his role
	*/

	history.location.state = {
		redirectUrl: user.redirectUrl // for example 'apps/academy'
	};

	/*
	    Set User Settings
     */
	dispatch(setDefaultSettings(user.data.settings));

	dispatch(setUser(user));
};

export const logoutUser = () => async (dispatch, getState) => {
	const { user } = getState().auth;

	if (!user.role || user.role.length === 0) {
		// is guest
		return null;
	}

	history.push({
		pathname: '#'
	});

	UserLoginService.logout();
	dispatch(setInitialSettings());
	return dispatch(userLoggedOut());
};

export const updateUserSettings = settings => async (dispatch, getState) => {
	const oldUser = getState().auth.user;
	const user = _.merge({}, oldUser, { data: { settings } });

	dispatch(updateUserData(user));

	return dispatch(setUserData(user));
};

export const updateUserShortcuts = shortcuts => async (dispatch, getState) => {
	/*const { user } = getState().auth;
	const newUser = {
		...user,
		data: {
			...user.data,
			shortcuts
		}
	};

	dispatch(updateUserData(user));

	return dispatch(setUserData(newUser));*/
};

export const updateUserData = user => async (dispatch, getState) => {
	if (!user.role || user.role.length === 0) {
		// is guest
		return;
	}
	localStorage.setItem('userSettings', JSON.stringify(user.data.settings));

	/*switch (user.from) {
		case 'firebase': {
			firebaseService
				.updateUserData(user)
				.then(() => {
					dispatch(showMessage({ message: 'User data saved to firebase' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
		case 'auth0': {
			auth0Service
				.updateUserData({
					settings: user.data.settings,
					shortcuts: user.data.shortcuts
				})
				.then(() => {
					dispatch(showMessage({ message: 'User data saved to auth0' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
		default: {
			jwtService
				.updateUserData(user)
				.then(() => {
					dispatch(showMessage({ message: 'User data saved with api' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
	}*/
};

const initialState = {
	role: [],
	data: {
		name: 'Guest'
	}
};

const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => action.payload,
		userLoggedOut: (state, action) => initialState
	},
	extraReducers: {}
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
