import NamedDto from 'modules/base/dto/NamedDto';

export class OrgTransferListDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.fullName = null;
		/** @type {string} */
		this.shortName = null;
	}

	/**
	 * @typedef {Object} OrgTransferListDto
	 * @property {string} fullName
	 * @property {string} shortName
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & OrgTransferListDto} OrgTransferListDtoProps
	 */
	/**
	 *
	 * @param {OrgTransferListDtoProps} props
	 * @returns {OrgTransferListDto}
	 */
	setup(props) {
		super.setup(props);
		this.fullName = props.fullName;
		this.shortName = props.shortName;
		return this;
	}
}
export default OrgTransferListDto;
