import { authRoles } from 'app/auth';
import CalendarEventViewPage from './calendar/CalendarEventViewPage';
import CalendarPage from './calendar/CalendarPage';
import CompetitionEditForm from './editform/CompetitionEditForm';
import CompetitionTable from './table/CompetitionTable';
import CompetitionEntryTable from './table/CompetitionEntryTable';
import CompetitionEntryEditForm from './editform/CompetitionEntryEditForm';
import CompetitionEntryAddForm from './editform/CompetitionEntryAddForm';
import CompetitionEntryClubTable from './table/CompetitionEntryClubTable';

export default [
	{
		path: '/competition-entry-club/:sessionNameParam?',
		component: CompetitionEntryClubTable,
		auth: authRoles.competitionEntryClub
	},
	{
		path: '/competition-entry/edit/:competitionId/new',
		component: CompetitionEntryAddForm,
		auth: authRoles.competition
	},
	{
		path: '/competition-entry/edit/:competitionId/:id',
		component: CompetitionEntryEditForm,
		auth: authRoles.competition
	},
	{
		path: '/competition-entry/:competitionId/:sessionNameParam?',
		component: CompetitionEntryTable,
		auth: authRoles.competition
	},
	{
		path: '/competition/edit/:id',
		component: CompetitionEditForm,
		auth: authRoles.competition
	},
	{
		path: '/competition/:sessionNameParam?',
		component: CompetitionTable,
		auth: authRoles.competition
	},
	{
		path: '/calendar/view/:id',
		component: CalendarEventViewPage,
		auth: authRoles.competitionCalendar
	},
	{
		path: '/calendar',
		component: CalendarPage,
		auth: authRoles.competitionCalendar
	}
];
