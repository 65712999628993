import nBossConnection from 'modules/base/service/nBossConnection';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableFilterListDto from 'modules/base/dto/table/TableFilterListDto';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import UserStatusType from 'app/admin/common/dto/UserStatusType';
import OrganizationTableService from '../service/OrganizationTableService';

const config = {
	key: 'organizerTable',
	text: 'Versenyszervező',
	textKey: 'organizer',
	service: OrganizationTableService,
	icon: 'person',
	editFormState: item => `/organizer/edit/${item ? item.id : 'new'}`,
	exportList: sessionName => `${nBossConnection.basePath}orgTableControl/exportXlsx?session=${sessionName}`,
	defaultFilter: () => {
		return new TableFilterListDto().setup({
			list: [
				{
					propertyName: 'isOrganizer',
					type: 'EQ',
					value: true,
					permanent: true
				}
			],
			activeCount: 1
		});
	},
	toolbarAction: ['filter', 'search', 'refresh', 'export', 'new'],
	filterFields: [
		{
			text: 'Státusz',
			propertyName: 'userStatus',
			propertyType: 'String',
			type: 'IN',
			items: Object.keys(UserStatusType).map(key => {
				return { label: UserStatusType[key], value: key };
			})
		},
		{
			text: 'Fizetve?',
			propertyName: 'cachedCurrentLicensePaymentPaid',
			propertyType: 'Boolean',
			type: 'EQ',
			trueLabel: 'Fizetve',
			falseLabel: 'Nem fizetve'
		}
	],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'fullName',
			text: 'Teljes név',
			textKey: 'fullName',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'shortName',
			text: 'Rövid név',
			textKey: 'shortName',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'cachedAddress',
			text: 'Bejegyzett cím',
			textKey: 'cachedAddress',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'userStatus',
			text: 'Státusz',
			textKey: 'userStatus',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'cachedCurrentLicensePaymentPaid',
			text: 'Fizetve?',
			textKey: 'cachedCurrentLicensePaymentPaid',
			type: 'text',
			sortable: true,
			disablePadding: false
		}
	]
};

function OrganizerTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('organizerTable', reducer('organizerTable'))(OrganizerTable);
