import { useFormContext } from 'react-hook-form';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormSeparatorField from 'modules/ui/editform/fields/EditFormSeparatorField';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import EditFormSubComponent from 'modules/ui/editform/subForm/EditFormSubComponent';
import CompetitionDistanceNameField from './CompetitionDistanceNameField';

const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
const blockTitleClassName = 'py-8';

function CompetitionDistanceEditForm(data) {
	return {
		key: 'distanceList',
		text: 'Távok',
		textKey: 'distanceList',
		tab: 'distanceList',
		component: EditFormSubComponent,
		fixedLengthList: data.isTechnicalCommittee,
		defaultValue: {
			id: null,
			name: null,
			distance: null,
			levelTime: null,
			levelTimeByCycling: false,
			cyclingDistance: null,
			cyclingRound: null,
			cyclingLevelRise: null,
			swimmingDistance: null,
			swimmingRound: null,
			runningDistance: null,
			runningRound: null,
			runningLevelRise: null
		},
		fields: [
			{
				key: 'name',
				component: CompetitionDistanceNameField,
				formData: data.formData
			},
			{
				key: 'distance',
				text: 'Táv kiválasztása',
				textKey: 'distance',
				component: DistanceSelect,
				items: data.formData.distances
			},
			{
				key: 'levelTimeGroup',
				component: EditFormGroupFields,
				className: 'w-full flex flex-col sm:flex-row',
				fields: [
					{
						key: 'levelTime',
						text: 'Szintidő',
						textKey: 'levelTime',
						className: 'sm:mr-6',
						component: EditFormTextField,
						required: true
					},
					{
						key: 'levelTimeByCycling',
						text: 'Szintidő a kerékpározás vége',
						textKey: 'levelTimeByCycling',
						className: 'sm:ml-6',
						component: EditFormCheckboxField
					}
				]
			},
			{
				key: 'swimmingGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				fields: [
					{
						key: 'sepSwimmingGroup',
						text: 'Úszás',
						textKey: 'sepSwimmingGroup',
						component: EditFormSeparatorField,
						className: blockTitleClassName,
						isControlledInput: false
					},
					{
						key: 'swimmingInnerGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col sm:flex-row',
						fields: [
							{
								key: 'swimmingDistance',
								text: 'Táv',
								textKey: 'swimmingDistance',
								className: 'sm:mr-6',
								component: EditFormTextField
							},
							{
								key: 'swimmingRound',
								text: 'Kör',
								textKey: 'swimmingRound',
								className: 'sm:ml-6',
								component: EditFormTextField
							}
						]
					}
				]
			},
			{
				key: 'cyclingGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				fields: [
					{
						key: 'sepCyclingGroup',
						text: 'Kerékpározás',
						textKey: 'sepCyclingGroup',
						component: EditFormSeparatorField,
						className: blockTitleClassName,
						isControlledInput: false
					},
					{
						key: 'cyclingInnerGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col sm:flex-row',
						fields: [
							{
								key: 'cyclingDistance',
								text: 'Táv',
								textKey: 'cyclingDistance',
								className: 'sm:mr-6',
								component: EditFormTextField
							},
							{
								key: 'cyclingRound',
								text: 'Kör',
								textKey: 'cyclingRound',
								className: 'sm:ml-6 sm:mr-6',
								component: EditFormTextField
							},
							{
								key: 'cyclingLevelRise',
								text: 'Szintemelkedés',
								textKey: 'cyclingLevelRise',
								className: 'sm:ml-6',
								component: EditFormTextField
							}
						]
					}
				]
			},
			{
				key: 'runningGroup',
				component: EditFormGroupFields,
				className: blockClassName,
				fields: [
					{
						key: 'sepRunningGroup',
						text: 'Futás',
						textKey: 'sepRunningGroup',
						component: EditFormSeparatorField,
						className: blockTitleClassName,
						isControlledInput: false
					},
					{
						key: 'runningInnerGroup',
						component: EditFormGroupFields,
						className: 'w-full flex flex-col sm:flex-row',
						fields: [
							{
								key: 'runningDistance',
								text: 'Táv',
								textKey: 'runningDistance',
								className: 'sm:mr-6',
								component: EditFormTextField
							},
							{
								key: 'runningRound',
								text: 'Kör',
								textKey: 'runningRound',
								className: 'sm:ml-6 sm:mr-6',
								component: EditFormTextField
							},
							{
								key: 'runningLevelRise',
								text: 'Szintemelkedés',
								textKey: 'runningLevelRise',
								className: 'sm:ml-6',
								component: EditFormTextField
							}
						]
					}
				]
			}
		]
	};
}

const DistanceSelect = props => {
	const methods = useFormContext();
	const availableIds = [];
	const usedIds = [];
	const formPath = props.fieldPrefix.split('.');
	const currentIndex = Number.parseInt(formPath[formPath.length - 2], 10);
	const competitionPartList = methods.watch('competitionPartList');
	const distanceList = methods.watch('distanceList');
	distanceList.forEach((dist, i) => {
		if (i !== currentIndex && dist.distance != null) usedIds.push(dist.distance);
	});
	competitionPartList.forEach(part => {
		if (part.distance != null && usedIds.indexOf(part.distance) === -1) availableIds.push(part.distance);
	});
	const items = props.fieldConfig.items.filter(d => availableIds.indexOf(d.id) > -1);
	return <EditFormSelectField {...props} fieldConfig={{ ...props.fieldConfig, items }} />;
};

export default CompetitionDistanceEditForm;
