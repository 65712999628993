import _ from 'lodash';
import TableCell from '@material-ui/core/TableCell';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';

function DateTableCell(props) {
	const [label, setLabel] = useState(null);

	useEffect(() => {
		if (_.get(props.item, props.config.key) != null) {
			setLabel(format(_.get(props.item, props.config.key), 'yyyy.MM.dd.'));
		} else {
			setLabel('-');
		}
	}, [props.item, props.config]);

	return (
		<TableCell className="p-4 md:p-16" scope="row">
			{label}
		</TableCell>
	);
}

export default DateTableCell;
