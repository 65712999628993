import { useSelector } from 'react-redux';
import { TableCell, Button, Icon } from '@material-ui/core';

function AthleteTransferTableButton(props) {
	const { item } = props;
	const user = useSelector(({ auth }) => auth.user);
	const userId = user.data.id;
	if (user.isSuperAdmin && item.status === 'WAIT_FOR_ADMIN')
		return (
			<TableCell className="p-4 md:p-16" scope="row">
				<Button className="whitespace-nowrap mx-4" variant="contained" role="button" color="secondary" href={`#/admin-answer/${item.id}`} startIcon={<Icon className="hidden sm:flex">create</Icon>}>
					Nyilatkozom
				</Button>
			</TableCell>
		);
	if (user.isAthlete && userId === item.athlete.id && item.status === 'WAIT_FOR_ATHLETE') {
		return (
			<TableCell className="p-4 md:p-16" scope="row">
				<Button className="whitespace-nowrap mx-4" variant="contained" role="button" color="secondary" href={`#/athlete-answer/${item.id}`} startIcon={<Icon className="hidden sm:flex">create</Icon>}>
					Nyilatkozom
				</Button>
			</TableCell>
		);
	}
	if (user.isClub && userId === item.srcClub.id && item.status === 'WAIT_FOR_CLUB') {
		return (
			<TableCell className="p-4 md:p-16" scope="row">
				<Button className="whitespace-nowrap mx-4" variant="contained" role="button" color="secondary" href={`#/club-answer/${item.id}`} startIcon={<Icon className="hidden sm:flex">create</Icon>}>
					Nyilatkozom
				</Button>
			</TableCell>
		);
	}
	return <TableCell className="p-4 md:p-16" scope="row" />;
}

export default AthleteTransferTableButton;
