import _ from '@lodash';
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function TableFilterStringLikeField(props) {
	const { t } = useTranslation(props.config.topic ? ['common', props.config.topic] : 'common');
	const filterList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterList);
	const [value, setValue] = useState('');

	useEffect(() => {
		const index = filterList.findIndex(el => el.propertyName === props.filterConfig.propertyName);
		if (index > -1) {
			setValue(filterList[index].value);
		} else {
			setValue('');
		}
	}, [filterList]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (_.isString(value) && value.length > 0) {
				const filterValue = {
					propertyName: props.filterConfig.propertyName,
					type: props.filterConfig.type,
					value
				};
				props.onFilterChanged(filterValue);
			} else {
				props.onFilterRemoved(props.filterConfig.propertyName);
			}
		}, 500);
		return () => clearTimeout(timeOutId);
	}, [value]);

	return (
		<div>
			<TextField
				margin="dense"
				label={!_.isUndefined(props.filterConfig.text) ? props.filterConfig.text : t(`${props.config.topic}:${props.filterConfig.propertyName}`)}
				fullWidth
				value={value}
				onChange={e => setValue(e.target.value)}
			/>
		</div>
	);
}

export default TableFilterStringLikeField;
