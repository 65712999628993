import nBossConnection from 'modules/base/service/nBossConnection';
import NamedDto from 'modules/base/dto/NamedDto';

class DistanceService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'distanceControl';
	}

	/**
	 * @return {Promise<NamedDto[]>}
	 */
	findAll() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/findAll`)
				.then(response => resolve(response.data !== null ? response.data.map(d => new NamedDto().setup(d)) : null))
				.catch(reject);
		});
	}
}

export default new DistanceService();
