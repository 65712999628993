import { useState } from 'react';
import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import huLocale from 'date-fns/locale/hu';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputAdornment } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import clsx from 'clsx';
import { determineEditableAccess } from '../EditFormUtils';

const localeMap = {
	en: enLocale,
	hu: huLocale
};

const localeFormatMap = {
	en: 'd MMMM yyyy',
	hu: 'yyyy MMMM d.'
};

const localeTextMap = {
	en: {
		cancel: 'Cancel',
		ok: 'Ok',
		clear: 'Clear'
	},
	hu: {
		cancel: 'Mégse',
		ok: 'Ok',
		clear: 'Törlés'
	}
};

function EditFormDateField(props) {
	const [locale, setLocale] = useState('hu');
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { error } = props.fieldState;
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
			<DatePicker
				views={props.fieldConfig.views}
				id={props.field.name}
				{...props.field}
				label={!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
				format={!_.isUndefined(props.fieldConfig.localeFormatMap) ? props.fieldConfig.localeFormatMap[locale] : localeFormatMap[locale]}
				fullWidth
				className={clsx('mt-8 mb-16', props.fieldConfig.className)}
				inputVariant="outlined"
				error={error}
				required={props.fieldConfig.required}
				helperText={error ? error.message : null}
				cancelLabel={localeTextMap[locale].cancel}
				okLabel={localeTextMap[locale].ok}
				clearLabel={localeTextMap[locale].clear}
				minDate={props.fieldConfig.minDate}
				maxDate={props.fieldConfig.maxDate}
				openTo={props.fieldConfig.openTo}
				readOnly={readOnly}
				clearable
				InputProps={{
					endAdornment: readOnly ? (
						<InputAdornment position="end">
							<Lock fontSize="small" color="disabled" />
						</InputAdornment>
					) : null
				}}
			/>
		</MuiPickersUtilsProvider>
	);
}

export default EditFormDateField;
