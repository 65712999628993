import _ from '@lodash';
import GenderType from 'app/admin/common/dto/GenderType';
import NamedDto from 'modules/base/dto/NamedDto';

export class CompetitionEntryTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.cachedPersonFullName = null;
		/** @type {Date} */
		this.cachedPersonBirthDate = null;
		/** @type {string} */
		this.cachedPersonLicenseType = null;
		/** @type {NamedDto} */
		this.cachedPersonAgeGroup = null;
		/** @type {string} */
		this.cachedPersonLicenseNumber = null;
		/** @type {string} */
		this.cachedPersonChipNumber = null;
		/** @type {boolean} */
		this.paid = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Date} */
		this.updated = null;
		/** @type {NamedDto} */
		this.race = null;
		/** @type {string} */
		this.cachedPersonGender;
		/** @type {NamedDto} */
		this.org = null;
		/** @type {string} */
		this.teamName;
		/** @type {NamedDto} */
		this.competition = null;
		/** @type {number} */
		this.amount;
	}

	/**
	 * @typedef {Object} _CompetitionEntryTableDtoProps
	 * @property {string} cachedPersonFullName
	 * @property {Date} cachedPersonBirthDate
	 * @property {string} cachedPersonLicenseType
	 * @property {string} cachedPersonLicenseNumber
	 * @property {string} cachedPersonChipNumber
	 * @property {NamedDto} cachedPersonAgeGroup
	 * @property {boolean} paid
	 * @property {Date} created
	 * @property {Date} updated
	 * @property {NamedDto} race
	 * @property {string} cachedPersonGender
	 * @property {NamedDto} org
	 * @property {string} teamName
	 * @property {NamedDto} competition
	 * @property {number} amount
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _CompetitionEntryTableDtoProps} CompetitionEntryTableDtoProps
	 */
	/**
	 *
	 * @param {CompetitionEntryTableDtoProps} props
	 * @returns {CompetitionEntryTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.cachedPersonFullName = props.cachedPersonFullName;
		this.cachedPersonBirthDate = props.cachedPersonBirthDate !== null ? new Date(props.cachedPersonBirthDate) : null;
		this.cachedPersonLicenseType = props.cachedPersonLicenseType;
		this.cachedPersonLicenseNumber = props.cachedPersonLicenseNumber;
		this.cachedPersonChipNumber = props.cachedPersonChipNumber;
		this.cachedPersonAgeGroup = props.cachedPersonAgeGroup != null ? new NamedDto().setup(props.cachedPersonAgeGroup) : null;
		this.paid = props.paid;
		this.created = props.created !== null ? new Date(props.created) : null;
		this.updated = props.updated !== null ? new Date(props.updated) : null;
		this.race = props.race != null ? new NamedDto().setup(props.race) : null;
		this.cachedPersonGender = props.cachedPersonGender;
		this.org = props.org != null ? new NamedDto().setup(props.org) : null;
		this.teamName = props.teamName;
		this.competition = props.competition != null ? new NamedDto().setup(props.competition) : null;
		this.amount = props.amount;
		return this;
	}

	getAsString(key) {
		if (key === 'amount') {
			return this.amount != null ? `${this.amount} ,-` : '';
		}
		if (key === 'paid' && this.paid !== null) {
			return this.paid ? 'Befizetett' : 'Befizetésre vár';
		}
		if (key === 'cachedPersonBirthDate') {
			return this.cachedPersonBirthDate != null ? this.cachedPersonBirthDate.getFullYear() : null;
		}
		if (key === 'cachedPersonGender') {
			return this.cachedPersonGender != null ? GenderType[this.cachedPersonGender] : null;
		}
		return super.getAsString(key);
	}
}

export default CompetitionEntryTableDto;
