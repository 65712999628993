import _ from '@lodash';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CompetitionEntryClubTableService from '../service/CompetitionEntryClubTableService';

function findCompetition(filterList) {
	if (_.isArray(filterList)) {
		const competitionFilter = filterList.find(d => d.propertyName === 'competition');
		if (competitionFilter && _.isNumber(competitionFilter.value)) return competitionFilter.value;
	}
	return null;
}

export default function TableAmountSumComponent(props) {
	const [amountSum, setAmountSum] = useState(null);
	const [competition, setCompetition] = useState(null);
	const filterList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterList);
	useEffect(() => {
		console.log(competition);
		if (competition != null) {
			CompetitionEntryClubTableService.getCompetititionAmountSum(competitionId).then(resp => {
				setAmountSum(resp);
			});
		} else {
			setAmountSum(null);
		}
	}, [competition]);

	const competitionId = findCompetition(filterList);
	if (competitionId !== competition) setCompetition(competitionId);
	if (competitionId == null) return <div className="font-bold flex items-center">Kérlek válassz ki egy versenyt a szűrési feltételknél az összegzéshez.</div>;
	if (amountSum != null) return <div className="font-bold flex items-center">{`Összesen: ${amountSum},-`}</div>;
	return <></>;
}
