import nBossConnection from 'modules/base/service/nBossConnection';
import _ from '@lodash';
import { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormSeparatorField from 'modules/ui/editform/fields/EditFormSeparatorField';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import EditFormDateField from 'modules/ui/editform/fields/EditFormDateField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import EditFormImageInputField from 'modules/ui/editform/fields/EditFormImageInputField';
import EditFormAutoCompleteField from 'modules/ui/editform/fields/EditFormAutoCompleteField';
import EditFormSubComponent from 'modules/ui/editform/subForm/EditFormSubComponent';
import CountryService from 'app/admin/payment/service/CountryService';
import { sub, isAfter, add } from 'date-fns';
import { showMessage } from 'app/store/fuse/messageSlice';
import TableFilterListDto from 'modules/base/dto/table/TableFilterListDto';
import EditFormTableBrowserField from 'modules/ui/editform/fields/EditFormTableBrowserField';
import { getSessionString } from 'modules/base/utils/GenericUtils';
import userPaymentTableService from 'app/admin/payment/service/UserPaymentTableService';
import PaymentTableCell from 'app/admin/payment/components/PaymentTableCell';
import DownloadInvoiceTableButton from 'app/admin/payment/components/DownloadInvoiceTableButton';
import ChangePaymentTableButton from 'app/admin/payment/components/ChangePaymentTableButton';
import MedicalLicenseTableService from 'app/admin/medicalLicense/service/MedicalLicenseTableService';
import UserLogTableService from 'app/admin/user/service/UserLogTableService';
import PersonEditService from '../service/PersonEditService';
import PersonFormInfo from '../component/PersonFormInfo';

const uncheckAnotherAthleteField = (field, methods) => {
	const { getValues, setValue } = methods;
	const values = getValues();
	if (field.name === 'isAthlete' && values.isCompetitor) {
		setValue('isCompetitor', false);
	} else if (field.name === 'isCompetitor' && values.isAthlete) {
		setValue('isAthlete', false);
	}
};

const isUnderAge = birthDate => {
	if (_.isDate(birthDate)) {
		const result = sub(new Date(), { years: 18 });
		if (isAfter(birthDate, result)) return true;
	}
	return false;
};

const userLogTableConfig = (data, dto) => {
	return {
		key: 'personUserLogTable',
		text: 'Naplók',
		textKey: 'personUserLogTable',
		withToolbar: true,
		hideTitle: true,
		service: UserLogTableService,
		toolbarAction: ['search', 'refresh'],
		defaultFilter: () => {
			return new TableFilterListDto().setup({
				list: [
					{
						propertyName: 'id',
						type: 'EQ',
						value: dto.id,
						permanent: true
					}
				],
				activeCount: 1
			});
		},
		columns: [
			{
				key: 'logDate',
				text: 'Dátum',
				textKey: 'logDate',
				type: 'dateTime',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'modifierUserName',
				text: 'Módosító felhasználó',
				textKey: 'modifierUserName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'logCategory',
				text: 'Kategória',
				textKey: 'logCategory',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'message',
				text: 'Bejegyzés',
				textKey: 'message',
				type: 'text',
				sortable: true,
				disablePadding: false
			}
		]
	};
};

const userPaymentTableConfig = (data, dto) => {
	return {
		key: 'personUserPaymentTable',
		text: 'Befizetések',
		textKey: 'personUserPaymentTable',
		withToolbar: true,
		hideTitle: true,
		service: userPaymentTableService,
		toolbarAction: ['search', 'refresh'],
		rowButtonList: [
			...(data.isSuperAdmin
				? [
						{
							key: 'changePayment',
							component: ChangePaymentTableButton
						}
				  ]
				: []),
			...[
				{
					key: 'downloadInvoice',
					component: DownloadInvoiceTableButton
				}
			]
		],
		defaultFilter: () => {
			return new TableFilterListDto().setup({
				list: [
					{
						propertyName: 'user.id',
						type: 'EQ',
						value: dto.id,
						permanent: true
					}
				],
				activeCount: 1
			});
		},
		columns: [
			{
				key: 'billingDate',
				text: 'Számlázás dátuma',
				textKey: 'billingDate',
				type: 'dateTime',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'description',
				text: 'Megnevezés',
				textKey: 'description',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'payableNet',
				text: 'Nettó fizetendő',
				textKey: 'payableNet',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'payableGross',
				text: 'Bruttó fizetendő',
				textKey: 'payableGross',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'paymentStatus',
				text: 'Státusz',
				textKey: 'paymentStatus',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'paymentDate',
				text: 'Befizetés dátuma',
				textKey: 'paymentDate',
				type: 'dateTime',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'payment',
				text: 'Fizetés',
				component: PaymentTableCell,
				sortable: false,
				disablePayment: !data.paymentIsPossible,
				disablePadding: false
			}
		]
	};
};

const nsrSportorvosiTableConfig = (data, dto) => {
	return {
		key: 'medicalLicenseTable',
		text: 'Sportorvosi engedélyek',
		textKey: 'medicalLicenseTable',
		service: MedicalLicenseTableService,
		withToolbar: true,
		hideTitle: true,
		toolbarAction: ['refresh'],
		defaultFilter: () => {
			return new TableFilterListDto().setup({
				list: [
					{
						propertyName: 'person',
						type: 'EQ',
						value: dto.id,
						permanent: true
					}
				],
				activeCount: 1
			});
		},
		columns: [
			{
				key: 'minositesKiadas',
				text: 'Minősítés kiadás',
				textKey: 'minositesKiadas',
				type: 'date',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'minosites',
				text: 'Minősítés',
				textKey: 'minosites',
				type: 'text',
				sortable: false,
				disablePadding: false
			},
			{
				key: 'lejaratiDatum',
				text: 'Lejárati dátum',
				textKey: 'lejaratiDatum',
				type: 'date',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'ervenytelenites',
				text: 'Érvénytelenítés',
				textKey: 'ervenytelenites',
				type: 'boolean',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'megjegyzes',
				text: 'Megjegyzés',
				textKey: 'megjegyzes',
				type: 'text',
				sortable: false,
				disablePadding: false
			}
		]
	};
};

const config = data => {
	const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
	const blockTitleClassName = 'py-8';
	return {
		key: 'personEditForm',
		text: data.titleText,
		service: PersonEditService,
		icon: 'person',
		title: item => (item && item.lastName && item.firstName ? `${item.lastName} ${item.firstName}` : `Új ${data.titleText}`),
		editableAccess: data.formData.editableAccess !== 'Full' ? data.formData.editableAccess : null,
		editableFields: data.formData.editableFields,
		noNavigationAfterSave: data.type === 'profile',
		hideBackLink: data.type === 'profile',
		backLink: () => `/${data.type}`,
		tabList: initData => {
			const tabList = [
				{
					key: 'basic',
					text: 'Adatok',
					textKey: 'basic'
				}
			];
			if (initData.id !== null && (initData.athleteRegType == null || initData.athleteRegType !== 'REG_HU')) {
				tabList.push({
					key: 'userPayment',
					text: 'Befizetések',
					textKey: 'userPayment'
				});
			}
			if (initData.id !== null && data.isSuperAdmin) {
				tabList.push({
					key: 'userLog',
					text: 'Naplók',
					textKey: 'userLog'
				});
			}
			if (initData.id !== null && (data.type === 'athlete' || data.type === 'competitor')) {
				tabList.push({
					key: 'nsrSportorvosi',
					text: 'Sportorvosi',
					textKey: 'nsrSportorvosi'
				});
			}
			if (
				data.type === 'athlete' ||
				data.type === 'competitor' ||
				(data.type === 'profile' && ((data.user.isAthlete && (data.athleteRegType == null || data.athleteRegType === 'NORMAL' || data.athleteRegType === 'LICENSE_REDEEM')) || data.user.isCompetitor))
			) {
				tabList.push({
					key: 'documentList',
					text: 'Dokumentumok',
					textKey: 'documentList'
				});
			}
			return tabList;
		},
		fields: initData => {
			const isNewTypeRegAndProfile = data.type === 'profile' && data.athleteRegType != null && data.athleteRegType !== 'NORMAL';
			const fields = [
				...(!isNewTypeRegAndProfile
					? [
							{
								key: 'typeGroup',
								component: EditFormGroupFields,
								className: 'w-full flex flex-col md:flex-row rounded-8 border px-16 pt-10 pb-4 mb-16',
								fields: [
									{
										key: 'isAthlete',
										text: 'Egyesületi versenyző vagyok',
										textKey: 'isAthlete',
										component: EditFormCheckboxField,
										onChange: (value, field, methods) => {
											if (value) uncheckAnotherAthleteField(field, methods);
										}
									},
									{
										key: 'isReferee',
										text: 'Versenybíró vagyok',
										textKey: 'isReferee',
										component: EditFormCheckboxField
									},
									{
										key: 'isTrainer',
										text: 'Edző vagyok',
										textKey: 'isTrainer',
										component: EditFormCheckboxField
									},
									{
										key: 'isCompetitor',
										text: 'Egyéni versenyző vagyok',
										textKey: 'isCompetitor',
										component: EditFormCheckboxField,
										onChange: (value, field, methods) => {
											if (value) uncheckAnotherAthleteField(field, methods);
										}
									},
									{
										key: 'isTechnicalCommittee',
										text: 'Technikai bizottság vagyok',
										textKey: 'isTechnicalCommittee',
										component: EditFormCheckboxField
									}
								]
							}
					  ]
					: []),
				{
					key: 'archived',
					text: 'Archivált',
					textKey: 'archived',
					component: EditFormSelectField,
					items: data.booleanList,
					required: true,
					visibleIf: () => data.isSuperAdmin || data.accessType === 'Org'
				},
				{
					key: 'userStatus',
					text: 'Státusz',
					textKey: 'userStatus',
					component: EditFormSelectField,
					items: data.formData.userStatuses,
					type: 'enum',
					required: true,
					visibleIf: () => data.isSuperAdmin
				},
				{
					key: 'club',
					text: 'Klub',
					textKey: 'club',
					component: EditFormSelectField,
					items: data.formData.clubs,
					required: false,
					dependsOn: 'isCompetitor',
					visibleIf: isCompetitor => !isCompetitor && (data.isSuperAdmin || (data.accessType === 'Person' && (data.athleteRegType == null || data.athleteRegType === 'NORMAL')))
				},
				{
					key: 'nameGroup',
					component: EditFormGroupFields,
					className: 'w-full flex flex-col sm:flex-row',
					fields: [
						{
							key: 'lastName',
							text: 'Vezetéknév',
							textKey: 'lastName',
							className: 'sm:mr-6',
							component: EditFormTextField,
							maxLength: 50,
							required: true,
							autoCapitalize: 'capitalize'
						},
						{
							key: 'firstName',
							text: 'Keresztnév',
							textKey: 'firstName',
							className: 'sm:ml-6',
							component: EditFormTextField,
							maxLength: 50,
							required: true,
							autoCapitalize: 'capitalize'
						}
					]
				},
				{
					key: 'birthGroup',
					component: EditFormGroupFields,
					className: 'w-full flex flex-col sm:flex-row',
					fields: [
						{
							key: 'birthPlace',
							text: 'Születési hely',
							textKey: 'birthPlace',
							className: 'sm:mr-6',
							component: EditFormTextField,
							maxLength: 40,
							required: true
						},
						{
							key: 'birthDate',
							text: 'Születési idő',
							textKey: 'birthDate',
							className: 'sm:ml-6',
							component: EditFormDateField,
							maxDate: new Date(),
							openTo: 'year',
							required: true
						}
					]
				},
				{
					key: 'parentalPermission',
					text: 'Szülői engedély',
					textKey: 'parentalPermission',
					component: EditFormFileInputField,
					required: false,
					dependsOn: 'birthDate',
					visibleIf: birthDate => isUnderAge(birthDate)
				},
				{
					key: 'gender',
					text: 'Nem',
					textKey: 'gender',
					component: EditFormSelectField,
					items: data.formData.genders,
					type: 'enum',
					required: true
				},
				{
					key: 'email',
					text: 'E-mail cím',
					textKey: 'email',
					component: EditFormTextField,
					maxLength: 100,
					required: true
				},
				{
					key: 'phoneNumber',
					text: 'Telefonszám',
					textKey: 'phoneNumber',
					mask: {
						mask: '+0000000000000'
					},
					component: EditFormTextField,
					maxLength: 50,
					required: true
				},
				{
					key: 'nationality',
					text: 'Állampolgárság',
					textKey: 'nationality',
					component: EditFormSelectField,
					items: data.formData.nationalities,
					required: true
				},
				{
					key: 'nationalityOther',
					text: 'Állampolgárság egyéb',
					textKey: 'nationalityOther',
					component: EditFormTextField,
					maxLength: 200,
					required: false
				},
				{
					key: 'fullMotherNameGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepMothersName',
							text: 'Anyja neve',
							textKey: 'sepMothersName',
							component: EditFormSeparatorField,
							className: blockTitleClassName,
							icon: 'pregnant_woman',
							isControlledInput: false
						},
						{
							key: 'motherNameGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col sm:flex-row',
							fields: [
								{
									key: 'motherLastName',
									text: 'Vezetéknév',
									textKey: 'motherLastName',
									className: 'sm:mr-6',
									component: EditFormTextField,
									maxLength: 50,
									required: true,
									autoCapitalize: 'capitalize'
								},
								{
									key: 'motherFirstName',
									text: 'Keresztnév',
									textKey: 'motherFirstName',
									className: 'sm:ml-6',
									component: EditFormTextField,
									maxLength: 50,
									required: true,
									autoCapitalize: 'capitalize'
								}
							]
						}
					]
				},
				{
					key: 'fullPasswordGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepMothersName',
							text: 'Jelszó módosítása',
							textKey: 'sepMothersName',
							component: EditFormSeparatorField,
							icon: 'lock',
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'passwordGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col sm:flex-row',
							fields: [
								{
									key: 'password',
									text: 'Jelszó',
									textKey: 'password',
									className: 'sm:mr-6',
									component: EditFormTextField,
									required: false,
									type: 'password',
									autoComplete: 'new-password',
									maxLength: 50
								},
								{
									key: 'passwordAgain',
									text: 'Jelszó mégegyszer',
									textKey: 'passwordAgain',
									className: 'sm:ml-6',
									component: EditFormTextField,
									required: false,
									type: 'password',
									autoComplete: 'new-password',
									maxLength: 50
								}
							]
						}
					]
				},
				{
					key: 'fullAddressGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepAddress',
							text: 'Cím',
							textKey: 'sepAddress',
							component: EditFormSeparatorField,
							icon: 'place',
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'addressGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col md:flex-row',
							fields: [
								{
									key: 'addressZipcode',
									text: 'Irányítószám',
									textKey: 'addressZipcode',
									component: EditFormTextField,
									maxLength: 10,
									className: 'w-full md:w-1/5 md:mr-12',
									required: true
								},
								{
									key: 'addressCity',
									text: 'Város',
									textKey: 'addressCity',
									component: EditFormTextField,
									maxLength: 40,
									className: 'w-full md:w-2/5 md:mr-12',
									required: true
								},
								{
									key: 'addressCounty',
									text: 'Megye',
									textKey: 'addressCounty',
									component: EditFormSelectField,
									items: data.formData.counties,
									className: 'w-full md:w-2/5',
									required: true
								}
							]
						},
						{
							key: 'addressStreetGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col md:flex-row',
							fields: [
								{
									key: 'addressStreet',
									text: 'Utca',
									textKey: 'addressStreet',
									component: EditFormTextField,
									maxLength: 100,
									className: 'w-full md:w-4/5 md:mr-12',
									required: true
								},
								{
									key: 'addressStreetType',
									text: 'Típus',
									textKey: 'addressStreetType',
									component: EditFormSelectField,
									items: data.formData.publicPlaceTypes,
									className: 'w-full md:w-1/5',
									required: true
								}
							]
						},
						{
							key: 'addressNumberGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-row',
							fields: [
								{
									key: 'addressNumber',
									text: 'Házszám',
									textKey: 'addressNumber',
									component: EditFormTextField,
									maxLength: 50,
									className: 'w-1/3 mr-12',
									required: true
								},
								{
									key: 'addressFloor',
									text: 'Emelet',
									textKey: 'addressFloor',
									component: EditFormTextField,
									maxLength: 10,
									className: 'w-1/3 mr-12'
								},
								{
									key: 'addressDoor',
									text: 'Ajtó',
									textKey: 'addressDoor',
									component: EditFormTextField,
									maxLength: 10,
									className: 'w-1/3'
								}
							]
						}
					]
				},
				{
					key: 'profilePicture',
					text: 'Profilkép',
					textKey: 'profilePicture',
					component: EditFormImageInputField,
					imageUrl: file => `${nBossConnection.baseUrl}fileRepository/getFile?key=${file.key}`,
					required: true
				},
				{
					key: 'fullAthleteGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					dependsOn: 'isAthlete',
					visibleIf: isAthlete => isAthlete,
					fields: [
						{
							key: 'sepAthleteGroup',
							text: isNewTypeRegAndProfile ? 'Versenyző adatok' : 'Egyesületi versenyző adatok',
							textKey: 'sepAthleteGroup',
							component: EditFormSeparatorField,
							className: blockTitleClassName,
							icon: 'person',
							isControlledInput: false
						},
						{
							key: 'athleteGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col',
							fields: [
								...(!isNewTypeRegAndProfile
									? [
											{
												key: 'athlete.taj',
												text: 'TAJ szám',
												textKey: 'athlete.taj',
												component: EditFormTextField,
												maxLength: 11,
												mask: {
													mask: '000 000 000'
												},
												required: false
											},
											{
												key: 'athlete.idCardNumber',
												text: 'Személyi igazolvány szám',
												textKey: 'athlete.idCardNumber',
												component: EditFormTextField,
												maxLength: 50,
												required: false
											},
											{
												key: 'athlete.passportNumber',
												text: 'Útlevélszám',
												textKey: 'athlete.passportNumber',
												component: EditFormTextField,
												maxLength: 50,
												required: false
											}
									  ]
									: []),
								initData.athlete.childAgeGroup != null
									? {
											key: 'athletePersonAgeGroup',
											component: EditFormGroupFields,
											className: 'w-full flex flex-col sm:flex-row',
											fields: [
												{
													key: 'athlete.childAgeGroup',
													text: 'Utánpótlás korcsoport',
													textKey: 'athlete.childAgeGroup',
													className: 'mr-0 sm:mr-12',
													component: EditFormSelectField,
													items: data.formData.personChildAgeGroups,
													readOnly: true
												},
												{
													key: 'athlete.adultAgeGroup',
													text: 'Felnőtt korcsoport',
													textKey: 'athlete.childAgeGroup',
													className: 'ml-0 sm:ml-12',
													component: EditFormSelectField,
													items: data.formData.personAdultAgeGroups,
													readOnly: true
												}
											]
									  }
									: {
											key: 'athlete.adultAgeGroup',
											text: 'Felnőtt korcsoport',
											textKey: 'athlete.childAgeGroup',
											component: EditFormSelectField,
											items: data.formData.personAdultAgeGroups,
											readOnly: true
									  },
								{
									key: 'athleteIdGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col sm:flex-row',
									fields: [
										{
											key: 'athlete.licenseType',
											text: 'Licensz típus',
											textKey: 'athlete.licenseType',
											className: 'mr-0 sm:mr-12',
											component: EditFormSelectField,
											items: data.formData.licenseTypes,
											type: 'enum',
											required: true
										},
										{
											key: 'athlete.licenseNumber',
											text: 'Licensz szám',
											textKey: 'athlete.licenseNumber',
											className: 'mr-0 sm:mr-12',
											component: EditFormTextField,
											maxLength: 100,
											required: false
										},
										{
											key: 'athlete.chipNumber',
											text: 'Chip szám',
											textKey: 'athlete.chipNumber',
											component: EditFormTextField,
											maxLength: 100,
											required: false
										}
									]
								}
							]
						}
					]
				},
				{
					key: 'fullTrainerGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					dependsOn: 'isTrainer',
					visibleIf: isTrainer => isTrainer,
					fields: [
						{
							key: 'sepTrainerGroup',
							text: 'Edző adatok',
							textKey: 'sepTrainerGroup',
							component: EditFormSeparatorField,
							className: blockTitleClassName,
							icon: 'person',
							isControlledInput: false
						},
						{
							key: 'trainerGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col',
							fields: [
								{
									key: 'trainerQualificationsGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col sm:flex-row',
									fields: [
										{
											key: 'trainer.qualification',
											text: 'Végzettség',
											textKey: 'trainer.qualification',
											className: 'sm:mr-6',
											component: EditFormSelectField,
											items: data.formData.qualifications,
											required: false
										},
										{
											key: 'trainer.qualificationOther',
											text: 'Végzettség egyéb',
											textKey: 'trainer.qualificationOther',
											className: 'sm:ml-6',
											component: EditFormTextField,
											maxLength: 200,
											required: false
										}
									]
								},
								{
									key: 'trainer.qualificationSport',
									text: 'Sportág',
									textKey: 'trainer.qualificationSport',
									component: EditFormTextField,
									maxLength: 100,
									required: true,
									dependsOn: 'trainer.qualification',
									visibleIf: trainerQualification => trainerQualification && data.formData.qualifications.find(item => item.id === trainerQualification).sportRequired
								},
								{
									key: 'trainer.certificate',
									text: 'Végzettséget igazoló dokumentum',
									textKey: 'trainer.certificate',
									component: EditFormFileInputField,
									required: true
								},
								{
									key: 'trainer.acceptSelfRecommendation',
									text: 'Hozzájárulok, hogy a Magyar Triatlon Szövetség, a honlapján edzői tevékenységemet kiajánlja és elérhetőségeimet megadja.',
									textKey: 'trainer.acceptSelfRecommendation',
									component: EditFormCheckboxField
								}
							]
						}
					]
				},
				{
					key: 'fullRefereeGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					dependsOn: 'isReferee',
					visibleIf: isReferee => isReferee,
					fields: [
						{
							key: 'sepRefereeGroup',
							text: 'Versenybíró adatok',
							textKey: 'sepRefereeGroup',
							component: EditFormSeparatorField,
							className: blockTitleClassName,
							icon: 'person',
							isControlledInput: false
						},
						{
							key: 'refereeGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col',
							fields: [
								{
									key: 'referee.shirtSize',
									text: 'Póló méret',
									textKey: 'referee.shirtSize',
									component: EditFormSelectField,
									items: data.formData.shirtSizes,
									type: 'enum',
									required: true
								},
								{
									key: 'refereeCarGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col sm:flex-row',
									fields: [
										{
											key: 'referee.hasDrivingLicense',
											text: 'Rendelkezik-e jogosítvánnyal?',
											textKey: 'referee.hasDrivingLicense',
											className: 'sm:mr-6',
											component: EditFormSelectField,
											items: data.booleanList,
											required: true
										},
										{
											key: 'referee.hasCar',
											text: 'Rendelkezik-e saját autóval?',
											textKey: 'referee.hasCar',
											className: 'sm:ml-6',
											component: EditFormSelectField,
											items: data.booleanList,
											required: true
										}
									]
								},
								{
									key: 'referee.languages',
									text: 'Nyelvtudás',
									textKey: 'referee.languages',
									component: EditFormTextField,
									maxLength: 200,
									required: true
								},
								{
									key: 'refereeLicenseGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col sm:flex-row',
									fields: [
										{
											key: 'referee.licenseNumber',
											text: 'Bírói engedély szám',
											textKey: 'referee.licenseNumber',
											className: 'sm:mr-6',
											component: EditFormTextField,
											maxLength: 50,
											required: false
										} /*,
										{
											key: 'referee.licenseExpirationDate',
											text: 'Engedély lejárati dátum',
											textKey: 'referee.licenseExpirationDate',
											className: 'sm:ml-6',
											component: EditFormDateField,
											required: true
										}*/
									]
								},
								{
									key: 'refereeQualificationsGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col sm:flex-row',
									fields: [
										{
											key: 'referee.refQualifications',
											text: 'Versenybírói minősítés',
											textKey: 'referee.refQualifications',
											className: 'sm:mr-6',
											component: EditFormSelectField,
											multiple: true,
											items: data.formData.refQualifications,
											required: true
										},
										{
											key: 'referee.examDate',
											text: 'Vizsga éve',
											textKey: 'referee.examDate',
											className: 'sm:ml-6',
											component: EditFormDateField,
											views: ['year'],
											localeFormatMap: {
												en: 'yyyy',
												hu: 'yyyy'
											},
											required: true
										}
									]
								}
							]
						},
						{
							key: 'fullLocationGroup',
							component: EditFormGroupFields,
							className: blockClassName,
							fields: [
								{
									key: 'sepLocation',
									text: 'Tartózkodási hely',
									textKey: 'sepLocation',
									component: EditFormSeparatorField,
									icon: 'place',
									className: blockTitleClassName,
									isControlledInput: false
								},
								{
									key: 'referee.locationCountry',
									text: 'Ország',
									textKey: 'referee.locationCountry',
									component: EditFormAutoCompleteField,
									query: (query, limit) => CountryService.getCountrySelection(query, limit),
									required: false
								},
								{
									key: 'locationGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col md:flex-row',
									fields: [
										{
											key: 'referee.locationZipcode',
											text: 'Irányítószám',
											textKey: 'referee.locationZipcode',
											component: EditFormTextField,
											maxLength: 10,
											className: 'w-full md:w-1/5 md:mr-12',
											required: false
										},
										{
											key: 'referee.locationCity',
											text: 'Város',
											textKey: 'referee.locationCity',
											component: EditFormTextField,
											maxLength: 40,
											className: 'w-full md:w-2/5 md:mr-12',
											required: false
										},
										{
											key: 'referee.locationCounty',
											text: 'Megye',
											textKey: 'referee.locationCounty',
											component: EditFormSelectField,
											items: data.formData.counties,
											className: 'w-full md:w-2/5',
											required: false
										}
									]
								},
								{
									key: 'locationStreetGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-col md:flex-row',
									fields: [
										{
											key: 'referee.locationStreet',
											text: 'Utca',
											textKey: 'referee.locationStreet',
											component: EditFormTextField,
											maxLength: 100,
											className: 'w-full md:w-4/5 md:mr-12',
											required: false
										},
										{
											key: 'referee.locationStreetType',
											text: 'Típus',
											textKey: 'referee.locationStreetType',
											component: EditFormSelectField,
											items: data.formData.publicPlaceTypes,
											className: 'w-full md:w-1/5',
											required: false
										}
									]
								},
								{
									key: 'locationNumberGroup',
									component: EditFormGroupFields,
									className: 'w-full flex flex-row',
									fields: [
										{
											key: 'referee.locationNumber',
											text: 'Házszám',
											textKey: 'referee.locationNumber',
											component: EditFormTextField,
											maxLength: 50,
											className: 'w-1/3 mr-12',
											required: false
										},
										{
											key: 'referee.locationFloor',
											text: 'Emelet',
											textKey: 'referee.locationFloor',
											component: EditFormTextField,
											maxLength: 10,
											className: 'w-1/3 mr-12'
										},
										{
											key: 'referee.locationDoor',
											text: 'Ajtó',
											textKey: 'referee.locationDoor',
											component: EditFormTextField,
											maxLength: 10,
											className: 'w-1/3'
										}
									]
								}
							]
						}
					]
				},
				{
					key: 'fullCompetitorGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					dependsOn: 'isCompetitor',
					visibleIf: isCompetitor => isCompetitor,
					fields: [
						{
							key: 'sepCompetitorGroup',
							text: 'Egyéni versenyző adatok',
							textKey: 'sepCompetitorGroup',
							component: EditFormSeparatorField,
							className: blockTitleClassName,
							icon: 'person',
							isControlledInput: false
						},
						{
							key: 'competitorGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col',
							fields: [
								initData.competitor.childAgeGroup != null
									? {
											key: 'competitorPersonAgeGroup',
											component: EditFormGroupFields,
											className: 'w-full flex flex-col sm:flex-row',
											fields: [
												{
													key: 'competitor.childAgeGroup',
													text: 'Utánpótlás korcsoport',
													textKey: 'competitor.childAgeGroup',
													className: 'mr-0 sm:mr-12',
													component: EditFormSelectField,
													items: data.formData.personChildAgeGroups,
													readOnly: true
												},
												{
													key: 'competitor.adultAgeGroup',
													text: 'Felnőtt korcsoport',
													textKey: 'competitor.childAgeGroup',
													className: 'ml-0 sm:ml-12',
													component: EditFormSelectField,
													items: data.formData.personAdultAgeGroups,
													readOnly: true
												}
											]
									  }
									: {
											key: 'competitor.adultAgeGroup',
											text: 'Felnőtt korcsoport',
											textKey: 'competitor.childAgeGroup',
											component: EditFormSelectField,
											items: data.formData.personAdultAgeGroups,
											readOnly: true
									  },
								{
									key: 'competitor.licenseNumber',
									text: 'Licensz szám',
									textKey: 'competitor.licenseNumber',
									component: EditFormTextField,
									maxLength: 100,
									required: false
								},
								{
									key: 'competitor.chipNumber',
									text: 'Chip szám',
									textKey: 'competitor.chipNumber',
									component: EditFormTextField,
									maxLength: 100,
									required: false
								}
							]
						}
					]
				},
				{
					key: 'accept',
					text: 'A Magyar Triatlon Szövetség alapszabályzatát megismertem, tartalmát elfogadom, és magamra kötelező érvényűnek tekintem.',
					textKey: 'accept',
					component: EditFormCheckboxField,
					required: true
				}
			];
			if (initData.id !== null) {
				if (!data.paymentIsPossible) {
					fields.push({
						key: 'paymentIsPossible',
						tab: 'userPayment',
						text: 'Fizetés nem lehetséges, mert az egyesület éves licensz díja nincs megfizetve.',
						component: EditFormSeparatorField,
						className: blockTitleClassName,
						icon: 'payment',
						isControlledInput: false
					});
				}
			}
			if (initData.id != null && (initData.athleteRegType == null || initData.athleteRegType !== 'REG_HU')) {
				fields.push({
					key: getSessionString(),
					text: 'Befizetések',
					textKey: 'userPayment',
					tab: 'userPayment',
					component: EditFormTableBrowserField,
					config: v => userPaymentTableConfig(data, initData),
					isControlledInput: false
				});
			}
			if (data.isSuperAdmin) {
				fields.push({
					key: getSessionString(),
					text: 'Naplók',
					textKey: 'userLog',
					tab: 'userLog',
					component: EditFormTableBrowserField,
					config: v => userLogTableConfig(data, initData),
					isControlledInput: false
				});
			}
			if (initData.id != null && (data.type === 'athlete' || data.type === 'competitor')) {
				fields.push({
					key: getSessionString(),
					text: 'Sportorvosi',
					textKey: 'nsrSportorvosi',
					tab: 'nsrSportorvosi',
					component: EditFormTableBrowserField,
					config: v => nsrSportorvosiTableConfig(data, initData),
					isControlledInput: false
				});
			}
			if (data.type === 'athlete' || (data.type === 'profile' && data.user.isAthlete && (data.athleteRegType == null || data.athleteRegType === 'NORMAL' || data.athleteRegType === 'LICENSE_REDEEM'))) {
				fields.push({
					key: 'athlete.doctorDate',
					text: 'Sportorvosi lejárati dátum',
					textKey: 'athlete.doctorDate',
					tab: 'documentList',
					component: EditFormDateField,
					required: true,
					dependsOn: 'birthDate',
					visibleIf: birthDate => isUnderAge(birthDate),
					maxDate: !data.isSuperAdmin ? add(new Date(), { months: 6 }) : undefined
				});
				fields.push({
					key: 'athlete.doctorDate',
					text: 'Sportorvosi lejárati dátum',
					textKey: 'athlete.doctorDate',
					tab: 'documentList',
					component: EditFormDateField,
					required: true,
					dependsOn: 'birthDate',
					visibleIf: birthDate => !isUnderAge(birthDate),
					maxDate: !data.isSuperAdmin ? add(new Date(), { months: 12 }) : undefined
				});
			}
			if (data.type === 'competitor' || (data.type === 'profile' && data.user.isCompetitor)) {
				fields.push({
					key: 'competitor.doctorDate',
					text: 'Sportorvosi lejárati dátum',
					textKey: 'competitor.doctorDate',
					tab: 'documentList',
					component: EditFormDateField,
					required: true,
					dependsOn: 'birthDate',
					visibleIf: birthDate => isUnderAge(birthDate),
					maxDate: !data.isSuperAdmin ? add(new Date(), { months: 6 }) : undefined
				});
				fields.push({
					key: 'competitor.doctorDate',
					text: 'Sportorvosi lejárati dátum',
					textKey: 'competitor.doctorDate',
					tab: 'documentList',
					component: EditFormDateField,
					required: true,
					dependsOn: 'birthDate',
					visibleIf: birthDate => !isUnderAge(birthDate),
					maxDate: !data.isSuperAdmin ? add(new Date(), { months: 12 }) : undefined
				});
			}
			if (
				data.type === 'athlete' ||
				data.type === 'competitor' ||
				(data.type === 'profile' && ((data.user.isAthlete && (data.athleteRegType == null || data.athleteRegType === 'NORMAL' || data.athleteRegType === 'LICENSE_REDEEM')) || data.user.isCompetitor))
			) {
				fields.push({
					key: 'documentList',
					text: 'Dokumentumok',
					textKey: 'documentList',
					tab: 'documentList',
					component: EditFormSubComponent,
					defaultValue: {
						id: null,
						name: null,
						document: null
					},
					fields: [
						{
							key: 'name',
							text: 'Név',
							textKey: 'name',
							required: true,
							component: EditFormTextField
						},
						{
							key: 'document',
							text: 'Dokumentum',
							textKey: 'document',
							component: EditFormFileInputField
						}
					]
				});
			}
			return fields;
		}
	};
};

function PersonEditForm(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const [formData, setFormData] = useState(null);
	const [showProfileValidationProblem, setShowProfileValidationProblem] = useState(false);
	const { id } = useParams();
	const [data, setData] = useState(null);
	const [paymentIsPossible, setPaymentIsPossible] = useState(null);

	useEffect(() => {
		if (showProfileValidationProblem) dispatch(showMessage({ message: 'A profilodon hiányosságok vannak. Kérlek pótold azokat.' }));
	}, [showProfileValidationProblem]);

	if (formData == null) {
		if (id !== 'new') {
			PersonEditService.getFormDataById(id).then(result => setFormData(result));
		} else {
			PersonEditService.getFormData().then(result => setFormData(result));
		}
		return <></>;
	}
	if (id != null && id !== 'new' && paymentIsPossible == null) {
		PersonEditService.checkPaymentIsPossible(id).then(result => setPaymentIsPossible(result));
		return <></>;
	}
	let titleText = '';
	if (props.type === 'athlete') titleText = 'Egyesületi versenyző';
	else if (props.type === 'competitor') titleText = 'Egyéni versenyző';
	else if (props.type === 'referee') titleText = 'Versenybíró';
	else if (props.type === 'trainer') titleText = 'Edző';
	else if (props.type === 'technicalCommittee') titleText = 'Technikai bizottság';
	else if (props.type === 'profile') titleText = 'Profilom';

	const booleanList = [
		{
			id: true,
			name: 'Igen'
		},
		{
			id: false,
			name: 'Nem'
		}
	];
	const myConfig = config({ formData, type: props.type, titleText, booleanList, isSuperAdmin: user.isSuperAdmin, accessType: user.accessType, athleteRegType: user.athleteRegType, paymentIsPossible, user });

	const handleDataChange = (d, methods) => {
		setData(d);
		if (props.type === 'profile') {
			PersonEditService.validate(d).then(response => {
				if (!response.successful) {
					setShowProfileValidationProblem(true);
					Object.keys(response.properties).forEach(key => {
						if (response.properties[key].length > 0) {
							methods.setError(key, {
								type: 'manual',
								message: response.properties[key][0].message
							});
						}
					});
				}
			});
		}
	};

	return <EditFormPage config={myConfig} onDataChanged={(d, methods) => handleDataChange(d, methods)} preContent={<PersonFormInfo data={data} />} />;
}

export default withRouter(
	withReducer('medicalLicenseTable', reducer('medicalLicenseTable'))(withReducer('personUserPaymentTable', reducer('personUserPaymentTable'))(withReducer('personUserLogTable', reducer('personUserLogTable'))(PersonEditForm)))
);
