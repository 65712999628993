import TextTableCell from './TextTableCell';
import DateTableCell from './DateTableCell';
import DateTimeTableCell from './DateTimeTableCell';
import FileSizeTableCell from './FileSizeTableCell';
import IconFileTypeTableCell from './IconFileTypeTableCell';
import BooleanTableCell from './BooleanTableCell';

const TableCellRepo = {
	text: TextTableCell,
	date: DateTableCell,
	dateTime: DateTimeTableCell,
	fileSize: FileSizeTableCell,
	iconFileType: IconFileTypeTableCell,
	boolean: BooleanTableCell
};
export default TableCellRepo;
