import IdentifiableDto from 'modules/base/dto/IdentifiableDto';
import UserLogCategoryType from './UserLogCategoryType';

export default class UserLogTableDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.logLevel = null;
		/** @type {Date} */
		this.logDate = null;
		/** @type {number} */
		this.modifierUserId = null;
		/** @type {string} */
		this.modifierUserName = null;
		/** @type {string} */
		this.message = null;
		/** @type {string} */
		this.logCategory = null;
		/** @type {number} */
		this.userId = null;
		/** @type {string} */
		this.userName = null;
	}

	/**
	 * @typedef {Object} _UserLogTableDtoProps
	 * @property {string} logLevel
	 * @property {Date} logDate
	 * @property {number} modifierUserId
	 * @property {string} modifierUserName
	 * @property {string} message
	 * @property {string} logCategory
	 * @property {number} userId
	 * @property {string} userName
	 *
	 * @typedef {import("modules/base/dto/IdentifiableDto").IdentifiableDtoProps & _UserLogTableDtoProps} UserLogTableDtoProps
	 */
	/**
	 *
	 * @param {UserLogTableDtoProps} props
	 * @returns {UserLogTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.logLevel = props.logLevel;
		this.logDate = props.logDate != null ? new Date(props.logDate) : null;
		this.modifierUserId = props.modifierUserId;
		this.modifierUserName = props.modifierUserName;
		this.message = props.message;
		this.logCategory = props.logCategory;
		this.userId = props.userId;
		this.userName = props.userName;
		return this;
	}

	/**
	 *
	 * @param {string} key
	 * @returns {string}
	 */
	getAsString(key) {
		if (key === 'logCategory') {
			return this.logCategory ? UserLogCategoryType[this.logCategory] : '';
		}
		return super.getAsString(key);
	}
}
