import NotificationPanel from 'modules/ui/notificationPanel/NotificationPanel';
import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import { memo } from 'react';
import notificationRepo from 'app/fuse-configs/notificationRepo';

function RightSideLayout3() {
	return (
		<>
			<QuickPanel />
			{process.env.REACT_APP_NOTIFICATION === 'true' ? <NotificationPanel repo={notificationRepo} /> : null}
		</>
	);
}

export default memo(RightSideLayout3);
