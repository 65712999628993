import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import UserPaymentEditDto from 'app/admin/payment/dto/UserPaymentEditDto';
import nBossConnection from 'modules/base/service/nBossConnection';
import NamedDto from 'modules/base/dto/NamedDto';
import EnumDto from 'modules/base/dto/enum/EnumDto';

class UserPaymentEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'userPaymentEditControl';
	}

	/**
	 * @return {Promise<UserPaymentEditDto>}
	 */
	create() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/create`)
				.then(response => resolve(new UserPaymentEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<UserPaymentEditDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(new UserPaymentEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {UserPaymentEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, {
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {UserPaymentEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {string} query
	 * @param {number} limit
	 * @return {Promise<NamedDto[]>}
	 */
	getUserSelection(query, limit) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getUserSelection`, { query, limit })
				.then(response => resolve(response.data != null ? response.data.map(d => new NamedDto().setup(d)) : []))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<EnumDto[]>}
	 */
	getPaymentStatusSelection() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getPaymentStatusSelection`)
				.then(response => resolve(response.data != null ? response.data.map(d => new EnumDto().setup(d)) : []))
				.catch(reject);
		});
	}
}

export default new UserPaymentEditService();
