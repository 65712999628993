import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './navigation-i18n/en';
import hu from './navigation-i18n/hu';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('hu', 'navigation', hu);

const navigationConfig = [
	{
		id: 'organization',
		title: 'organization',
		translate: 'SZERVEZET',
		type: 'group',
		icon: 'apps',
		auth: [authRoles.organizer, authRoles.club, authRoles.competition, authRoles.contest, authRoles.competitionEntryClub],
		children: [
			{
				id: 'organizer',
				title: 'Versenyszervező',
				//translate: 'users',
				type: 'item',
				icon: 'person',
				url: '/organizer',
				auth: authRoles.organizer
			},
			{
				id: 'club',
				title: 'Klub',
				//translate: 'users',
				type: 'item',
				icon: 'person',
				url: '/club',
				auth: authRoles.club
			},
			{
				id: 'competition',
				title: 'Megpályázott események',
				//translate: 'users',
				type: 'item',
				icon: 'emoji_events',
				url: '/competition',
				auth: authRoles.competition
			},
			{
				id: 'contest',
				title: 'Kiírt események',
				//translate: 'users',
				type: 'item',
				icon: 'emoji_events',
				url: '/contest',
				auth: authRoles.contest
			},
			{
				id: 'competitionEntryClub',
				title: 'Nevezett versenyzők',
				type: 'item',
				icon: 'person',
				url: '/competition-entry-club',
				auth: authRoles.competitionEntryClub
			}
		]
	},
	{
		id: 'person',
		title: 'person',
		translate: 'SZEMÉLYI',
		type: 'group',
		icon: 'apps',
		auth: [authRoles.athlete, authRoles.competitor, authRoles.trainer, authRoles.referee, authRoles.technicalCommittee, authRoles.athleteTransfer],
		children: [
			{
				id: 'athlete',
				title: 'Egyesületi versenyző',
				//translate: 'users',
				type: 'item',
				icon: 'person',
				url: '/athlete',
				auth: authRoles.athlete
			},
			{
				id: 'competitor',
				title: 'Egyéni versenyző',
				//translate: 'users',
				type: 'item',
				icon: 'person',
				url: '/competitor',
				auth: authRoles.competitor
			},
			{
				id: 'referee',
				title: 'Versenybíró',
				//translate: 'users',
				type: 'item',
				icon: 'person',
				url: '/referee',
				auth: authRoles.referee
			},
			{
				id: 'trainer',
				title: 'Edző',
				//translate: 'users',
				type: 'item',
				icon: 'person',
				url: '/trainer',
				auth: authRoles.trainer
			},
			{
				id: 'technicalCommittee',
				title: 'Technikai bizottság',
				type: 'item',
				icon: 'person',
				url: '/technicalCommittee',
				auth: authRoles.technicalCommittee
			},
			{
				id: 'athleteTransfer',
				title: 'Átigazolás',
				type: 'item',
				icon: 'transfer_within_a_station',
				url: '/athlete-transfer',
				auth: authRoles.athleteTransfer
			}
		]
	},
	{
		id: 'payment',
		title: 'payment',
		translate: 'PÉNZÜGYEK',
		type: 'group',
		auth: [authRoles.payment, authRoles.licenseCost],
		children: [
			{
				id: 'user-payment',
				title: 'Csoportos befizetések',
				type: 'item',
				icon: 'payment',
				url: '/user-payment',
				auth: authRoles.payment
			},
			{
				id: 'license-cost',
				title: 'Díjak',
				type: 'item',
				icon: 'euro',
				url: '/license-cost',
				auth: authRoles.licenseCost
			}
		]
	},
	{
		id: 'users',
		title: 'users',
		translate: 'FELHASZNÁLÓ',
		type: 'group',
		icon: 'apps',
		auth: [authRoles.userAccount, authRoles.userRole],
		children: [
			{
				id: 'user-list',
				title: 'Hozzáférés',
				//translate: 'users',
				type: 'item',
				icon: 'person',
				url: '/user',
				auth: authRoles.userAccount
			},
			{
				id: 'dashboard-role',
				title: 'Jogkör',
				//translate: 'users',
				type: 'item',
				icon: 'perm_identity',
				url: '/dashboard-role',
				auth: authRoles.userRole
			}
		]
	},
	{
		id: 'valueSet',
		title: 'valueSet',
		translate: 'ÉRTÉKKÉSZLET',
		type: 'group',
		icon: 'apps',
		auth: [authRoles.valueSet, authRoles.emailTemplate],
		children: [
			{
				id: 'sport-category',
				title: 'Szakág',
				type: 'item',
				icon: 'menu_book',
				url: '/sport-category',
				auth: authRoles.valueSet
			},
			{
				id: 'comp-qualification',
				title: 'Versenyminősítés',
				type: 'item',
				icon: 'menu_book',
				url: '/comp-qualification',
				auth: authRoles.valueSet
			},
			{
				id: 'distance',
				title: 'Táv',
				type: 'item',
				icon: 'menu_book',
				url: '/distance',
				auth: authRoles.valueSet
			},
			{
				id: 'age-group',
				title: 'Korosztály',
				type: 'item',
				icon: 'menu_book',
				url: '/age-group',
				auth: authRoles.valueSet
			},
			{
				id: 'group-age-group',
				title: 'Korosztály csoport',
				type: 'item',
				icon: 'menu_book',
				url: '/group-age-group',
				auth: authRoles.valueSet
			},
			{
				id: 'competitor-competition',
				title: 'Egyéni reg. versenyek',
				type: 'item',
				icon: 'menu_book',
				url: '/competitor-competition',
				auth: authRoles.valueSet
			},
			{
				id: 'email-template',
				title: 'Email sablon',
				type: 'item',
				icon: 'menu_book',
				url: '/email-template',
				auth: authRoles.emailTemplate
			}
		]
	},
	{
		id: 'my-profile',
		title: 'Profilom',
		//translate: 'users',
		type: 'item',
		icon: 'person',
		url: '/profile',
		auth: authRoles.userProfile
	},
	{
		id: 'calendar',
		title: 'Versenynaptár',
		//translate: 'users',
		type: 'item',
		icon: 'emoji_events',
		url: '/calendar',
		auth: authRoles.competitionCalendar
	}
];

export default navigationConfig;
