const locale = {
	ADD_NEW: 'Add New',
	ADD_NEW_SHORT: 'Add',
	SEARCH: 'Search',
	BACK: 'Back',
	FILTER: 'Filter',
	FILTERS: 'Filters',
	FILTER_CONDITIONS: 'Filter Conditions',
	CLEAR_FILTERS: 'Clear filters',
	REFRESH: 'Refresh',
	IMPORT: 'Import',
	IMPORT_FILE_UPLOADING_ERROR: 'An error occurred during the upload.',
	IMPORT_DONE: 'Successful Import.',
	EXPORT: 'Export',
	EXPORT_TO_PDF: 'Export to PDF',
	NEW_FOLDER: 'New Folder',
	NEW_FILE: 'New File',
	NO_DATA: 'No Data',
	ROWS_PER_PAGE: 'Rows per page',
	PREV_PAGE: 'Previous Page',
	NEXT_PAGE: 'Next Page',
	DELETE: 'Delete',
	DELETE_DESC: 'Are you sure you want to delete this item?',
	DELETE_FAILED: 'Failed to delete the item.',
	OPEN: 'Open',
	MODIFY: 'Modify',
	SEARCH_FIELDS: 'Search Fields',
	SORT: 'Sort',
	ASC: 'Asc',
	DESC: 'Desc',
	DATE_FROM: 'From Date',
	DATE_TO: 'To Date'
};

export default locale;
