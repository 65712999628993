import _ from 'lodash';
import OrderableNamedDto from './OrderableNamedDto';

class OrderableNamedParentEditDto extends OrderableNamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.parent = null;
	}

	/**
	 * @typedef {Object} _OrderableNamedParentEditDtoProps
	 * @property {number} parent
	 *
	 * @typedef {import("./OrderableNamedDto").OrderableNamedDtoProps & _OrderableNamedParentEditDtoProps} OrderableNamedParentEditDtoProps
	 */
	/**
	 *
	 * @param {OrderableNamedParentEditDtoProps} props
	 * @returns {OrderableNamedParentEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.parent = props.parent;
		return this;
	}
}

export default OrderableNamedParentEditDto;
