import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';

export default class ContestTableDto extends NamedDto {
	/**
	 * @typedef {Object} _ContestTableDtoProps
	 *
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _ContestTableDtoProps} ContestTableDtoProps
	 */
	/**
	 *
	 * @param {ContestTableDtoProps} props
	 * @returns {ContestTableDto}
	 */
	setup(props) {
		super.setup(props);
		return this;
	}
}
