import _ from '@lodash';
import { useSelector, useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { motion } from 'framer-motion';
import { Button, Typography, Icon, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm, Controller, FormProvider, useWatch } from 'react-hook-form';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormFileInputField from 'modules/ui/editform/fields/EditFormFileInputField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import { useState } from 'react';
import AthleteTransferService from '../service/AthleteTransferService';
import AthleteTransferPaymentMode from '../dto/AthleteTransferPaymentMode';

const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';

function StartAthleteTransfer(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const history = useHistory();
	const { t } = useTranslation('editform');
	const [searchLoading, setSearchLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [searchResult, setSearchResult] = useState(undefined);
	const schema = yup.object().shape({
		athleteId: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		paymentMode: yup.string().nullable().required(t('validation:FIELD_REQUIRED')),
		targetClubCheck1: yup.boolean().required(t('validation:FIELD_REQUIRED')).oneOf([true], t('validation:FIELD_REQUIRED')),
		targetClubCheck2: yup.boolean().required(t('validation:FIELD_REQUIRED')).oneOf([true], t('validation:FIELD_REQUIRED')),
		parentalPermission: yup
			.string()
			.nullable()
			.test('isUp', 'Szülői engedély feltöltése kötelező.', (value, testContext) => {
				if (testContext.parent.licenseType === 'UP' && value == null) return false;
				return true;
			})
	});

	const formMethods = useForm({
		defaultValues: { licenseNumber: '', licenseType: null, isAdult: null, athleteId: null, targetClubCheck1: false, targetClubCheck2: false, parentalPermission: null, paymentMode: null },
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const licenseNumber = useWatch({
		control: formMethods.control,
		name: 'licenseNumber'
	});

	const athleteId = useWatch({
		control: formMethods.control,
		name: 'athleteId'
	});

	const handleSearch = () => {
		formMethods.clearErrors();
		setSearchLoading(true);
		setSearchResult(undefined);
		AthleteTransferService.searchAthlete(licenseNumber).then(resp => {
			setSearchLoading(false);
			setSearchResult(resp);
			if (resp != null && !resp.cachedCurrentLicensePaymentPaid) {
				formMethods.setValue('athleteId', resp.id);
				formMethods.setValue('licenseType', resp.athlete.licenseType);
				formMethods.setValue('isAdult', resp.athlete.licenseType !== 'UP');
			} else {
				formMethods.setValue('athleteId', null);
				formMethods.setValue('licenseType', null);
				formMethods.setValue('isAdult', null);
			}
		});
	};

	const handleSave = values => {
		console.log(values);
		setLoading(true);
		AthleteTransferService.startTransfer(values.athleteId, values.paymentMode, values.parentalPermission).then(resp => {
			if (resp.successful) {
				dispatch(showMessage({ message: 'Sikeres indítás.' }));
				history.goBack();
			} else {
				dispatch(showMessage({ message: 'Hiba történt az átigazolás indítása során.' }));
			}
		});
	};

	if (user.isClub) {
		return (
			<FormProvider {...formMethods}>
				<FusePageCarded
					classes={{
						toolbar: 'p-0',
						header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
					}}
					header={
						<div className="flex flex-1 w-full items-center justify-between">
							<div className="flex flex-col items-start max-w-full min-w-0">
								<motion.div initial={{ x: 20, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}>
									<Typography className="flex items-center sm:mb-12" component={Button} role="button" onClick={() => history.goBack(history)} color="inherit">
										<Icon className="text-20">arrow_back</Icon>
										<span className="hidden sm:flex mx-4 font-medium">Vissza</span>
									</Typography>
								</motion.div>
								<div className="flex items-center max-w-full">
									<div className="flex flex-col min-w-0 mx-8 sm:mc-16 gap-4">
										<motion.div initial={{ x: -20 }} animate={{ x: 0, transition: { delay: 0.3 } }}>
											<Typography className="text-16 sm:text-20 truncate font-semibold">Átigazolás indítása</Typography>
										</motion.div>
									</div>
								</div>
							</div>
							<motion.div className="flex" initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}>
								<Button
									className="whitespace-nowrap mx-4"
									variant="contained"
									color="secondary"
									disabled={loading}
									onClick={() => formMethods.handleSubmit(handleSave)()}
									startIcon={<Icon className="hidden sm:flex">save</Icon>}
								>
									Átigazolás indítása
								</Button>
							</motion.div>
						</div>
					}
					content={
						<div className="p-16 sm:p-24">
							<div className="mt-8 mb-16 font-semibold text-base">Kérem adja meg az átigazolandó egyesületi versenyző licenszszámát.</div>
							<div className="w-full flex flex-row">
								<Controller
									control={formMethods.control}
									name="licenseNumber"
									render={({ field, fieldState }) => (
										<EditFormTextField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'person' }}
											fieldConfig={{
												key: 'licenseNumber',
												text: 'Licensz szám',
												onEnterKey: () => {
													if (licenseNumber) handleSearch();
												}
											}}
										/>
									)}
								/>
								<div className="mt-12 ml-12">
									<IconButton onClick={() => handleSearch()} disabled={searchLoading || licenseNumber == null || licenseNumber.length === 0}>
										<Icon>search</Icon>
									</IconButton>
								</div>
							</div>
							{searchResult === null && <div className="mt-8 mb-16 font-semibold text-red">A megadott licensszámhoz nem található átigazolható egyesületi versenyző.</div>}
							{_.isObject(searchResult) && (
								<div className={`${blockClassName} mt-8 mb-16`}>
									<div>
										<div className="mt-8 mb-16 font-semibold">Név: {searchResult.name}</div>
										<div className="mt-8 mb-16 font-semibold">Klub: {searchResult.club.org.name}</div>
										<div className="mt-8 mb-16 font-semibold">Fizetett Licenszdíjat: {searchResult.cachedCurrentLicensePaymentPaid ? 'Igen' : 'Nem'}</div>
									</div>
									{searchResult.cachedCurrentLicensePaymentPaid && <div className="mt-8 mb-16 font-semibold text-red">Átigazolás nem lehetséges, mert a versenyző már befizette az éves díjat.</div>}
								</div>
							)}
							<Controller
								control={formMethods.control}
								name="athleteId"
								render={({ field, fieldState }) => <>{fieldState.error && <div className="mt-8 mb-16 font-semibold text-red">Átigazolható egyesületi versenyző kiválasztása szükséges.</div>}</>}
							/>
							{athleteId != null && searchResult && searchResult.athlete.licenseType === 'UP' && (
								<Controller
									control={formMethods.control}
									name="parentalPermission"
									render={({ field, fieldState }) => (
										<EditFormFileInputField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'person' }}
											fieldConfig={{
												key: 'parentalPermission',
												text: 'Szülői engedély',
												required: true
											}}
										/>
									)}
								/>
							)}
							{athleteId != null && (
								<Controller
									control={formMethods.control}
									name="paymentMode"
									render={({ field, fieldState }) => (
										<EditFormSelectField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'person' }}
											fieldConfig={{
												key: 'paymentMode',
												text: 'Fizetési mód',
												required: true,
												type: 'enum',
												items: Object.keys(AthleteTransferPaymentMode).map(key => {
													return { label: AthleteTransferPaymentMode[key], value: key };
												})
											}}
										/>
									)}
								/>
							)}
							{athleteId != null && (
								<Controller
									control={formMethods.control}
									name="targetClubCheck1"
									render={({ field, fieldState }) => (
										<EditFormCheckboxField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'person' }}
											fieldConfig={{
												key: 'targetClubCheck1',
												text: 'A hatályos Átigazolási Szabályzatot elolvastam, a benne foglaltakat tudomásul vettem és teljeskörűen betartom.'
											}}
										/>
									)}
								/>
							)}
							{athleteId != null && (
								<Controller
									control={formMethods.control}
									name="targetClubCheck2"
									render={({ field, fieldState }) => (
										<EditFormCheckboxField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'person' }}
											fieldConfig={{
												key: 'targetClubCheck2',
												text: 'Adott évben a Magyar Triatlon Szövetség verseny naptárában szereplő eseményen még nem indultam a hatályos Átigazolási Szabályzatban leírtak szerint.'
											}}
										/>
									)}
								/>
							)}
						</div>
					}
				/>
			</FormProvider>
		);
	}
	return <></>;
}

export default StartAthleteTransfer;
