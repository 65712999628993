import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { getRandomString } from 'modules/base/utils/GenericUtils';
import NotificationCard from './NotificationCard';
import reducer from './store';
import { closeNotificationPanel, toggleNotificationPanel } from './store/stateSlice';
import NotificationTableService from './service/NotificationTableService';
import NotificationService from './service/NotificationService';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.default,
		width: 320
	}
}));

function NotificationPanel(props) {
	const classes = useStyles();
	const location = useLocation();
	const dispatch = useDispatch();
	const state = useSelector(({ notificationPanel }) => notificationPanel.state);
	const [isLoading, setIsLoading] = useState(true);
	const [sessionName, setSessionName] = useState(null);
	const [result, setResult] = useState(null);
	const { repo } = props;

	useEffect(() => {
		if (state) {
			if (sessionName == null) {
				const _session = getRandomString();
				NotificationTableService.getInstance(_session, true, null).then(() => {
					setSessionName(_session);
				});
			} else {
				updateList();
			}
		}
	}, [state]);

	useEffect(() => {
		setIsLoading(true);

		if (sessionName !== null) {
			NotificationTableService.getList(sessionName, 0, 0).then(resp => {
				setResult(resp);
				setIsLoading(false);
			});
		}
	}, [sessionName]);

	const updateList = () => {
		NotificationTableService.getList(sessionName, 0, 0).then(resp => setResult(resp));
	};

	useEffect(() => {
		if (state) {
			dispatch(closeNotificationPanel());
		}
		// eslint-disable-next-line
	}, [location, dispatch]);

	function handleClose() {
		dispatch(closeNotificationPanel());
	}

	function handleDismiss(id) {
		NotificationService.deleteById(id).then(resp => {
			updateList();
		});
	}

	function handleDismissAll() {
		NotificationService.deleteAll().then(resp => {
			updateList();
		});
	}

	const getSpecificNotification = item => {
		const SpecificNotification = repo[item.notificationType];
		if (SpecificNotification) return <SpecificNotification item={item} />;
		return null;
	};

	return (
		<SwipeableDrawer classes={{ paper: clsx(classes.root) }} open={state} anchor="right" onOpen={ev => {}} onClose={ev => dispatch(toggleNotificationPanel())} disableSwipeToOpen>
			<IconButton className="m-4 absolute top-0 right-0 z-999" onClick={handleClose}>
				<Icon color="action">close</Icon>
			</IconButton>
			{isLoading ? <FuseLoading /> : null}
			{!isLoading && result ? (
				result.metadata.totalCount > 0 ? (
					<FuseScrollbars className="p-16">
						<div className="flex flex-col">
							<div className="flex justify-between items-end pt-136 mb-36">
								<Typography className="text-28 font-semibold leading-none">Értesítők</Typography>
								<Typography className="text-12 underline cursor-pointer" color="secondary" onClick={handleDismissAll}>
									összes törlése
								</Typography>
							</div>
							{result.data.map(item => (
								<NotificationCard key={item.id} className="mb-16" item={item} onClose={handleDismiss}>
									{getSpecificNotification(item)}
								</NotificationCard>
							))}
						</div>
					</FuseScrollbars>
				) : (
					<div className="flex flex-1 items-center justify-center p-16">
						<Typography className="text-24 text-center" color="textSecondary">
							Jelenleg nincs semmilyen értesítő.
						</Typography>
					</div>
				)
			) : null}
		</SwipeableDrawer>
	);
}

export default withReducer('notificationPanel', reducer)(memo(NotificationPanel));
