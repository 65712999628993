import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import EmailTemplateTableService from '../service/EmailTemplateTableService';

const config = {
	key: 'emailTemplateTable',
	text: 'Email sablonok',
	textKey: 'emailTemplate',
	service: EmailTemplateTableService,
	editFormState: item => `/email-template/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh'],
	rowAction: ['modify'],
	columns: [
		{
			key: 'name',
			text: 'Név',
			textKey: 'name',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'subject',
			text: 'Tárgy',
			textKey: 'subject',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'created',
			text: 'Létrehozva',
			textKey: 'created',
			type: 'dateTime',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'updated',
			text: 'Módosítva',
			textKey: 'updated',
			type: 'dateTime',
			sortable: true,
			disablePadding: false
		}
	]
};

function EmailTemplateTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('emailTemplateTable', reducer('emailTemplateTable'))(EmailTemplateTable);
