import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, fade } from '@material-ui/core/styles';
import { Button, Card, CardContent, CardActionArea, CardActions, Icon, Tooltip, IconButton } from '@material-ui/core';
import { valueToIcon } from 'modules/ui/tablebrowser/components/cells/IconFileTypeTableCell';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	root: {
		cursor: 'pointer'
	},
	pos: {
		marginBottom: 12
	},
	imageIcon: {
		background: fade(theme.palette.secondary.main, 0.8),
		'&:hover': {
			background: fade(theme.palette.secondary.main, 1)
		}
	}
}));

function FilePickerFileItem(props) {
	const classes = useStyles();
	const { t } = useTranslation('filePicker');

	return (
		<Card className={classes.root} variant="outlined">
			<CardActionArea component="a" href={props.getUrl(props.item)} target="_blank">
				<CardContent className="p-0">
					<div className="w-full flex items-center justify-center relative h-192 border-b">
						<Icon style={{ fontSize: '6rem' }}>{valueToIcon(props.item.fileCategory)}</Icon>
						{props.selectable ? (
							<Tooltip title={t('SELECT')}>
								<IconButton
									className={clsx(classes.imageIcon, 'absolute top-12 left-12')}
									onClick={e => {
										props.onSelectFile();
										e.preventDefault();
									}}
								>
									<Icon fontSize="small">present_to_all</Icon>
								</IconButton>
							</Tooltip>
						) : null}
					</div>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Button size="small" component="a" className="w-full text-center file-item" href={props.getUrl(props.item)} target="_blank">
					<div dangerouslySetInnerHTML={{ __html: props.item.fileName.replaceAll('_', '_<wbr>') }} />
				</Button>
			</CardActions>
		</Card>
	);
}

export default FilePickerFileItem;
