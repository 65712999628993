import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import Provider from 'react-redux/es/components/Provider';
import { HashRouter as Router, useHistory } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import NbossErrorHandler from 'modules/ui/component/NbossErrorHandler';
//import GlobalMessage from 'app/admin/common/component/GlobalMessage';
import i18nConfig from './i18n/i18nConfig';
import AppContext from './AppContext';
import { Auth } from './auth';
import routes from './fuse-configs/routesConfig';
import store from './store';
import CompetitorEndMessage from './admin/common/component/CompetitorEndMessage';
import CompetitorsChoice from './admin/common/component/CompetitorsChoice';

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName({ disableGlobal: true });

const App = () => {
	const history = useHistory();
	i18nConfig();
	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<Provider store={store}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<NbossErrorHandler>
							<Auth>
								<Router history={history}>
									<FuseAuthorization>
										<FuseTheme>
											<SnackbarProvider
												maxSnack={5}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'right'
												}}
												classes={{
													containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99'
												}}
											>
												<FuseLayout />
												{/* <GlobalMessage /> */}
												{/*<CompetitorEndMessage />*/}
												{/*<CompetitorsChoice />*/}
											</SnackbarProvider>
										</FuseTheme>
									</FuseAuthorization>
								</Router>
							</Auth>
						</NbossErrorHandler>
					</MuiPickersUtilsProvider>
				</Provider>
			</StylesProvider>
		</AppContext.Provider>
	);
};

export default App;
