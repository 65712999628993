import _ from '@lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { FormHelperText, FormControl, FormLabel } from '@material-ui/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResizePlugin from '@ckeditor/ckeditor5-image/src/imageresize';
import IndentPlugin from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlockPlugin from '@ckeditor/ckeditor5-indent/src/indentblock';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import CKFinderPlugin from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
import UploadAdapterPlugin from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import TablePlugin from '@ckeditor/ckeditor5-table/src/table';
import TableToolbarPlugin from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import MediaEmbedPlugin from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment';
//import RestrictedEditingModePlugin from '@ckeditor/ckeditor5-restricted-editing/src/restrictededitingmode';
import InsertDetailsPlugin from 'modules/ui/component/ckeditor/InsertDetails';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';
import SourceEditingPlugin from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import FilePickerDialog from 'modules/ui/component/FilePickerDialog';

const editorConfiguration = data => {
	const items = [
		'heading',
		'|',
		'bold',
		'italic',
		'alignment',
		'link',
		'bulletedList',
		'numberedList',
		'|',
		'outdent',
		'indent',
		'insertDetails',
		'|',
		'CKFinder',
		'blockQuote',
		'insertTable',
		'mediaEmbed',
		'undo',
		'redo',
		'|',
		'removeFormat',
		'sourceEditing'
	];
	if (!data.withCkFinder) items.splice(items.indexOf('CKFinder'), 1);
	return {
		plugins: [
			EssentialsPlugin,
			HeadingPlugin,
			BoldPlugin,
			ItalicPlugin,
			LinkPlugin,
			ListPlugin,
			IndentPlugin,
			IndentBlockPlugin,
			CKFinderPlugin,
			UploadAdapterPlugin,
			BlockQuotePlugin,
			TablePlugin,
			TableToolbarPlugin,
			TableProperties,
			TableCellProperties,
			MediaEmbedPlugin,
			ImagePlugin,
			AutoformatPlugin,
			ImageCaptionPlugin,
			ImageStylePlugin,
			ImageToolbarPlugin,
			ImageUploadPlugin,
			ImageResizePlugin,
			ParagraphPlugin,
			AlignmentPlugin,
			InsertDetailsPlugin,
			GeneralHtmlSupport,
			SourceEditingPlugin,
			RemoveFormat /*,
			RestrictedEditingModePlugin*/
		],
		toolbar: {
			items
		},
		image: {
			toolbar: ['imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', 'resizeImage', '|', 'toggleImageCaption', 'imageTextAlternative']
		},
		table: {
			contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
			tableProperties: {
				defaultProperties: {
					borderStyle: 'solid',
					borderColor: 'black',
					borderWidth: '1px'
				}
			},
			tableCellProperties: {
				defaultProperties: {
					borderStyle: 'solid',
					borderColor: 'black',
					borderWidth: '1px'
				}
			}
		},
		link: {
			decorators: {
				isExternal: {
					mode: 'manual',
					label: 'Open in a new tab',
					attributes: {
						target: '_blank'
					}
				}
			}
		},
		htmlSupport: {
			allow: [
				{
					name: /.*/,
					attributes: true,
					classes: true,
					styles: true
				}
			]
		},
		/*		restrictedEditing: {
			allowedCommands: items,
			allowedAttributes: ['bold', 'linkHref']
		},*/
		language: 'hu',
		mediaEmbed: {
			previewsInData: true
		}
	};
};

function EditFormHtmlField(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { value, onChange } = props.field;
	const { error } = props.fieldState;
	const [showFilePicker, setShowFilePicker] = useState(false);
	const [editor, setEditor] = useState(null);
	const setup = {
		withCkFinder: !_.isUndefined(props.fieldConfig.storageService) && !_.isUndefined(props.fieldConfig.browserService) && !_.isUndefined(props.fieldConfig.getUrl)
	};
	const myEditorConfiguration = editorConfiguration(setup);

	const handleReady = e => {
		setEditor(e);
		e.setData(value || '');
		e.commands.get('ckfinder').execute = () => {
			setShowFilePicker(true);
		};
	};

	const handleImageSelect = item => {
		setShowFilePicker(false);
		const imgCmd = editor.commands.get('imageUpload');
		if (!imgCmd.isEnabled) {
			dispatch(showMessage({ message: 'Az aktuális pozícióba nem illeszthető be kép!' }));
			return;
		}
		editor.execute('imageInsert', { source: props.fieldConfig.getUrl(item) });
	};

	const handleFileSelect = item => {
		setShowFilePicker(false);
		const range = editor.model.document.selection.getFirstRange();
		let textSelected = false;
		/* eslint no-restricted-syntax: "off" */
		for (const rangeItem of range.getItems()) {
			if (rangeItem.data.length > 0) {
				textSelected = true;
				break;
			}
		}
		if (textSelected) {
			editor.execute('link', props.fieldConfig.getUrl(item));
		} else {
			editor.model.change(writer => {
				const link = writer.createText(item.fileName, {
					linkHref: props.fieldConfig.getUrl(item)
				});
				editor.model.insertContent(link, editor.model.document.selection);
			});
		}
	};

	return (
		<div>
			<FormControl className="w-full mt-8 mb-16" error={error} required={props.fieldConfig.required}>
				<FormLabel className="mb-16">{!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}</FormLabel>
				{!_.isUndefined(value) ? (
					<CKEditor
						editor={props.fieldConfig.type && props.fieldConfig.type === 'inline' ? InlineEditor : ClassicEditor}
						config={myEditorConfiguration}
						onReady={e => handleReady(e)}
						data={value || ''}
						onChange={(event, e) => {
							const data = e.getData();
							onChange(data);
						}}
					/>
				) : null}
				{error ? <FormHelperText>{error.message}</FormHelperText> : null}
			</FormControl>
			{setup.withCkFinder ? (
				<FilePickerDialog
					open={showFilePicker}
					onClose={() => setShowFilePicker(false)}
					storageService={props.fieldConfig.storageService}
					browserService={props.fieldConfig.browserService}
					getUrl={props.fieldConfig.getUrl}
					onSelectImage={item => handleImageSelect(item)}
					onSelectFile={item => handleFileSelect(item)}
					fileSelectable
				/>
			) : null}
		</div>
	);
}

export default EditFormHtmlField;
