import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import LicenseCostTableService from '../service/LicenseCostTableService';

const config = {
	key: 'licenseCostTable',
	text: 'Díjak',
	textKey: 'licenseCostTable',
	service: LicenseCostTableService,
	editFormState: item => `/license-cost/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name',
			text: 'Megnevezés',
			textKey: 'name',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'feeNet',
			text: 'Nettó fizetendő',
			textKey: 'feeNet',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'feeGross',
			text: 'Bruttó fizetendő',
			textKey: 'feeGross',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'feeVat',
			text: 'Áfa tartalom',
			textKey: 'feeVat',
			type: 'text',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'autoAllocationEnabled',
			text: 'Engedélyezve',
			textKey: 'autoAllocationEnabled',
			type: 'boolean',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'lastAllocationDate',
			text: 'Utolsó díj kiosztás',
			textKey: 'lastAllocationDate',
			type: 'dateTime',
			sortable: true,
			disablePadding: false
		},
		{
			key: 'nextAllocationDate',
			text: 'Következő',
			textKey: 'nextAllocationDate',
			type: 'dateTime',
			sortable: true,
			disablePadding: false
		}
	]
};

function LicenseCostTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('licenseCostTable', reducer('licenseCostTable'))(LicenseCostTable);
