import IdentifiableDto from 'modules/base/dto/IdentifiableDto';
import ContactRegistrationDto from './ContactRegistrationDto';

export class OrganizerRegistrationDto extends IdentifiableDto {
	/*constructor() {
		super();
	}*/

	/**
	 * @typedef {Object} _OrganizerRegistrationDtoProps
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _OrganizerRegistrationDtoProps} OrganizerRegistrationDtoProps
	 */
	/**
	 *
	 * @param {OrganizerRegistrationDtoProps} props
	 * @returns {OrganizerRegistrationDto}
	 */
	setup(props) {
		super.setup(props);
		return this;
	}
}

export default OrganizerRegistrationDto;
