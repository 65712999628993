import _ from '@lodash';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function ProfileForm() {
	const history = useHistory();
	const user = useSelector(({ auth }) => auth.user);

	useEffect(() => {
		if (user.accessType === 'Org') {
			history.push(`/profile/org/${user.data.id}`);
		} else if (user.accessType === 'Person') {
			history.push(`/profile/person/${user.data.id}`);
		} else {
			history.push(`/user/edit/${user.data.id}`);
		}
	}, []);

	return <></>;
}

export default withRouter(ProfileForm);
