import { authRoles } from 'app/auth';
import UserPaymentTable from './table/UserPaymentTable';
import UserPaymentEditForm from './editform/UserPaymentEditForm';

export default [
	{
		path: '/user-payment/edit/:id',
		component: UserPaymentEditForm,
		auth: authRoles.payment
	},
	{
		path: '/user-payment/:sessionNameParam?',
		component: UserPaymentTable,
		auth: authRoles.payment
	}
];
