import { authRoles } from 'app/auth';
import PersonEditForm from 'app/admin/person/editform/PersonEditForm';
import OrganizationEditForm from 'app/admin/organization/editform/OrganizationEditForm';
import ProfileForm from './editform/ProfileForm';

export default [
	{
		path: '/profile/person/:id',
		component: () => <PersonEditForm type="profile" />,
		auth: authRoles.userProfile
	},
	{
		path: '/profile/org/:id',
		component: () => <OrganizationEditForm type="profile" />,
		auth: authRoles.userProfile
	},
	{
		path: '/profile',
		component: ProfileForm,
		auth: authRoles.userProfile
	}
];
