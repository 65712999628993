import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import nBossConnection from 'modules/base/service/nBossConnection';
import NewPasswordDto from './NewPasswordDto';

class UserAccountService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'userAccountControl';
	}

	/**
	 * @param {string} email
	 * @return {Promise<boolean>>}
	 */
	requestNewPassword(email) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/requestNewPassword`, { email })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {string} key
	 * @param {number} userId
	 * @param {NewPasswordDto} dto
	 * @return {Promise<EntityValidationResult>>}
	 */
	setNewPassword(key, userId, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/setNewPassword`, {
					key,
					userId,
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}
}

export default new UserAccountService();
