import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';
import RegistrationStatusType from './RegistrationStatusType';

export default class UserTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.email = null;
		/** @type {Date} */
		this.registrationTimestamp = null;
		/** @type {Date} */
		this.lastLoginTimestamp = null;
		/** @type {string} */
		this.registrationStatus = null;
		/** @type {Date} */
		this.birthDate = null;
		/** @type {NamedDto[]>} */
		this.roleList = null;
	}

	/**
	 * @typedef {Object} _UserTableDtoProps
	 * @property {string} email
	 * @property {Date} registrationTimestamp
	 * @property {Date} lastLoginTimestamp
	 * @property {string} registrationStatus
	 * @property {Date} birthDate
	 * @property {NamedDto[]} roleList
	 *
	 * @typedef {import("../NamedDto").NamedDtoProps & _UserTableDtoProps} UserTableDtoProps
	 */
	/**
	 *
	 * @param {UserTableDtoProps} props
	 * @returns {UserTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.email = props.email;
		this.registrationTimestamp = props.registrationTimestamp != null ? new Date(props.registrationTimestamp) : null;
		this.lastLoginTimestamp = props.lastLoginTimestamp != null ? new Date(props.lastLoginTimestamp) : null;
		this.registrationStatus = props.registrationStatus;
		this.birthDate = props.birthDate != null ? new Date(props.birthDate) : null;
		this.roleList = props.roleList != null ? props.roleList.map(d => new NamedDto().setup(d)) : [];
		return this;
	}

	getAsString(key) {
		if (key === 'registrationStatus') {
			return this.registrationStatus != null ? RegistrationStatusType[this.registrationStatus] : '';
		}
		if (key === 'roleList') {
			return _.isArray(this.roleList) ? this.roleList.map(d => d.name).join(', ') : '';
		}
		return super.getAsString(key);
	}
}
