import NamedDto from 'modules/base/dto/NamedDto';
import ClubRegistrationDto from './ClubRegistrationDto';
import ContactRegistrationDto from './ContactRegistrationDto';
import OrganizerRegistrationDto from './OrganizerRegistrationDto';

export class OrganizationRegistrationDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.fullName = null;
		/** @type {string} */
		this.password = null;
		/** @type {string} */
		this.passwordAgain = null;
		/** @type {string} */
		this.email = null;
		/** @type {string} */
		this.shortName = null;
		/** @type {string} */
		this.addressZipcode = null;
		/** @type {string} */
		this.addressCity = null;
		/** @type {number} */
		this.addressCounty = null;
		/** @type {string} */
		this.addressStreet = null;
		/** @type {number} */
		this.addressStreetType = null;
		/** @type {string} */
		this.addressNumber = null;
		/** @type {string} */
		this.addressFloor = null;
		/** @type {string} */
		this.addressDoor = null;
		/** @type {string} */
		this.taxNumber = null;
		/** @type {ContactRegistrationDto} */
		this.president = null;
		/** @type {ContactRegistrationDto} */
		this.contact = null;
		/** @type {string} */
		this.bankAccountNumber = null;
		/** @type {string} */
		this.billingName = null;
		/** @type {string} */
		this.bankName = null;
		/** @type {string} */
		this.billingAccountNumber = null;
		/** @type {string} */
		this.billingZipcode = null;
		/** @type {string} */
		this.billingCity = null;
		/** @type {number} */
		this.billingCounty = null;
		/** @type {string} */
		this.billingStreet = null;
		/** @type {number} */
		this.billingStreetType = null;
		/** @type {string} */
		this.billingNumber = null;
		/** @type {string} */
		this.billingFloor = null;
		/** @type {string} */
		this.billingDoor = null;
		/** @type {ContactRegistrationDto} */
		this.economicLeader = null;
		/** @type {ClubRegistrationDto} */
		this.club = null;
		/** @type {boolean} */
		this.isClub = null;
		/** @type {OrganizerRegistrationDto} */
		this.organizer = null;
		/** @type {boolean} */
		this.isOrganizer = null;
		/** @type {string} */
		this.dataSheetDocument = null;
		/** @type {boolean} */
		this.accept = null;
		/** @type {boolean} */
		this.isAdult = null;
		/** @type {boolean} */
		this.isSupply = null;
		/** @type {string} */
		this.fbLink = null;
		/** @type {string} */
		this.instaLink = null;
	}

	/**
	 * @typedef {Object} _OrganizationRegistrationDtoProps
	 * @property {string} fullName
	 * @property {string} password
	 * @property {string} passwordAgain
	 * @property {string} email
	 * @property {string} shortName
	 * @property {string} addressZipcode
	 * @property {string} addressCity
	 * @property {number} addressCounty
	 * @property {string} addressStreet
	 * @property {number} addressStreetType
	 * @property {string} addressNumber
	 * @property {string} addressFloor
	 * @property {string} addressDoor
	 * @property {string} taxNumber
	 * @property {ContactRegistrationDto} president
	 * @property {ContactRegistrationDto} contact
	 * @property {string} bankAccountNumber
	 * @property {string} billingName
	 * @property {string} bankName
	 * @property {string} billingAccountNumber
	 * @property {string} billingZipcode
	 * @property {number} billingCounty
	 * @property {string} billingStreet
	 * @property {number} billingStreetType
	 * @property {string} billingNumber
	 * @property {string} billingFloor
	 * @property {string} billingDoor
	 * @property {ContactRegistrationDto} economicLeader
	 * @property {ClubRegistrationDto} club
	 * @property {boolean} isClub
	 * @property {OrganizerRegistrationDto} organizer
	 * @property {boolean} isOrganizer
	 * @property {string} dataSheetDocument
	 * @property {boolean} accept
	 * @property {boolean} isAdult
	 * @property {boolean} isSupply
	 * @property {string} fbLink
	 * @property {string} instaLink
	 * @typedef {import("../NamedDto").NamedDtoProps & _OrganizationRegistrationDtoProps} OrganizationRegistrationDtoProps
	 */
	/**
	 *
	 * @param {OrganizationRegistrationDtoProps} props
	 * @returns {OrganizationRegistrationDto}
	 */
	setup(props) {
		super.setup(props);
		this.fullName = props.fullName;
		this.password = props.password;
		this.passwordAgain = props.passwordAgain;
		this.email = props.email;
		this.shortName = props.shortName;
		this.addressZipcode = props.addressZipcode;
		this.addressCity = props.addressCity;
		this.addressCounty = props.addressCounty;
		this.addressStreet = props.addressStreet;
		this.addressStreetType = props.addressStreetType;
		this.addressNumber = props.addressNumber;
		this.addressFloor = props.addressFloor;
		this.addressDoor = props.addressDoor;
		this.taxNumber = props.taxNumber;
		this.president = props.president != null ? new ContactRegistrationDto().setup(props.president) : null;
		this.contact = props.contact != null ? new ContactRegistrationDto().setup(props.contact) : null;
		this.bankAccountNumber = props.bankAccountNumber;
		this.billingName = props.billingName;
		this.bankName = props.bankName;
		this.billingAccountNumber = props.billingAccountNumber;
		this.billingZipcode = props.billingZipcode;
		this.billingCity = props.billingCity;
		this.billingCounty = props.billingCounty;
		this.billingStreet = props.billingStreet;
		this.billingStreetType = props.billingStreetType;
		this.billingNumber = props.billingNumber;
		this.billingFloor = props.billingFloor;
		this.billingDoor = props.billingDoor;
		this.economicLeader = props.economicLeader != null ? new ContactRegistrationDto().setup(props.economicLeader) : null;
		this.club = props.club != null ? new ClubRegistrationDto().setup(props.club) : null;
		this.isClub = props.isClub;
		this.organizer = props.organizer != null ? new OrganizerRegistrationDto().setup(props.organizer) : null;
		this.isOrganizer = props.isOrganizer;
		this.dataSheetDocument = props.dataSheetDocument;
		this.accept = props.accept;
		this.isAdult = props.isAdult;
		this.isSupply = props.isSupply;
		this.fbLink = props.fbLink;
		this.instaLink = props.instaLink;
		return this;
	}
}

export default OrganizationRegistrationDto;
