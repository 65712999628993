import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';
import TableHeaderAllSelectionCheckbox from './header/TableHeaderAllSelectionCheckbox';

const useStyles = makeStyles(theme => ({
	actionsButtonWrapper: {
		background: theme.palette.background.paper
	}
}));

function TableBrowserHead(props) {
	const classes = useStyles(props);
	const { t } = useTranslation(props.config.topic ? ['tableBrowser', props.config.topic] : 'tableBrowser');
	const createSortHandler = property => event => {
		props.onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow className="h-48 sm:h-64">
				{props.config.bulkSelection ? <TableHeaderAllSelectionCheckbox config={props.config} /> : null}
				{props.config.rowButtonList
					? props.config.rowButtonList.map(r => {
							return <TableCell key={r.key} padding="none" className="w-0 text-center z-99" />;
					  })
					: null}
				{props.config.rowAction
					? props.config.rowAction.map(r => {
							return <TableCell key={r} padding="none" className="w-0 text-center z-99" />;
					  })
					: null}
				{props.config.columns.map(row => {
					return !_.isFunction(row.visibleIf) || row.visibleIf() ? (
						<TableCell
							className="p-4 md:p-16"
							key={row.key}
							align={row.align}
							padding={row.disablePadding ? 'none' : 'default'}
							sortDirection={props.order.propertyName === row.key ? (props.order.ascending ? 'asc' : 'desc') : false}
						>
							{row.sortable ? (
								<Tooltip title={props.order.propertyName === row.key ? (props.order.ascending ? t('DESC') : t('ASC')) : t('SORT')} placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
									<TableSortLabel active={props.order.propertyName === row.key} direction={props.order.ascending ? 'asc' : 'desc'} onClick={createSortHandler(row.key)} className="font-semibold">
										{!_.isUndefined(row.text) ? row.text : t(`${props.config.topic}:${row.key}`)}
									</TableSortLabel>
								</Tooltip>
							) : !_.isUndefined(row.text) ? (
								row.text
							) : (
								t(`${props.config.topic}:${row.key}`)
							)}
						</TableCell>
					) : null;
				}, this)}
				{props.config.rowEndButtonList
					? props.config.rowEndButtonList.map(r => {
							return <TableCell key={r.key} padding="none" className="w-0 text-center z-99" />;
					  })
					: null}
				{props.config.rowEndAction
					? props.config.rowEndAction.map(r => {
							return <TableCell key={r} padding="none" className="w-0 text-center z-99" />;
					  })
					: null}
			</TableRow>
		</TableHead>
	);
}

export default TableBrowserHead;
