import KeyedDto from 'modules/base/dto/KeyedDto';
import NamedDto from 'modules/base/dto/NamedDto';
import PaymentStatusType from './PaymentStatusType';

export class UserPaymentTableDto extends KeyedDto {
	constructor() {
		super();
		/** @type {string} */
		this.description = null;
		/** @type {number} */
		this.payableNet = null;
		/** @type {number} */
		this.payableVat = null;
		/** @type {number} */
		this.payableGross = null;
		/** @type {Date} */
		this.billingDate = null;
		/** @type {NamedDto} */
		this.user = null;
		/** @type {string} */
		this.paymentStatus = null;
		/** @type {Date} */
		this.paymentDate = null;
		/** @type {string} */
		this.paymentTransaction = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Date} */
		this.updated = null;
		/** @type {string} */
		this.invoice = null;
		/** @type {string} */
		this.protectionLevel = null;
	}

	/**
	 * @typedef {Object} UserPaymentTableDto
	 * @property {string} description
	 * @property {number} payableNet
	 * @property {number} payableVat
	 * @property {number} payableGross
	 * @property {Date} billingDate
	 * @property {NamedDto} user
	 * @property {string} paymentStatus
	 * @property {Date} paymentDate
	 * @property {string} paymentTransaction
	 * @property {Date} created
	 * @property {Date} updated
	 * @property {string} invoice
	 * @property {string} protectionLevel
	 * @typedef {import("modules/base/dto/KeyedDto").KeyedDtoProps & UserPaymentTableDto} UserPaymentTableDtoProps
	 */
	/**
	 *
	 * @param {UserPaymentTableDtoProps} props
	 * @returns {UserPaymentTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.description = props.description;
		this.payableNet = props.payableNet;
		this.payableVat = props.payableVat;
		this.payableGross = props.payableGross;
		this.billingDate = props.billingDate != null ? new Date(props.billingDate) : null;
		this.user = props.user != null ? new NamedDto().setup(props.user) : null;
		this.paymentStatus = props.paymentStatus;
		this.paymentDate = props.paymentDate != null ? new Date(props.paymentDate) : null;
		this.paymentTransaction = props.paymentTransaction;
		this.created = props.created != null ? new Date(props.created) : null;
		this.updated = props.updated != null ? new Date(props.updated) : null;
		this.invoice = props.invoice;
		this.protectionLevel = props.protectionLevel;
		return this;
	}

	getAsString(key) {
		if (key === 'paymentStatus') {
			return this.paymentStatus != null ? PaymentStatusType[this.paymentStatus] : '';
		}
		return super.getAsString(key);
	}
}

export default UserPaymentTableDto;
