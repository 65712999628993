import _ from '@lodash';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import huLocale from 'date-fns/locale/hu';
import { InputAdornment } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { determineEditableAccess } from '../EditFormUtils';

const localeMap = {
	en: enLocale,
	hu: huLocale
};

const localeTextMap = {
	en: {
		cancel: 'Cancel',
		ok: 'Ok',
		clear: 'Clear'
	},
	hu: {
		cancel: 'Mégse',
		ok: 'Ok',
		clear: 'Törlés'
	}
};

function EditFormTimeField(props) {
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const [locale, setLocale] = useState('hu');
	const { error } = props.fieldState;
	const [dateValue, setDateValue] = useState(null);
	const { value, onChange } = props.field;
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));

	const handleDateChange = date => {
		setDateValue(date);
		if (date != null) {
			const sec = date.getHours() * 60 * 60 + date.getMinutes() * 60;
			onChange(sec);
		} else {
			onChange(null);
		}
	};

	useEffect(() => {
		if (_.isNumber(value)) {
			const date = new Date();
			const hour = Math.floor(value / 3600);
			const min = (value - hour * 3600) / 60;
			date.setHours(hour);
			date.setMinutes(min);
			setDateValue(date);
		} else {
			setDateValue(null);
		}
	}, [value]);

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
			<TimePicker
				id={props.field.name}
				{...props.field}
				value={dateValue}
				onChange={date => handleDateChange(date)}
				label={!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
				fullWidth
				className={clsx('mt-8 mb-16', props.fieldConfig.className)}
				inputVariant="outlined"
				error={error}
				required={props.fieldConfig.required}
				helperText={error ? error.message : null}
				cancelLabel={localeTextMap[locale].cancel}
				okLabel={localeTextMap[locale].ok}
				clearLabel={localeTextMap[locale].clear}
				ampm={false}
				clearable
				readOnly={readOnly}
				InputProps={{
					endAdornment: readOnly ? (
						<InputAdornment position="end">
							<Lock fontSize="small" color="disabled" />
						</InputAdornment>
					) : null
				}}
			/>
		</MuiPickersUtilsProvider>
	);
}

export default EditFormTimeField;
