import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class NotificationDto extends IdentifiableDto {
	/**
	 * @module NotificationDto
	 */
	constructor() {
		super();
		/** @type {string} */
		this.message = null;
		/** @type {string} */
		this.severity = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Date} */
		this.updated = null;
		/** @type {string} */
		this.notificationType = null;
		/** @type {Object} */
		this.data = null;
	}

	/**
	 * @typedef {Object} _NotificationDtoProps
	 * @property {string} message
	 * @property {string} severity
	 * @property {Date} created
	 * @property {Date} updated
	 * @property {string} notificationType
	 * @property {Object} data
	 * @typedef {import("./IdentifiableDto").IdentifiableDtoProps & _NotificationDtoProps} NotificationDtoProps
	 */
	/**
	 *
	 * @param {NotificationDtoProps} props
	 * @returns {NotificationDto}
	 */
	setup(props) {
		super.setup(props);
		this.message = props.message;
		this.severity = props.severity;
		this.created = props.created != null ? new Date(props.created) : null;
		this.updated = props.updated != null ? new Date(props.updated) : null;
		this.notificationType = props.notificationType;
		this.data = props.data;
		return this;
	}
}

export default NotificationDto;
