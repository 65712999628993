import _ from '@lodash';
import { useEffect, useState } from 'react';
import { useParams, withRouter, Link } from 'react-router-dom';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { Icon, Typography, Table, TableContainer, TableBody, TableHead, TableCell, TableRow, Tabs, Tab } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import huLocale from 'date-fns/locale/hu';
import { useDispatch } from 'react-redux';
import CompetitionService from '../service/CompetitionService';
import CalendarViewEntryPriceList from './CalendarViewEntryPriceList';
import CalendarViewDistanceList from './CalendarViewDistanceList';

const formatDate = 'yyyy MMMM d.';
const formatDateTime = 'yyyy MMMM d. HH:mm';
const formatTime = 'HH:mm';

function CalendarEventViewPage() {
	const dispatch = useDispatch();
	const [data, setData] = useState(null);
	const { id } = useParams();
	const theme = useTheme();
	const [tabValue, setTabValue] = useState(0);

	useEffect(() => {
		CompetitionService.getDetails(id).then(d => {
			setData(d);
		});
	}, [id]);

	const convertTime = value => {
		if (value) {
			const date = new Date();
			const hour = Math.floor(value / 3600);
			const min = (value - hour * 3600) / 60;
			date.setHours(hour);
			date.setMinutes(min);
			return date;
		}
		return null;
	};

	const renderValue = (selected, items) => {
		const valueKey = 'id';
		const labelKey = 'name';
		if (_.isArray(selected))
			return items
				.filter(d => selected.indexOf(d[valueKey]) > -1)
				.map(d => d[labelKey])
				.join(', ');
		if (!_.isUndefined(selected) && selected !== null && items) {
			const itemIndex = items.findIndex(d => selected === d[valueKey]);
			if (itemIndex > -1) return items[itemIndex][labelKey];
		}
		return '';
	};

	const eventDate = () => {
		let result = '';
		if (data.date) result += format(data.date, formatDateTime, { locale: huLocale });
		if (data.endDate) result += ` - ${format(data.endDate, formatDateTime, { locale: huLocale })}`;
		return result;
	};

	if (data === null) return <></>;

	return (
		<FusePageCarded
			classes={{
				toolbar: 'p-0',
				header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
			}}
			contentToolbar={
				<Tabs value={tabValue} onChange={(e, v) => setTabValue(v)} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" classes={{ root: 'w-full h-64' }}>
					<Tab key="0" className="h-64" label="Esemény" />
					<Tab key="1" className="h-64" label="Távok" />
					<Tab key="2" className="h-64" label="Nevezési díjak" />
				</Tabs>
			}
			header={
				<div className="flex flex-1 w-full items-center justify-between">
					<div className="flex flex-col items-start max-w-full min-w-0">
						<motion.div initial={{ x: 20, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}>
							<Typography className="flex items-center sm:mb-12" component={Link} role="button" to="/calendar" color="inherit">
								<Icon className="text-20">{theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}</Icon>
								<span className="hidden sm:flex mx-4 font-medium">Vissza</span>
							</Typography>
						</motion.div>

						<div className="flex items-center max-w-full">
							<div className="flex flex-col min-w-0 mx-8 sm:mc-16">
								<motion.div initial={{ x: -20 }} animate={{ x: 0, transition: { delay: 0.3 } }}>
									<Typography className="text-16 sm:text-20 truncate font-semibold">{data.name}</Typography>
									<Typography variant="caption" className="font-medium">
										{eventDate()}
									</Typography>
								</motion.div>
							</div>
						</div>
					</div>
				</div>
			}
			content={
				<div className="p-16 sm:p-24">
					<div className={tabValue !== 0 ? 'hidden' : ''}>
						<div className="flex flex-col">
							<DataBlock label="Általános információk" type="main">
								<DataLine label="Versenyközpont címe" value={data.address} />
								<DataLine
									label="Verseny weboldala"
									value={
										data.officialWebsite ? (
											<a href={data.officialWebsite} target="_blank" rel="noreferrer">
												{data.officialWebsite}
											</a>
										) : (
											'-'
										)
									}
								/>
								<DataLine label="GPS koordináta" value={`${data.coordLat}, ${data.coordLng}`} />
								<DataLine label="Nevezési határidő" value={format(data.regDeadline, formatDate, { locale: huLocale })} />
							</DataBlock>

							<DataBlock label="Rendező adatai" type="main">
								<DataBlock label="(Versenyszervező - Kapcsolattartó) Tagszervezet" type="sub">
									<DataLine label="Név" value={data.orgName} />
									<DataLine label="Klub" value={data.createdOrg.name} />
									<DataLine label="E-mail" value={data.orgEmail} />
									<DataLine label="Telefonszám" value={data.phoneNumber} />
									<DataLine
										label="Weboldal"
										value={
											data.officialWebsite ? (
												<a href={data.officialWebsite} target="_blank" rel="noreferrer">
													{data.orgWebsite}
												</a>
											) : (
												'-'
											)
										}
									/>
								</DataBlock>
								<DataBlock label="Versenyigazgató" type="sub">
									<DataLine label="Név" value={data.directorName} />
									<DataLine label="E-mail" value={data.directorEmail} />
									<DataLine label="Telefonszám" value={data.directorPhoneNumber} />
								</DataBlock>
								<DataBlock label="Szervező bizottság elnöke" type="sub">
									<DataLine label="Név" value={data.orgPresName} />
									<DataLine label="E-mail" value={data.orgPresEmail} />
									<DataLine label="Telefonszám" value={data.orgPresPhoneNumber} />
								</DataBlock>
							</DataBlock>

							<DataBlock label="MTSZ küldöttek" type="main">
								<DataBlock label="Vezető versenybíró" type="sub">
									<DataLine label="Név" value={data.refereeName} />
									<DataLine label="E-mail" value={data.refereeEmail} />
									<DataLine label="Telefonszám" value={data.refereePhoneNumber} />
								</DataBlock>
								<DataBlock label="Technikai felügyelő" type="sub">
									<DataLine label="Név" value={data.supervisorName} />
									<DataLine label="E-mail" value={data.supervisorEmail} />
									<DataLine label="Telefonszám" value={data.supervisorPhoneNumber} />
								</DataBlock>
							</DataBlock>

							<DataBlock label="Versenyiroda" type="main">
								<DataLine label="Helye" value={data.officeAddress} />
								<DataLine label="Nyitvatartása" value={data.officeHours} />
								<DataBlock label="Felelős személy" type="sub">
									<DataLine label="Név" value={data.officeRepName} />
									<DataLine label="E-mail" value={data.officeRepEmail} />
									<DataLine label="Telefonszám" value={data.officeRepPhoneNumber} />
								</DataBlock>
							</DataBlock>

							<DataBlock label="Technikai információk" type="main">
								<DataBlock label="Technikai tájékoztató" type="sub">
									<DataLine label="Helye" value={data.techAddress} />
									<DataLine label="Ideje" value={data.techDate ? format(data.techDate, formatDateTime, { locale: huLocale }) : null} />
								</DataBlock>
								<DataBlock label="Úszópálya információk" type="sub">
									<DataLine label="Várható vízhőmérsékelt" value={data.waterTemp} />
									<DataLine label="Egyéb információ" value={data.waterOtherInfo} />
								</DataBlock>
								<DataLine label="Kerékpárpálya információk" value={data.bikeTrackOtherInfo} />
								<DataLine label="Futópálya információk" value={data.runningTrackOtherInfo} />
								<DataBlock label="Időmérés" type="sub">
									<DataLine label="Jellege" value={data.timingType} />
									<DataLine label="Időmérő" value={data.timingTiming} />
								</DataBlock>
								<DataLine label="Bolyozás" value={data.bolyozas !== null ? (data.bolyozas ? 'Igen' : 'Nem ') : null} />
								<DataLine label="Egyéb információ" value={data.techOtherInfo} />
							</DataBlock>

							<DataBlock label="Egyéb" type="main">
								<DataLine label="Díjazás" value={data.pricing} />
								<DataLine label="Megközelítési és parkolás információk" value={data.parkingInfo} />
								<DataLine label="Verseny szabályzattól eltérő rendelkezések" value={data.regulations} />
							</DataBlock>
						</div>

						<div className="mt-16 bg-gray-100 rounded-8 shadow-lg">
							{data.events ? (
								<TableContainer>
									<div className="font-extrabold text-2xl text-center sm:text-left py-24 px-16">Időrend</div>
									<div className="bg-white rounded-8 m-16 shadow-xl">
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>Kategória</TableCell>
													<TableCell>Táv</TableCell>
													<TableCell>Egyéni korosztály</TableCell>
													<TableCell>Egyéniből számolt csapat korosztály</TableCell>
													<TableCell>Idő</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{data.events.map(row => (
													<TableRow key={row.id}>
														<TableCell>{row.category}</TableCell>
														<TableCell>{row.distance.name}</TableCell>
														<TableCell>{row.ageGroup.name}</TableCell>
														<TableCell>{row.groupAgeGroup.name}</TableCell>
														<TableCell>{row.time ? format(convertTime(row.time), formatTime, { locale: huLocale }) : null}</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</div>
								</TableContainer>
							) : null}
						</div>
					</div>
					<div className={tabValue !== 1 ? 'hidden' : ''}>
						<CalendarViewDistanceList data={data} />
					</div>

					<div className={tabValue !== 2 ? 'hidden' : ''}>
						<CalendarViewEntryPriceList data={data} />
					</div>
				</div>
			}
		/>
	);
}

export default withRouter(CalendarEventViewPage);

function DataBlock(props) {
	return (
		<div className={`flex flex-col rounded-8 my-8 first:mt-0 last:mb-0 shadow-lg ${props.type === 'main' ? 'bg-gray-100' : 'bg-white'}`}>
			{props.label ? <div className={props.type === 'main' ? 'font-extrabold text-2xl text-center sm:text-left pt-16 px-16' : 'font-bold text-lg leading-8 text-center border-b shadow-lg py-16'}>{props.label}</div> : null}
			<div className="flex flex-col p-16">{props.children}</div>
		</div>
	);
}

function DataLine(props) {
	return (
		<div className="flex flex-row gap-16 py-8 first:pt-0 last:pb-0 border-b last:border-0">
			<div className="flex-1 text-right font-bold">{props.label}</div>
			<div className="flex-1 text-left">{props.value ? props.value : '-'}</div>
		</div>
	);
}
