import _ from '@lodash';
import { Tooltip, IconButton, TableCell, Icon } from '@material-ui/core';
import { useEffect, useState } from 'react';
import nBossConnection from 'modules/base/service/nBossConnection';

function DownloadInvoiceTableButton(props) {
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		setDisabled(props.item.invoice === null);
	}, [props.item]);

	return (
		<TableCell className="w-auto text-center" padding="none">
			<Tooltip title="Számla letöltése">
				<IconButton disabled={disabled} role="button" component="a" href={`${nBossConnection.basePath}invoiceControl/download?key=${props.item.invoice}`} target="_blank">
					<Icon fontSize="small">receipt</Icon>
				</IconButton>
			</Tooltip>
		</TableCell>
	);
}

export default DownloadInvoiceTableButton;
