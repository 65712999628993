import nBossConnection from 'modules/base/service/nBossConnection';
import NotificationStatusDto from '../dto/NotificationStatusDto';
import NotificationDto from '../dto/NotificationDto';

class NotificationService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'dashboardNotificationControl';
	}

	/**
	 * @return {Promise<NotificationStatusDto>}
	 */
	getStatus() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getStatus`)
				.then(response => resolve(new NotificationStatusDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<Object>}
	 */
	deleteById(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/deleteById`, {
					id
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<Object>}
	 */
	deleteAll(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/deleteAll`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} firstResult
	 * @param {number} maxResults
	 * @return {Promise<NotificationDto[]>}
	 */
	getList(firstResult, maxResults) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getList`, { firstResult, maxResults })
				.then(response => resolve(new NotificationDto().setup(response.data)))
				.catch(reject);
		});
	}
}

export default new NotificationService();
