/* eslint-disable prettier/prettier */
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

function FooterLayout1(props) {
	const footerTheme = useSelector(selectFooterTheme);

	return (
		<ThemeProvider theme={footerTheme}>
			<AppBar id="fuse-footer" className={clsx('relative z-20 shadow-md', props.className)} color="default" style={{ backgroundColor: footerTheme.palette.background.paper }}>

				{/* <div className='w-full bg-white-400 p-14 flex flex-col sm:flex-row justify-center items-center gap-12'>

					<div className='flex-1 flex flex-col gap-12'>
						<h3> Kedves Versenyzők!</h3>

						<div className='text-xs'>Örömmel értesítünk Benneteket, hogy 2022.03.12-én megnyitjuk a Magyar Triatlon Szövetség adatbázisát. A szombati naptól kezdve lehetőség van az új versenyengedélyek kiváltására, illetve a 2022. évi licensz díjak és egyesületi tagdíjak befizetésére.
							Szeretnénk felhívni a figyelmet, hogy a rendszer tesztelése megtörtént, azonban arra szeretnénk kérni Benneteket, ha bármilyen problémát észleltek a regisztrációkor, befizetéskor vagy a számlázással kapcsolatban, akkor legyetek kedvesek jelezni felénk. <span className='font-semibold'>A regisztrációval, befizetéssel és számlázással kapcsolatban felmerülő kérdésekben az alábbi elérhetőségen állunk rendelkezésetekre: <a href='tel:+36703999771'>+36 70 399 9771</a></span></div>

					</div>

					<div className='flex flex-col gap-12'>
						<Button component='a' href='/documents/mtsz-versenyengedely-utmutato.pdf' color='primary' variant='contained'>Versenyengedély útmutató</Button>
						<Button component='a' href='tel:+36703999771' color='primary' variant='contained'>Telefonos elérhetőség</Button>
					</div>

				</div> */}

				<Toolbar className="min-h-64 px-8 sm:px-12 py-8 flex flex-col md:flex-row items-center justify-between overflow-x-auto">
					<Typography>{`Magyar Triatlon Szövetség - Adminisztráció © ${new Date().getFullYear()}`}</Typography>
					<img className="h-24" src="assets/barion/logos.png" alt="Barion brands" />
				</Toolbar>
			</AppBar>

		</ThemeProvider>
	);
}

export default memo(FooterLayout1);
