import _ from '@lodash';
import React, { useEffect, useState } from 'react';
import { InputLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { convertDateToBackingsString, convertBackingsStringToDate } from 'modules/base/utils/DateUtils';
import set from 'date-fns/set';
import enLocale from 'date-fns/locale/en-US';
import huLocale from 'date-fns/locale/hu';

const localeMap = {
	en: enLocale,
	hu: huLocale
};

const dateLocaleFormatMap = {
	en: 'd MMMM yyyy',
	hu: 'yyyy MMMM d.'
};

const dateTimeLocaleFormatMap = {
	en: 'd MMMM yyyy HH:mm',
	hu: 'yyyy MMMM d. HH:mm'
};

const localeTextMap = {
	en: {
		cancel: 'Cancel',
		ok: 'Ok',
		clear: 'Clear'
	},
	hu: {
		cancel: 'Mégse',
		ok: 'Ok',
		clear: 'Törlés'
	}
};

function TableFilterDateIntervalField(props) {
	const [locale, setLocale] = useState('hu');
	const { t } = useTranslation(props.config.topic ? ['tableBrowser', props.config.topic] : 'tableBrowser');
	const filterList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterList);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);

	useEffect(() => {
		const index = filterList.findIndex(el => el.propertyName === props.filterConfig.propertyName);
		if (index > -1) {
			setFromDate(filterList[index].value.from != null ? convertBackingsStringToDate(filterList[index].value.from) : null);
			setToDate(filterList[index].value.to != null ? convertBackingsStringToDate(filterList[index].value.to) : null);
		} else {
			setFromDate(null);
			setToDate(null);
		}
	}, [filterList]);

	useEffect(() => {
		if (fromDate || toDate) {
			let _fromDate = fromDate;
			let _toDate = toDate;
			if (props.filterConfig.withTime) {
				if (_fromDate != null) {
					_fromDate = convertDateToBackingsString(set(_fromDate, { seconds: 0, milliseconds: 0 }));
				}
				if (_toDate != null) {
					_toDate = convertDateToBackingsString(set(_toDate, { seconds: 59, milliseconds: 999 }));
				}
			} else {
				if (_fromDate != null) {
					_fromDate = convertDateToBackingsString(set(_fromDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
				}
				if (_toDate != null) {
					_toDate = convertDateToBackingsString(set(_toDate, { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }));
				}
			}
			if (_fromDate !== null || _toDate !== null) {
				const filterValue = {
					propertyName: props.filterConfig.propertyName,
					type: props.filterConfig.type,
					value: {
						from: _fromDate,
						to: _toDate
					}
				};
				props.onFilterChanged(filterValue);
			} else {
				props.onFilterRemoved(props.filterConfig.propertyName);
			}
		}
	}, [fromDate, toDate]);

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
			<div>
				<InputLabel className="mt-16 w-full">{!_.isUndefined(props.filterConfig.text) ? props.filterConfig.text : t(`${props.config.topic}:${props.filterConfig.propertyName}`)}</InputLabel>
				{props.filterConfig.withTime ? (
					<div>
						<DateTimePicker
							label={t('DATE_FROM')}
							margin="dense"
							className="w-full"
							value={fromDate}
							onChange={e => setFromDate(e)}
							format={dateTimeLocaleFormatMap[locale]}
							cancelLabel={localeTextMap[locale].cancel}
							okLabel={localeTextMap[locale].ok}
							clearLabel={localeTextMap[locale].clear}
						/>
						<DateTimePicker
							label={t('DATE_TO')}
							className="w-full"
							value={toDate}
							onChange={e => setToDate(e)}
							format={dateTimeLocaleFormatMap[locale]}
							cancelLabel={localeTextMap[locale].cancel}
							okLabel={localeTextMap[locale].ok}
							clearLabel={localeTextMap[locale].clear}
						/>
					</div>
				) : (
					<div>
						<DatePicker
							label={t('DATE_FROM')}
							margin="dense"
							className="w-full"
							value={fromDate}
							onChange={e => setFromDate(e)}
							format={dateLocaleFormatMap[locale]}
							cancelLabel={localeTextMap[locale].cancel}
							okLabel={localeTextMap[locale].ok}
							clearLabel={localeTextMap[locale].clear}
						/>
						<DatePicker
							label={t('DATE_TO')}
							className="w-full"
							value={toDate}
							onChange={e => setToDate(e)}
							format={dateLocaleFormatMap[locale]}
							cancelLabel={localeTextMap[locale].cancel}
							okLabel={localeTextMap[locale].ok}
							clearLabel={localeTextMap[locale].clear}
						/>
					</div>
				)}
			</div>
		</MuiPickersUtilsProvider>
	);
}

export default TableFilterDateIntervalField;
