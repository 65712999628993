import _ from '@lodash';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField, InputAdornment } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { IMaskInput, useIMask } from 'react-imask';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { determineEditableAccess } from '../EditFormUtils';

function EditFormTextField(props) {
	const methods = useFormContext();
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { error } = props.fieldState;
	const { value, onChange } = props.field;
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));
	//const { ref, maskRef } = useIMask(props.fieldConfig.mask);

	const handleOnBlur = () => {
		if (!_.isUndefined(props.fieldConfig.autoCapitalize) && _.isString(value)) {
			let newValue = value;
			if (props.fieldConfig.autoCapitalize === 'upper') {
				newValue = newValue.toUpperCase();
			} else if (props.fieldConfig.autoCapitalize === 'lower') {
				newValue = newValue.toLowerCase();
			} else if (props.fieldConfig.autoCapitalize === 'capitalize') {
				newValue = newValue
					.split(' ')
					.map(s => (s.length > 0 ? s[0].toUpperCase() + s.slice(1) : ''))
					.join(' ');
			}
			if (newValue !== value) {
				onChange(newValue);
			}
		}
	};

	useEffect(() => {
		!_.isUndefined(props.fieldConfig.onChange) ? props.fieldConfig.onChange(value, props.field, methods) : null;
	}, [value]);

	return (
		<TextField
			{...props.field}
			value={!_.isUndefined(value) && value !== null ? value : ''}
			onChange={e => (e.target.value.length > 0 ? onChange(e.target.value) : onChange(null))}
			onKeyUp={e => {
				if (e.keyCode && e.keyCode === 13 && props.fieldConfig.onEnterKey) {
					props.fieldConfig.onEnterKey();
				}
			}}
			className={clsx('mt-8 mb-16', props.fieldConfig.className)}
			type={props.fieldConfig.type ? props.fieldConfig.type : 'text'}
			error={error}
			required={props.fieldConfig.required}
			helperText={error ? error.message : props.fieldConfig.helperText}
			label={!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
			id={props.field.name}
			variant="outlined"
			fullWidth
			multiline={props.fieldConfig.multiline || false}
			autoComplete={props.fieldConfig.autoComplete || 'on'}
			//inputRef={ref}
			InputProps={{
				maxLength: props.fieldConfig.maxLength || null,
				readOnly,
				startAdornment: props.fieldConfig.startAdornment,
				endAdornment: readOnly ? (
					<InputAdornment position="end">
						<Lock fontSize="small" color="disabled" />
					</InputAdornment>
				) : (
					props.fieldConfig.endAdornment
				),
				inputComponent: props.fieldConfig.mask ? IMaskInput : undefined,
				inputProps: props.fieldConfig.mask ? props.fieldConfig.mask : undefined
			}}
			onBlur={() => handleOnBlur()}
		/>
	);
}

export default EditFormTextField;
