import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import CompetitorService from 'app/admin/person/service/CompetitorService';
import EnumEditFormCheckboxesField from 'app/admin/registration/component/EnumEditFormCheckboxesField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import { useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import FuseLoading from '@fuse/core/FuseLoading';
import EditFormCheckboxField from 'modules/ui/editform/fields/EditFormCheckboxField';
import { useTranslation } from 'react-i18next';
import { logoutUser } from 'app/auth/store/userSlice';

const schema = yup.object().shape({
	athleteRegType: yup.string().nullable().required('Mező kötelező.'),
	accept: yup.boolean().oneOf([true], 'Kötelező elfogadni.') /*,
	competitionId: yup
		.number()
		.nullable()
		.when('athleteRegType', {
			is: 'REG_HU',
			then: yup.number().nullable().required('Mező kötelező.')
		})*/
});

function CompetitorsChoice(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const [dialogStatus, setDialogStatus] = useState('form');
	const { t } = useTranslation('reg');
	const methods = useForm({
		mode: 'onChange',
		defaultValues: {
			athleteRegType: null,
			competitionId: null,
			accept: false
		},
		resolver: yupResolver(schema)
	});
	const { control } = methods;

	const athleteRegType = useWatch({
		control,
		name: 'athleteRegType'
	});

	const handleSave = values => {
		setDialogStatus('loading');
		CompetitorService.updateCompetitorToTSE(values.athleteRegType, values.competitionId).then(resp => {
			if (resp != null && resp.successful) {
				setDialogStatus('done');
			} else {
				setDialogStatus('form');
				dispatch(showMessage('Hiba történt a regisztráció módosítása során.'));
			}
		});
	};

	if (user && user.isCompetitor && user.athleteRegType == null) {
		return (
			<FormProvider {...methods}>
				<Dialog open fullWidth>
					<DialogTitle disableTypography className="border-b">
						Regisztráció
					</DialogTitle>
					<DialogContent className="p-20">
						{dialogStatus === 'form' && (
							<>
								<p>Az egyéni versenyzői regisztráció megszűnt. Kérlek nyilatkozz róla, hogy beregisztrálsz e új vesernyzőként, vagy licenszt váltanál ki.</p>
								<div className="w-full flex flex-col md:flex-row">
									<Controller
										control={control}
										name="athleteRegType"
										render={({ field, fieldState }) => (
											<EnumEditFormCheckboxesField
												field={field}
												fieldState={fieldState}
												config={{ topic: 'person' }}
												fieldConfig={{
													key: 'athleteRegType',
													className: 'flex flex-row justify-between',
													items: [
														{ label: 'Regisztráció', value: 'REG_HU' },
														{ label: 'Licensz kiváltás', value: 'LICENSE_REDEEM' }
													]
												}}
											/>
										)}
									/>
								</div>
								<Controller
									control={control}
									name="accept"
									render={({ field, fieldState }) => (
										<EditFormCheckboxField
											field={field}
											fieldState={fieldState}
											config={{ topic: 'person' }}
											fieldConfig={{
												key: 'accept',
												text: t('accept'),
												items: () => CompetitorService.getCompetitionList()
											}}
										/>
									)}
								/>
							</>
						)}
						{dialogStatus === 'loading' && <FuseLoading />}
						{dialogStatus === 'done' && (
							<>
								<p>Regisztrációd módosítása sikeresen megtörtént. Kérlek a kijelentkezés gombra kattintva jelentkezz ki, majd jelentkezz be újra.</p>
								<div className="flex flex-row justify-center py-12">
									<Button
										className="whitespace-nowrap mx-4"
										variant="contained"
										color="secondary"
										onClick={() => {
											dispatch(logoutUser());
										}}
									>
										Kijelentkezés
									</Button>
								</div>
							</>
						)}
					</DialogContent>
					<DialogActions className="justify-between border-t p-20">
						<Button onClick={methods.handleSubmit(handleSave)} disabled={dialogStatus !== 'form'}>
							Beküldés
						</Button>
						<Button
							onClick={() => {
								dispatch(logoutUser());
							}}
							disabled={dialogStatus === 'loading'}
						>
							Kijelentkezés
						</Button>
					</DialogActions>
				</Dialog>
			</FormProvider>
		);
	}
	return <></>;
}

export default CompetitorsChoice;
/*
{
	athleteRegType != null && athleteRegType === 'REG_HU' && (
		<Controller
			control={control}
			name="competitionId"
			render={({ field, fieldState }) => (
				<EditFormSelectField
					field={field}
					fieldState={fieldState}
					config={{ topic: 'person' }}
					fieldConfig={{
						key: 'competitionId',
						text: 'Verseny',
						items: () => CompetitorService.getCompetitionList()
					}}
				/>
			)}
		/>
	);
}
*/
