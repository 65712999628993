import _ from '@lodash';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import React from 'react';
import { fixDependsOn } from '../EditFormUtils';

function EditFormGroupFields(props) {
	const fieldPrefixStr = props.fieldPrefix || '';

	return (
		<div className={props.fieldConfig.className}>
			{props.fieldConfig.fields ? props.fieldConfig.fields.map(fieldRow => <EditFormGroupItem key={fieldRow.key} fieldRow={fieldRow} fieldPrefix={fieldPrefixStr} config={props.config} fieldConfig={props.fieldConfig} />) : null}
		</div>
	);
}

function EditFormGroupItem(props) {
	const { fieldRow, fieldPrefix, config, fieldConfig } = props;
	const methods = useFormContext();
	const { control } = methods;

	const watchFields = useWatch({
		name: fixDependsOn(fieldRow.dependsOn, fieldPrefix),
		control
	});

	if (_.isUndefined(fieldRow.visibleIf) || fieldRow.visibleIf(watchFields, methods)) {
		if (_.isUndefined(fieldRow.fields) && fieldRow.isControlledInput !== false) {
			return (
				<Controller
					key={`${fieldPrefix}${fieldRow.key}`}
					name={`${fieldPrefix}${fieldRow.key}`}
					control={control}
					defaultValue={fieldConfig[fieldRow.key]}
					render={({ field, fieldState }) => <fieldRow.component field={field} fieldState={fieldState} config={config} fieldConfig={fieldRow} fieldPrefix={fieldPrefix} />}
				/>
			);
		}
		return <fieldRow.component key={`${fieldPrefix}${fieldRow.key}`} name={`${fieldPrefix}${fieldRow.key}`} config={config} fieldConfig={fieldRow} fieldPrefix={fieldPrefix} field={props.field} />;
	}
	return null;
}

export default EditFormGroupFields;
