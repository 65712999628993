import nBossConnection from 'modules/base/service/nBossConnection';
import UserDto from 'app/admin/user/dto/UserDto';
import AccessConfigDto from 'app/admin/user/dto/AccessConfigDto';

class AccessManagementService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'accessManagement';
	}

	/**
	 * @param {string} email
	 * @param {string} password
	 * @return {Promise<boolean>}
	 */
	login(email, password) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/login`, {
					email,
					password
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<UserDto>}
	 */
	getLoggedInClient() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getLoggedInClient`)
				.then(response => resolve(new UserDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<AccessConfigDto>}
	 */
	getAccessConfig() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getAccessConfig`)
				.then(response => resolve(new AccessConfigDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<Object>}
	 */
	getServiceRolesMap() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getServiceRolesMap`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new AccessManagementService();
