import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormControl, Select, FormHelperText, InputLabel, MenuItem, ListItemText, Checkbox } from '@material-ui/core';
import { Lock, ArrowDropDown } from '@material-ui/icons';
import clsx from 'clsx';
import { determineEditableAccess } from '../EditFormUtils';

function EditFormSelectField(props) {
	const methods = useFormContext();
	const { control, getValues } = methods;
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { error } = props.fieldState;
	const { value, onChange } = props.field;
	const [items, setItems] = useState(null);
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));
	const valueKey = !_.isUndefined(props.fieldConfig.type) && props.fieldConfig.type === 'enum' ? 'value' : 'id';
	const labelKey = !_.isUndefined(props.fieldConfig.type) && props.fieldConfig.type === 'enum' ? 'label' : 'name';

	const parseFilterKey = () => {
		if (props.fieldConfig.filterKey) {
			if (_.isArray(props.fieldConfig.filterKey)) {
				return props.fieldConfig.filterKey.map(f => (!f.startsWith('^') ? (props.fieldPrefix || '') + f : f.substring(1)));
			}
			return !props.fieldConfig.filterKey.startsWith('^') ? (props.fieldPrefix || '') + props.fieldConfig.filterKey : props.fieldConfig.filterKey.substring(1);
		}
		return null;
	};

	const filterValue = useWatch({
		control,
		name: parseFilterKey(),
		disabled: parseFilterKey() == null
		//defaultValue: null TODO: gondot okoz ha benne van
	});

	const updateItems = () => {
		if (typeof props.fieldConfig.items === 'function') {
			typeof props.fieldConfig.items(props.fieldConfig.itemsParameterIsDto ? getValues() : filterValue).then(result => {
				setItems(result || []);
			});
		} else if (Array.isArray(props.fieldConfig.items)) {
			setItems(props.fieldConfig.items);
		} else {
			setItems([]);
		}
	};

	useEffect(() => {
		updateItems();
	}, [props.fieldConfig, filterValue]);

	const renderValue = selected => {
		if (_.isArray(selected))
			return items
				.filter(d => selected.indexOf(d[valueKey]) > -1)
				.map(d => d[labelKey])
				.join(', ');
		if (!_.isUndefined(selected) && selected !== null && items) {
			const itemIndex = items.findIndex(d => selected === d[valueKey]);
			if (itemIndex > -1) return items[itemIndex][labelKey];
		}
		return '';
	};

	useEffect(() => {
		!_.isUndefined(props.fieldConfig.onChange) ? props.fieldConfig.onChange(value, props.field, methods) : null;
	}, [value]);

	return (
		<FormControl className={clsx('w-full mt-8 mb-16', props.fieldConfig.className)} variant="outlined" error={error} required={props.fieldConfig.required}>
			<InputLabel id={`${props.field.name}-label`}>{!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}</InputLabel>
			{items != null ? (
				<Select
					labelId={`${props.field.name}-label`}
					id={props.field.name}
					multiple={!!props.fieldConfig.multiple}
					label={!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
					value={!!props.fieldConfig.multiple && value === null ? [] : value}
					defaultValue={props.fieldConfig.multiple ? [] : null}
					onChange={e => onChange(e.target.value)}
					inputProps={{ readOnly }}
					renderValue={selected => renderValue(selected)}
					IconComponent={readOnly ? Lock : ArrowDropDown}
				>
					<MenuItem value={null}>&nbsp;</MenuItem>
					{!_.isUndefined(props.fieldConfig.multiple) && props.fieldConfig.multiple
						? items.map((item, i) => (
								<MenuItem key={item[valueKey]} value={item[valueKey]}>
									<Checkbox checked={value && value.indexOf(item[valueKey]) > -1} />
									<ListItemText primary={item[labelKey]} />
								</MenuItem>
						  ))
						: items.map((item, i) => (
								<MenuItem key={item[valueKey]} value={item[valueKey]}>
									{item[labelKey]}
								</MenuItem>
						  ))}
				</Select>
			) : null}
			{error ? <FormHelperText>{error.message}</FormHelperText> : null}
		</FormControl>
	);
}

export default EditFormSelectField;
