import TableCell from '@material-ui/core/TableCell';

function TextTableCell(props) {
	return (
		<TableCell className="p-4 md:p-16" scope="row">
			{props.item.getAsString(props.config.key)}
		</TableCell>
	);
}

export default TextTableCell;
