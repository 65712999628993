import { useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { TableCell, Button, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

function AthleteTransferDescriptionTableButton(props) {
	const { item } = props;
	const [showDialog, setShowDialog] = useState(false);

	if (item.status === 'ATHLETE_REJECTED' || item.status === 'CLUB_REJECTED' || item.status === 'ADMIN_REJECTED') {
		return (
			<TableCell className="w-auto text-center" padding="none">
				<Tooltip title="Indoklás megjelenítése">
					<span>
						<IconButton onClick={e => setShowDialog(true)}>
							<InfoIcon fontSize="small" />
						</IconButton>
					</span>
				</Tooltip>
				{showDialog && (
					<Dialog open fullWidth>
						<DialogTitle disableTypography className="border-b">
							{item.status === 'ADMIN_REJECTED' ? 'Adminisztrátor indoklása' : null}
							{item.status === 'ATHLETE_REJECTED' ? 'Versenyző indoklása' : null}
							{item.status === 'CLUB_REJECTED' ? 'Klub indoklása' : null}
						</DialogTitle>
						<DialogContent className="p-20">
							{item.status === 'ADMIN_REJECTED' ? <div style={{ whiteSpace: 'pre' }}>{item.adminDescription}</div> : null}
							{item.status === 'ATHLETE_REJECTED' ? <div style={{ whiteSpace: 'pre' }}>{item.athleteDescription}</div> : null}
							{item.status === 'CLUB_REJECTED' ? <div style={{ whiteSpace: 'pre' }}>{item.srcClubDescription}</div> : null}
						</DialogContent>
						<DialogActions className="justify-between border-t p-20">
							<Button
								onClick={() => {
									setShowDialog(false);
								}}
							>
								Bezár
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</TableCell>
		);
	}
	return <TableCell className="w-auto text-center" padding="none" />;
}

export default AthleteTransferDescriptionTableButton;
