import NamedDto from 'modules/base/dto/NamedDto';

export class PersonDocumentEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.document = null;
	}

	/**
	 * @typedef {Object} _PersonDocumentEditDtoProps
	 * @property {string} document
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _PersonDocumentEditDtoProps} PersonDocumentEditDtoProps
	 */
	/**
	 *
	 * @param {PersonDocumentEditDtoProps} props
	 * @returns {PersonDocumentEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.document = props.document;
		return this;
	}
}

export default PersonDocumentEditDto;
