import KeyedAndNamedDto from 'modules/base/dto/KeyedAndNamedDto';

export class RoleContentPermissionDto extends KeyedAndNamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.title = null;
		/** @type {number} */
		this.contentType = null;
		/** @type {string} */
		this.grantedLevel = null;
	}

	/**
	 * @typedef {Object} _RoleContentPermissionDtoProps
	 * @property {string} title
	 * @property {number} contentType
	 * @property {string} grantedLevel
	 * @typedef {import("../KeyedAndNamedDto").KeyedAndNamedDtoProps & _RoleContentPermissionDtoProps} RoleContentPermissionDtoProps
	 */
	/**
	 *
	 * @param {RoleContentPermissionDtoProps} props
	 * @returns {RoleContentPermissionDto}
	 */
	setup(props) {
		super.setup(props);
		this.title = props.title;
		this.contentType = props.contentType;
		this.grantedLevel = props.grantedLevel;
		return this;
	}
}

export default RoleContentPermissionDto;
