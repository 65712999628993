import NamedDto from 'modules/base/dto/NamedDto';
import UserStatusType from 'app/admin/common/dto/UserStatusType';

export class OrganizationTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.fullName = null;
		/** @type {string} */
		this.shortName = null;
		/** @type {string} */
		this.cachedAddress = null;
		/** @type {userStatus} */
		this.userStatus = null;
		/** @type {boolean} */
		this.cachedPaymentPaid = null;
		/** @type {boolean} */
		this.cachedLicensePaymentPaid = null;
		/** @type {boolean} */
		this.cachedCurrentLicensePaymentPaid = null;
	}

	/**
	 * @typedef {Object} OrganizationTableDto
	 * @property {string} fullName
	 * @property {string} shortName
	 * @property {string} cachedAddress
	 * @property {string} userStatus
	 * @property {boolean} cachedPaymentPaid
	 * @property {boolean} cachedLicensePaymentPaid
	 * @property {boolean} cachedCurrentLicensePaymentPaid
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & OrganizationTableDto} OrganizationTableDtoProps
	 */
	/**
	 *
	 * @param {OrganizationTableDtoProps} props
	 * @returns {OrganizationTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.fullName = props.fullName;
		this.shortName = props.shortName;
		this.cachedAddress = props.cachedAddress;
		this.userStatus = props.userStatus;
		this.cachedPaymentPaid = props.cachedPaymentPaid;
		this.cachedLicensePaymentPaid = props.cachedLicensePaymentPaid;
		this.cachedCurrentLicensePaymentPaid = props.cachedCurrentLicensePaymentPaid;
		return this;
	}

	/**
	 *
	 * @param {string} key
	 * @returns {string}
	 */
	getAsString(key) {
		if (key === 'userStatus') {
			return this.userStatus != null ? UserStatusType[this.userStatus] : '';
		}
		if (key === 'cachedPaymentPaid' && this.cachedPaymentPaid !== null) {
			if (this.cachedPaymentPaid) return 'Befizetve';
			return 'Nem fizetve';
		}
		if (key === 'cachedLicensePaymentPaid' && this.cachedLicensePaymentPaid !== null) {
			if (this.cachedLicensePaymentPaid) return 'Befizetve';
			return 'Nem fizetve';
		}
		if (key === 'cachedCurrentLicensePaymentPaid' && this.cachedCurrentLicensePaymentPaid !== null) {
			if (this.cachedCurrentLicensePaymentPaid) return 'Befizetve';
			return 'Nem fizetve';
		}
		return super.getAsString(key);
	}
}

export default OrganizationTableDto;
