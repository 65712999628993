import nBossConnection from 'modules/base/service/nBossConnection';
import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import CompetitionEntryEditDto from '../dto/CompetitionEntryEditDto';

class CompetitionEntryEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'competitionEntryEditControl';
	}

	/**
	 * @return {Promise<CompetitionEntryEditDto>}
	 */
	create() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/create`)
				.then(response => resolve(new CompetitionEntryEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<CompetitionEntryEditDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(new CompetitionEntryEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {CompetitionEntryEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, {
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {CompetitionEntryEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Map<String, NamedDto[]}
	 */
	getFormDataByCompetitionId(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getFormDataByCompetitionId`, { id })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} competition
	 * @param {string}  licenseNumber
	 * @param {Date}  birthDate
	 * @return {NamedDto[]}
	 */
	searchAthleteRace(competition, licenseNumber, birthDate) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/searchAthleteRace`, { competition, licenseNumber, birthDate })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new CompetitionEntryEditService();
