import nBossConnection from 'modules/base/service/nBossConnection';

class GroupTransactionService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'groupTransactionControl';
	}

	/**
	 * @param {string} paymentId
	 * @return {Promise<string>}
	 */
	getStatusByBarionId(paymentId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getStatusByBarionId`, { paymentId })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new GroupTransactionService();
