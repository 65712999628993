const locale = {
	nameAsc: 'Név szerint növekvő',
	nameDesc: 'Név szerint csökkenő',
	dateAsc: 'Dátum szerint növekvő',
	dateDesc: 'Dátum szerint csökkenő',
	NO_RESULTS: 'Nincsenek találatok.',
	NO_RESULTS_FILE: 'Nincsenek fájlok.',
	SEARCH_RESULTS: 'Keresési eredmények',
	MAIN_FOLDER: 'Főmappa',
	SELECT_FOLDER: 'Könyvtár kiválasztása',
	SELECT: 'Kiválasztás',
	BACK: 'Vissza'
};

export default locale;
