import { darken } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent } from '@material-ui/core';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import _ from '@lodash';
import { useForm, FormProvider } from 'react-hook-form';
import EditForm from 'modules/ui/editform/EditForm';
import Button from '@material-ui/core/Button';
import EditFormProvider from 'modules/ui/editform/EditFormProvider';
import RegistrationFormToolbar from './RegistrationFormToolbar';
import RegistrationHeader from './RegistrationHeader';
import RegistrationFeedback from './RegistrationFeedback';

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
		color: theme.palette.primary.contrastText
	},
	leftSection: {}
}));

function RegistrationFormPage(props) {
	const id = 'new';
	const classes = useStyles();
	const [tabValue, setTabValue] = useState(0);
	const [tabList, setTabList] = useState(null);
	const [data, setData] = useState(null);
	const [feedback, setFeedback] = useState(false);
	const { t } = useTranslation('reg');

	const methods = useForm({
		mode: 'onChange',
		defaultValues: {}
	});
	const { formState } = methods;

	const handleFormInitialized = result => {};

	const handleFormSuccessful = (result, btnData) => {
		setFeedback(true);
	};

	const handleTabChange = (event, value) => {
		setTabValue(value);
	};
	useEffect(() => {
		if (tabList) {
			if (props.startTabValue) {
				const tabIndex = tabList.findIndex(tab => props.startTabValue === tab.key);
				setTabValue(tabIndex > -1 ? tabIndex : 0);
			} else {
				setTabValue(0);
			}
		}
	}, [tabList]);

	return (
		<FormProvider {...methods}>
			<EditFormProvider>
				<div className={clsx(classes.root, 'flex flex-col flex-auto items-center flex-shrink-0 p-16 md:p-24')}>
					<RegistrationHeader
						title="Adatok megadása"
						subTitle="Kérjük, töltsd ki a regisztrációhoz szükséges összes mezőt"
						rightChildren={
							<>
								<Button component={Link} to="/registration" variant="contained" color="primary" aria-label="BACK">
									{t('back')}
								</Button>
								<Button component={Link} to="/login" variant="contained" color="secondary" aria-label="LOG IN">
									{t('login')}
								</Button>
							</>
						}
					/>
					{feedback && <RegistrationFeedback content="Köszönjük, a regisztrációs folyamat sikeresen lezárul. A belépési adatok megadásával a triatlonadmin kezőoldalán, vagy a Belépés gombra kattintva tudsz bejelentkezni." />}
					{!feedback && (
						<motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }} className="flex min-w-320 max-w-screen-md w-full rounded-20 shadow-2xl overflow-hidden mt-32">
							<Card className={clsx(classes.leftSection, 'flex flex-col w-full items-center justify-center shadow-0')} square>
								<CardContent className="flex flex-col items-center justify-center w-full p-0 max-w-screen-md">
									<div className="w-full py-16 px-16 md:px-36 border-b shadow-lg">
										<Typography variant="h6" className="pt-4 font-semibold text-18 sm:text-24">
											{props.config.text}
										</Typography>
									</div>
									<div className="w-full py-16 md:py-24 px-16 md:px-36">
										<EditForm
											config={props.config}
											id={id}
											onInitialized={result => handleFormInitialized(result)}
											onSuccessful={(result, btnData) => handleFormSuccessful(result, btnData)}
											tabValue={tabValue}
											onTabList={tList => setTabList(tList)}
											onDataChanged={d => setData(d)}
										/>
									</div>
								</CardContent>
								<div className="w-full py-24 px-16 md:px-36 border-t">
									<div className="w-auto flex-grow-0 flex flex-col items-center justify-center">
										<RegistrationFormToolbar config={props.config} id={id} data={data} />
									</div>
								</div>
							</Card>
						</motion.div>
					)}
				</div>
			</EditFormProvider>
		</FormProvider>
	);
}

export default withRouter(RegistrationFormPage);
