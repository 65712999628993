import { Button, Icon } from '@material-ui/core';
import { motion } from 'framer-motion';
import _ from '@lodash';
import { useEditForm } from 'modules/ui/editform/EditFormProvider';
import { useTranslation } from 'react-i18next';

function RegistrationFormToolbar(props) {
	const { setSaveBtnKeyClick } = useEditForm();
	const { t } = useTranslation('reg');

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<motion.div className="flex" initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}>
				{props.config.otherSaveButtonList
					? props.config.otherSaveButtonList.map(btn =>
							btn.place === 'before' ? (
								<Button
									key={btn.key}
									className="whitespace-nowrap mx-4"
									variant="contained"
									color="secondary"
									onClick={() => setSaveBtnKeyClick({ btnKey: btn.key })}
									startIcon={<Icon className="hidden sm:flex">{btn.icon}</Icon>}
								>
									{btn.text}
								</Button>
							) : null
					  )
					: null}
				<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" onClick={() => setSaveBtnKeyClick({ btnKey: 'default' })} startIcon={<Icon className="hidden sm:flex">save</Icon>}>
					{t('registration')}
				</Button>
				{props.config.otherSaveButtonList
					? props.config.otherSaveButtonList.map(btn =>
							btn.place === 'after' ? (
								<Button
									key={btn.key}
									className="whitespace-nowrap mx-4"
									variant="contained"
									color="secondary"
									onClick={() => setSaveBtnKeyClick({ btnKey: btn.key })}
									startIcon={<Icon className="hidden sm:flex">{btn.icon}</Icon>}
								>
									{btn.text}
								</Button>
							) : null
					  )
					: null}
			</motion.div>
		</div>
	);
}

export default RegistrationFormToolbar;
