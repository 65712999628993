import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { useParams, withRouter } from 'react-router';
import EditFormHtmlField from 'modules/ui/editform/fields/EditFormHtmlField';
import EmailTemplateEditService from '../service/EmailTemplateEditService';

const config = data => {
	return {
		key: 'emailTemplateEditForm',
		text: 'Email sablonok',
		textKey: 'emailTemplate',
		service: EmailTemplateEditService,
		fields: [
			{
				key: 'name',
				text: 'Név',
				textKey: 'name',
				component: EditFormTextField,
				required: true,
				readOnly: data.id !== 'new'
			},
			{
				key: 'subject',
				text: 'Tárgy',
				textKey: 'subject',
				component: EditFormTextField,
				required: true
			},
			{
				key: 'html',
				text: 'Tartalom(HTML)',
				textKey: 'html',
				component: EditFormHtmlField
			},
			{
				key: 'text',
				text: 'Tartalom(Szöveges)',
				textKey: 'text',
				multiline: true,
				component: EditFormTextField,
				required: true
			}
		]
	};
};
function EmailTemplateEditForm() {
	const { id } = useParams();
	const myConfig = config({ id });
	return <EditFormPage config={myConfig} />;
}

export default withRouter(EmailTemplateEditForm);
