import _ from '@lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import huLocale from 'date-fns/locale/hu';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputAdornment } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { determineEditableAccess } from '../EditFormUtils';

const localeMap = {
	en: enLocale,
	hu: huLocale
};

const localeFormatMap = {
	en: 'd MMMM yyyy HH:mm',
	hu: 'yyyy MMMM d. HH:mm'
};

const localeTextMap = {
	en: {
		cancel: 'Cancel',
		ok: 'Ok',
		clear: 'Clear'
	},
	hu: {
		cancel: 'Mégse',
		ok: 'Ok',
		clear: 'Törlés'
	}
};

function EditFormDateTimeField(props) {
	const methods = useFormContext();
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const [locale, setLocale] = useState('hu');
	const { error } = props.fieldState;
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));

	useEffect(() => {
		!_.isUndefined(props.fieldConfig.onChange) ? props.fieldConfig.onChange(props.field.value, props.field, methods) : null;
	}, [props.field.value]);

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
			<DateTimePicker
				id={props.field.name}
				{...props.field}
				label={!_.isUndefined(props.fieldConfig.text) ? props.fieldConfig.text : t(`${props.config.topic}:${props.fieldConfig.key}`)}
				format={localeFormatMap[locale]}
				fullWidth
				className={clsx('mt-8 mb-16', props.fieldConfig.className)}
				inputVariant="outlined"
				error={error}
				required={props.fieldConfig.required}
				helperText={error ? error.message : null}
				cancelLabel={localeTextMap[locale].cancel}
				okLabel={localeTextMap[locale].ok}
				clearLabel={localeTextMap[locale].clear}
				ampm={false}
				clearable
				readOnly={readOnly}
				InputProps={{
					endAdornment: readOnly ? (
						<InputAdornment position="end">
							<Lock fontSize="small" color="disabled" />
						</InputAdornment>
					) : null
				}}
			/>
		</MuiPickersUtilsProvider>
	);
}

export default EditFormDateTimeField;
