import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import UserAccountService from './UserAccountService';

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
		color: theme.palette.primary.contrastText
	},
	leftSection: {}
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	email: yup.string().email('Email formátuma nem megfelelő.').required('Email cím megadása kötelező.')
});

const defaultValues = {
	email: ''
};

function PasswordRequest() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [completed, setCompleted] = useState(false);
	const { control, setValue, formState, handleSubmit, reset, trigger } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { isValid, dirtyFields, errors } = formState;

	useEffect(() => {
		setValue('email', '', { shouldDirty: true, shouldValidate: true });
	}, [reset, setValue, trigger]);

	function onSubmit(model) {
		setLoading(true);
		UserAccountService.requestNewPassword(model.email).then(resp => {
			setLoading(false);
			if (resp) {
				setCompleted(true);
			} else {
				dispatch(showMessage({ message: 'A megadott e-mail címre nem lehetséges új jelszót igényelni.' }));
				setValue('email', '', { shouldDirty: true, shouldValidate: true });
			}
		});
	}

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24')}>
			<motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }} className="flex min-w-320 max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden">
				<Card className={clsx(classes.leftSection, 'flex flex-col w-full max-w-sm items-center justify-center shadow-0')} square>
					<CardContent className="flex flex-col items-center justify-center w-full py-24 max-w-320">
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className="flex items-center pb-24">
								<img className="w-full h-auto mx-12" src="assets/images/logos/mtsz-logo-vector-new.svg" alt="Magyar Triatlon Szövetség" />
							</div>
						</motion.div>
						{!completed ? (
							<div className="w-full">
								<div className="pb-24 text-xs">
									<div className="pb-8 font-semibold ">Kérlek add meg az e-mail címed, amivel a rendszerbe vagy regisztrálva.</div>
									<div>A megadott e-mail címre egy linket fogunk küldeni, amire kattintva tudsz majd új jelszót generálni.</div>
								</div>
								<form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
									<Controller
										name="email"
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												className="mb-16"
												type="text"
												error={!!errors.email}
												helperText={errors?.email?.message}
												label="Email"
												// InputProps={{
												// 	endAdornment: (
												// 		<InputAdornment position="end">
												// 			<Icon className="text-20" color="action">
												// 				email
												// 			</Icon>
												// 		</InputAdornment>
												// 	)
												// }}
												InputProps={{
													className: 'pr-4',
													endAdornment: (
														<InputAdornment position="end" className="ml-4">
															<Icon className="text-20 mx-12" color="action">
																email
															</Icon>
														</InputAdornment>
													)
												}}
												variant="outlined"
											/>
										)}
									/>

									<Button type="submit" variant="contained" color="primary" className="w-full mx-auto mt-8" aria-label="LOG IN" disabled={_.isEmpty(dirtyFields) || !isValid || loading} value="legacy">
										Kérem az e-mail-t
									</Button>
								</form>
							</div>
						) : (
							<div className="w-full">A megadott e-mail címre a jelszóbeállításhoz szükséges levelet küldtünk ki. Kérlek ellenőrizd a postafiókod.</div>
						)}
					</CardContent>

					<div className="flex flex-col items-center justify-center pb-24">
						<div>
							<Link className="font-semibold" to="/login">
								Vissza a bejelentkezésre
							</Link>
						</div>
					</div>
				</Card>
			</motion.div>
		</div>
	);
}

export default PasswordRequest;
