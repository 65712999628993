import nBossConnection from 'modules/base/service/nBossConnection';
import { useSelector } from 'react-redux';
import NsrStatusTableCell from 'app/admin/person/component/NsrStatusTableCell';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableFilterListDto from 'modules/base/dto/table/TableFilterListDto';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import UserStatusType from 'app/admin/common/dto/UserStatusType';
import OrganizationTableService from '../service/OrganizationTableService';

const config = data => {
	return {
		key: 'clubTable',
		text: 'Klub',
		textKey: 'club',
		service: OrganizationTableService,
		icon: 'person',
		editFormState: item => `/club/edit/${item ? item.id : 'new'}`,
		exportList: sessionName => `${nBossConnection.basePath}orgTableControl/exportXlsx?session=${sessionName}`,
		defaultFilter: () => {
			return new TableFilterListDto().setup({
				list: [
					{
						propertyName: 'isClub',
						type: 'EQ',
						value: true,
						permanent: true
					}
				],
				activeCount: 1
			});
		},
		toolbarAction: ['filter', 'search', 'refresh', 'export', 'new'],
		filterFields: [
			{
				text: 'Státusz',
				propertyName: 'userStatus',
				propertyType: 'String',
				type: 'IN',
				items: Object.keys(UserStatusType).map(key => {
					return { label: UserStatusType[key], value: key };
				})
			},
			{
				text: 'Fizetve?',
				propertyName: 'cachedCurrentLicensePaymentPaid',
				propertyType: 'Boolean',
				type: 'EQ',
				trueLabel: 'Fizetve',
				falseLabel: 'Nem fizetve'
			}
		],
		rowAction: ['modify', 'delete'],
		columns: [
			{
				key: 'fullName',
				text: 'Teljes név',
				textKey: 'fullName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'shortName',
				text: 'Rövid név',
				textKey: 'shortName',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedAddress',
				text: 'Bejegyzett cím',
				textKey: 'cachedAddress',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			{
				key: 'cachedCurrentLicensePaymentPaid',
				text: 'Fizetve?',
				textKey: 'cachedCurrentLicensePaymentPaid',
				type: 'text',
				sortable: true,
				disablePadding: false
			},
			...(data.isSuperAdmin
				? [
						{
							key: 'nsrSyncStatus',
							text: 'NSR Státusz',
							textKey: 'nsrSyncStatus',
							component: NsrStatusTableCell,
							sortable: true,
							disablePadding: false
						},
						{
							key: 'nsrSyncDate',
							text: 'NSR Dátum',
							textKey: 'nsrSyncDate',
							type: 'dateTime',
							sortable: true,
							disablePadding: false
						}
				  ]
				: [])
		]
	};
};

function ClubTable() {
	const user = useSelector(({ auth }) => auth.user);
	const myConfig = config({ isSuperAdmin: user.isSuperAdmin, accessType: user.accessType });
	return <TableBrowser config={myConfig} />;
}

export default withReducer('clubTable', reducer('clubTable'))(ClubTable);
