import NamedDto from 'modules/base/dto/NamedDto';

export class EntryPriceEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.groupAgeGroup = null;
		/** @type {number} */
		this.preRegAmount1 = null;
		/** @type {number} */
		this.preRegAmount2 = null;
		/** @type {number} */
		this.preRegAmount3 = null;
		/** @type {number} */
		this.entryAmount = null;
		/** @type {number} */
		this.localAmount = null;
	}

	/**
	 * @typedef {Object} _EntryPriceEditDtoProps
	 * @property {number} groupAgeGroup
	 * @property {number} preRegAmount1
	 * @property {number} preRegAmount2
	 * @property {number} preRegAmount3
	 * @property {number} entryAmount
	 * @property {number} localAmount
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _EntryPriceEditDtoProps} EntryPriceEditDtoProps
	 */
	/**
	 *
	 * @param {EntryPriceEditDtoProps} props
	 * @returns {EntryPriceEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.groupAgeGroup = props.groupAgeGroup;
		this.preRegAmount1 = props.preRegAmount1;
		this.preRegAmount2 = props.preRegAmount2;
		this.preRegAmount3 = props.preRegAmount3;
		this.entryAmount = props.entryAmount;
		this.localAmount = props.localAmount;
		return this;
	}
}

export default EntryPriceEditDto;
