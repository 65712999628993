import TableFilterDto from './TableFilterDto';

export class TableFilterListDto {
	constructor() {
		/** @type {Array<TableFilterDto>} */
		this.list = null;
		/** @type {number} */
		this.activeCount = null;
	}

	/**
	 *
	 * @typedef {Object} _TableFilterListDtoProps
	 * @property {Array<TableFilterDto>} list
	 * @property {number} activeCount
	 * @typedef {_TableFilterListDtoProps} TableFilterListDtoProps
	 */
	/**
	 *
	 * @param {TableFilterListDtoProps} props
	 * @returns {TableFilterListDto}
	 */
	setup(props) {
		this.list = props.list != null ? props.list.map(d => new TableFilterDto().setup(d)) : null;
		this.activeCount = props.activeCount;
		return this;
	}
}

export default TableFilterListDto;
