export class EnumDto {
	constructor() {
		/** @type {string} */
		this.label = null;
		/** @type {string} */
		this.value = null;
	}

	/**
	 *
	 * @typedef {Object} _EnumDtoProps
	 * @property {string} label
	 * @property {string} value
	 * @typedef {_EnumDtoProps} EnumDtoProps
	 */
	/**
	 *
	 * @param {EnumDtoProps} props
	 * @returns {EnumDto}
	 */
	setup(props) {
		this.label = props.label;
		this.value = props.value;
		return this;
	}
}

export default EnumDto;
