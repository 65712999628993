export const startsWithVowel = s => {
	const vowels = ['a', 'á', 'e', 'é', 'i', 'í', 'o', 'ó', 'ö', 'ő', 'u', 'ú', 'ü', 'ű'];
	return vowels.some(v => s.toLowerCase().startsWith(v));
};

export const getVowelAdjective = s => {
	return s ? (startsWithVowel(s) ? 'az' : 'a') : 'a(z)';
};

export const getSessionString = () => {
	return Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, '')
		.substr(0, 16);
};

export const getRandomString = () => {
	return Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, '')
		.substr(0, 16);
};

export const generateKey = length => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
};
