import _ from 'lodash';
import NamedDto from 'modules/base/dto/NamedDto';

export default class OrderableNamedDto extends NamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.orderIndex = null;
	}

	/**
	 * @typedef {Object} _OrderableNamedDtoProps
	 * @property {number} orderIndex
	 *
	 * @typedef {import("modules/base/dto//NamedDto").NamedDtoProps & _OrderableNamedDtoProps} OrderableNamedDtoProps
	 */
	/**
	 *
	 * @param {OrderableNamedDtoProps} props
	 * @returns {OrderableNamedDto}
	 */
	setup(props) {
		super.setup(props);
		this.orderIndex = props.orderIndex;
		return this;
	}
}
