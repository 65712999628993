import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import ContestTableService from '../service/ContestTableService';

const config = {
	key: 'contestTable',
	text: 'Kiírt események',
	service: ContestTableService,
	editFormState: item => `/contest/edit/${item ? item.id : 'new'}`,
	toolbarAction: ['search', 'refresh', 'new'],
	rowAction: ['modify', 'delete'],
	columns: [
		{
			key: 'name',
			text: 'Név',
			textKey: 'name',
			type: 'text',
			sortable: true,
			disablePadding: false
		}
	]
};

function ContestTable() {
	return <TableBrowser config={config} />;
}

export default withReducer('contestTable', reducer('contestTable'))(ContestTable);
