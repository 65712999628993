import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class OrganizerEditDto extends IdentifiableDto {
	/*	constructor() {
		super();
	}
*/
	/**
	 * @typedef {Object} _OrganizerEditDtoProps
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _OrganizerEditDtoProps} OrganizerEditDtoProps
	 */
	/**
	 *
	 * @param {OrganizerEditDtoProps} props
	 * @returns {OrganizerEditDto}
	 */
	setup(props) {
		super.setup(props);
		return this;
	}
}

export default OrganizerEditDto;
