import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import TableBrowserHead from 'modules/ui/tablebrowser/components/TableBrowserHead';
import TableCellRepo from 'modules/ui/tablebrowser/components/cells/TableCellRepo';
import TableModifyButton from 'modules/ui/tablebrowser/components/buttons/TableModifyButton';
import TableDeleteButton from 'modules/ui/tablebrowser/components/buttons/TableDeleteButton';
import TableSubPageButton from 'modules/ui/tablebrowser/components/buttons/TableSubPageButton';
import TableFileOpenButton from 'modules/ui/tablebrowser/components/buttons/TableFileOpenButton';
import TableExportDataButton from 'modules/ui/tablebrowser/components/buttons/TableExportDataButton';
import TableCustomButton from 'modules/ui/tablebrowser/components/buttons/TableCustomButton';
import TableBrowserFiltersDialog from 'modules/ui/tablebrowser/components/filters/TableBrowserFiltersDialog';
import TableBrowserHelper from 'modules/ui/tablebrowser/components/TableBrowserHelper';
import { getSessionString } from 'modules/base/utils/GenericUtils';
import TableBrowserComponentHeader from './TableBrowserComponentHeader';

const useStyles = makeStyles(theme => ({
	tableBrowser: {
		'& a[role=row]': {
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'none'
			}
		},
		'& a[target=_blank]': {
			background: 'transparent',
			color: theme.palette.type === 'light' ? fade('#000', 0.54) : fade('#fff'),
			textDecoration: 'none',
			borderBottom: 0,
			'&:hover': {
				background: theme.palette.type === 'light' ? fade('#000', 0.04) : fade('#fff', 0.08),
				textDecoration: 'none'
			}
		},
		'& a[target=_blank][aria-disabled=true]': {
			color: theme.palette.type === 'light' ? fade('#000', 0.26) : fade('#fff'),
			background: 'transparent'
		}
	}
}));

function TableBrowserComponent(props) {
	const { t } = useTranslation('tableBrowser');
	const classes = useStyles(props);
	const dispatch = useDispatch();
	const visibleFiltersDialog = useSelector(rootState => rootState[props.config.key].tableBrowserEvent.visibleFiltersDialog);
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [order, setOrder] = useState({
		ascending: true,
		propertyName: null
	});
	const [deleteItem, setDeleteItem] = useState(null);
	const sessionName = useSelector(rootState => rootState[props.config.key].tableBrowserSession.sessionName);
	const [tableSessionNameReady, setTableSessionNameReady] = useState(false);

	const initialize = () => {
		if (sessionName == null) {
			const _sessionName = getSessionString();
			dispatch({ type: `${props.config.key}/session/setSessionName`, payload: _sessionName });
		} else {
			setTableSessionNameReady(true);
		}
		return () => {
			dispatch({ type: `${props.config.key}/event/cleanTableBrowserEventSlice` });
		};
	};

	useEffect(() => {
		return initialize();
	}, []);

	useEffect(() => {
		if (sessionName != null) {
			setTableSessionNameReady(true);
		}
	}, [sessionName]);

	function handleRequestSort(event, property) {
		const propertyName = property;
		let ascending = true;
		if (order.propertyName === propertyName && order.ascending) {
			ascending = false;
		}
		setOrder({
			ascending,
			propertyName
		});
	}

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event.target.value);
	}

	function getSpecificTableCell(config, item) {
		const SpecificTableCell = config.component ? config.component : TableCellRepo[config.type];
		return <SpecificTableCell key={config.key} item={item} config={config} />;
	}

	function handleDelete(item) {
		setDeleteItem(item);
	}
	if (!tableSessionNameReady) {
		return (
			<div className="w-full flex">
				<FuseLoading />
			</div>
		);
	}

	const handleData = d => {
		if (d !== null && d.data !== null && !_.isUndefined(props.config.rowDataModifier)) {
			d.data = d.data.map(item => props.config.rowDataModifier(item));
		}
		setData(d);
	};

	return (
		<div className="w-full flex">
			<TableBrowserHelper
				config={props.config}
				onLoading={l => setLoading(l)}
				onData={d => handleData(d)}
				order={order}
				onOrder={o => setOrder(o)}
				rowsPerPage={rowsPerPage}
				onRowsPerPage={r => setRowsPerPage(r)}
				page={page}
				onPage={p => setPage(p)}
				deleteItem={deleteItem}
			/>

			{loading ? <FuseLoading /> : null}
			{!loading && data && data.metadata.totalCount === 0 ? (
				<div className="w-full">
					{props.config.withToolbar ? <TableBrowserComponentHeader config={props.config} /> : null}
					<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.1 } }} className="flex flex-1 items-center justify-center h-full">
						<Typography color="textSecondary" variant="h5">
							{t('NO_DATA')}
						</Typography>
						{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('filter') > -1 ? (
							<TableBrowserFiltersDialog open={visibleFiltersDialog} onClose={() => dispatch({ type: `${props.config.key}/event/hideFiltersDialog` })} config={props.config} filterFields={props.config.filterFields} />
						) : null}
					</motion.div>
				</div>
			) : null}

			{!loading && data && data.metadata.totalCount > 0 ? (
				<div className="w-full flex flex-col">
					{props.config.withToolbar ? <TableBrowserComponentHeader config={props.config} /> : null}
					<FuseScrollbars className="flex-grow overflow-x-auto">
						<Table stickyHeader className={clsx(classes.tableBrowser, 'min-w-xl')} aria-labelledby="tableTitle">
							<TableBrowserHead order={order} onRequestSort={handleRequestSort} rowCount={data.length} config={props.config} />
							<TableBody>
								{data.data.map(item => {
									return (
										<TableRow
											className="h-72 cursor-pointer"
											hover
											tabIndex={-1}
											key={item.id}
											onClick={() => (!_.isUndefined(props.config.rowClick) ? dispatch({ type: `${props.config.key}/event/cleanTableBrowserEventSlice` }) : null)}
											component={_.isUndefined(props.config.rowClick) ? 'tr' : _.isUndefined(props.config.rowClickTarget) ? Link : props.config.rowClickTarget(item) === '_blank' ? 'a' : Link}
											target={_.isUndefined(props.config.rowClickTarget) ? '_self' : props.config.rowClickTarget(item)}
											to={_.isUndefined(props.config.rowClick) ? null : props.config.rowClick(item)}
											href={_.isUndefined(props.config.rowClick) ? null : props.config.rowClick(item)}
										>
											{props.config.rowButtonList
												? props.config.rowButtonList.map(btn =>
														_.isUndefined(btn.component) ? (
															<TableCustomButton key={btn.key} config={props.config} item={item} button={btn} />
														) : (
															<btn.component key={btn.key} config={props.config} item={item} button={btn} />
														)
												  )
												: null}
											{props.config.rowAction != null && props.config.rowAction.indexOf('fileOpen') > -1 ? <TableFileOpenButton config={props.config} item={item} /> : null}
											{props.config.rowAction != null && props.config.rowAction.indexOf('exportData') > -1 ? <TableExportDataButton config={props.config} item={item} /> : null}
											{props.config.rowAction != null && props.config.rowAction.indexOf('subPage') > -1 ? <TableSubPageButton config={props.config} item={item} /> : null}
											{props.config.rowAction != null && props.config.rowAction.indexOf('modify') > -1 ? <TableModifyButton config={props.config} item={item} /> : null}
											{props.config.rowAction != null && props.config.rowAction.indexOf('delete') > -1 ? <TableDeleteButton config={props.config} item={item} onDelete={() => handleDelete(item)} /> : null}
											{props.config.columns.map(config => {
												return getSpecificTableCell(config, item);
											})}
											{props.config.rowEndButtonList
												? props.config.rowEndButtonList.map(btn =>
														_.isUndefined(btn.component) ? (
															<TableCustomButton key={btn.key} config={props.config} item={item} button={btn} />
														) : (
															<btn.component key={btn.key} config={props.config} item={item} button={btn} />
														)
												  )
												: null}
											{props.config.rowEndAction != null && props.config.rowEndAction.indexOf('fileOpen') > -1 ? <TableFileOpenButton config={props.config} item={item} /> : null}
											{props.config.rowEndAction != null && props.config.rowEndAction.indexOf('exportData') > -1 ? <TableExportDataButton config={props.config} item={item} /> : null}
											{props.config.rowEndAction != null && props.config.rowEndAction.indexOf('subPage') > -1 ? <TableSubPageButton config={props.config} item={item} /> : null}
											{props.config.rowEndAction != null && props.config.rowEndAction.indexOf('modify') > -1 ? <TableModifyButton config={props.config} item={item} /> : null}
											{props.config.rowEndAction != null && props.config.rowEndAction.indexOf('delete') > -1 ? <TableDeleteButton config={props.config} item={item} onDelete={() => handleDelete(item)} /> : null}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</FuseScrollbars>

					<TablePagination
						className="flex-shrink-0 border-t-1"
						component="div"
						count={data.metadata.totalCount}
						rowsPerPage={rowsPerPage}
						page={page}
						labelRowsPerPage={t('ROWS_PER_PAGE')}
						backIconButtonProps={{
							title: t('PREV_PAGE'),
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							title: t('NEXT_PAGE'),
							'aria-label': 'Next Page'
						}}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
					{props.config.toolbarAction != null && props.config.toolbarAction.indexOf('filter') > -1 ? (
						<TableBrowserFiltersDialog open={visibleFiltersDialog} onClose={() => dispatch({ type: `${props.config.key}/event/hideFiltersDialog` })} config={props.config} filterFields={props.config.filterFields} />
					) : null}
				</div>
			) : null}
		</div>
	);
}

export default TableBrowserComponent;
