import { Link } from 'react-router-dom';

function AthleteTransferWaitForAthleteNotificaion(props) {
	return (
		<div className="pt-4 pl-32">
			<Link to={`/athlete-answer/${props.item.data.id}`}> Megnyit</Link>
		</div>
	);
}

export default AthleteTransferWaitForAthleteNotificaion;
