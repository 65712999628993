import KeyedAndNamedDto from 'modules/base/dto/KeyedAndNamedDto';

export class DashboardRoleTableDto extends KeyedAndNamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.title = null;
		/** @type {string} */
		this.adminDescription = null;
		/** @type {boolean} */
		this.isEnabled = null;
		/** @type {string} */
		this.protectionLevel = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Date} */
		this.updated = null;
	}

	/**
	 * @typedef {Object} _DashboardRoleTableDtoProps
	 * @property {string} title
	 * @property {string} adminDescription
	 * @property {boolean} isEnabled
	 * @property {string} protectionLevel
	 * @property {Date} created
	 * @property {Date} updated
	 * @typedef {import("../KeyedAndNamedDto").KeyedAndNamedDtoProps & _DashboardRoleTableDtoProps} DashboardRoleTableDtoProps
	 */
	/**
	 *
	 * @param {DashboardRoleTableDtoProps} props
	 * @returns {DashboardRoleTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.title = props.title;
		this.adminDescription = props.adminDescription;
		this.isEnabled = props.isEnabled;
		this.protectionLevel = props.protectionLevel;
		this.created = props.created != null ? new Date(props.created) : null;
		this.updated = props.updated != null ? new Date(props.updated) : null;
		return this;
	}
}

export default DashboardRoleTableDto;
