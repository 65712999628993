import nBossConnection from 'modules/base/service/nBossConnection';
import { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { motion } from 'framer-motion';
import { TbTableImport } from 'react-icons/tb';

function TableHeaderImportButton(props) {
	const dispatch = useDispatch();
	const { t } = useTranslation(props.config.topic ? ['tableBrowser', props.config.topic] : 'tableBrowser');
	const [selectedFile, setSelectedFile] = useState(null);
	const [isUploading, setIsUploading] = useState(false);

	const handleFileChange = e => {
		setSelectedFile(e.target.files[0]);
	};

	useEffect(() => {
		if (selectedFile != null) {
			const formData = new FormData();
			formData.append('file', selectedFile, selectedFile.name);
			setIsUploading(true);

			nBossConnection.connection
				.post(props.config.importUrl, formData)
				.then(response => {
					setIsUploading(false);
					setSelectedFile(null);
					dispatch(showMessage({ message: t('IMPORT_DONE') }));
					dispatch({ type: `${props.config.key}/event/setNeedDataReload` });
				})
				.catch(e => {
					setIsUploading(false);
					setSelectedFile(null);
					dispatch(showMessage({ message: t('IMPORT_FILE_UPLOADING_ERROR') }));
				});
		}
	}, [selectedFile]);

	return (
		<motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}>
			<Tooltip title={t('IMPORT')}>
				<IconButton aria-label="import" disabled={isUploading}>
					<TbTableImport fontSize="medium" />
					{!isUploading ? <input type="file" onChange={e => handleFileChange(e)} className="absolute inset-0 w-full h-full" style={{ opacity: 0.0001 }} disabled={isUploading} /> : null}
				</IconButton>
			</Tooltip>
		</motion.div>
	);
}

export default TableHeaderImportButton;
