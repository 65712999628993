import { Link } from 'react-router-dom';

function AthleteTransferWaitForAdminNotification(props) {
	return (
		<div className="pt-4 pl-32">
			<Link to="/athlete-transfer"> Megnyit</Link>
		</div>
	);
}

export default AthleteTransferWaitForAdminNotification;
