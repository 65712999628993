import { Tooltip, IconButton, TableCell } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';

function TableModifyButton(props) {
	const { t } = useTranslation('tableBrowser');
	return (
		<TableCell className="text-center" padding="none">
			<Tooltip title={t('MODIFY')}>
				<span>
					<IconButton component={Link} to={() => props.config.editFormState(props.item)} aria-label="modify" disabled={props.item && props.item.protectionLevel && props.item.protectionLevel === 'CannotBeModified'}>
						<SettingsIcon fontSize="small" />
					</IconButton>
				</span>
			</Tooltip>
		</TableCell>
	);
}

export default TableModifyButton;
