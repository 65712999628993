export class NewPasswordDto {
	constructor() {
		/** @type {string} */
		this.password = null;
		/** @type {string} */
		this.passwordAgain = null;
	}

	/**
	 *
	 * @typedef {Object} _NewPasswordDtoProps
	 * @property {string} password
	 * @property {string} passwordAgain
	 * @typedef {_NewPasswordDtoProps} NewPasswordDtoProps
	 */
	/**
	 *
	 * @param {IdentifiableDtoProps} props
	 * @returns {IdentifiableDto}
	 */
	setup(props) {
		if (props === null) return null;
		this.password = props.password;
		this.passwordAgain = props.passwordAgain;
		return this;
	}
}

export default NewPasswordDto;
