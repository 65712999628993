import NamedDto from 'modules/base/dto/NamedDto';

export class LicenseCostTableDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.costType = null;
		/** @type {boolean} */
		this.autoAllocationEnabled = null;
		/** @type {Date} */
		this.nextAllocationDate = null;
		/** @type {Date} */
		this.lastAllocationDate = null;
		/** @type {number} */
		this.feeNet = null;
		/** @type {number} */
		this.feeVat = null;
		/** @type {number} */
		this.feeGross = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Date} */
		this.updated = null;
	}

	/**
	 * @typedef {Object} LicenseCostTableDto
	 * @property {string} costType
	 * @property {boolean} autoAllocationEnabled
	 * @property {Date} nextAllocationDate
	 * @property {Date} lastAllocationDate
	 * @property {number} feeNet
	 * @property {number} feeVat
	 * @property {number} feeGross
	 * @property {Date} created
	 * @property {Date} updated
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & LicenseCostTableDto} LicenseCostTableDtoProps
	 */
	/**
	 *
	 * @param {LicenseCostTableDtoProps} props
	 * @returns {LicenseCostTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.costType = props.costType;
		this.autoAllocationEnabled = props.autoAllocationEnabled;
		this.nextAllocationDate = props.nextAllocationDate != null ? new Date(props.nextAllocationDate) : null;
		this.lastAllocationDate = props.lastAllocationDate != null ? new Date(props.lastAllocationDate) : null;
		this.feeNet = props.feeNet;
		this.feeVat = props.feeVat;
		this.feeGross = props.feeGross;
		this.created = props.created != null ? new Date(props.created) : null;
		this.updated = props.updated != null ? new Date(props.updated) : null;
		return this;
	}
}

export default LicenseCostTableDto;
