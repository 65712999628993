import NamedDto from 'modules/base/dto/NamedDto';

export class RaceEditDto extends NamedDto {
	constructor() {
		super();
		/** @type {string[]} */
		this.competitionPartKeyList = null;
		/** @type {Date} */
		this.date = null;
		/** @type {number} */
		this.startTime = null;
		/** @type {number} */
		this.depotIn = null;
		/** @type {number} */
		this.depotOut = null;
		/** @type {number[]} */
		this.ageGroupList = null;
		/** @type {string[]} */
		this.licenseTypeList = null;
		/** @type {boolean} */
		this.isMale = null;
		/** @type {boolean} */
		this.isFemale = null;
		/** @type {number[]} */
		this.teamScoreCalcList = null;
	}

	/**
	 * @typedef {Object} _RaceEditDtoProps
	 * @property {string[]} competitionPartKeyList
	 * @property {Date} date
	 * @property {number} startTime
	 * @property {number} depotIn
	 * @property {number} depotOut
	 * @property {number[]} ageGroupList
	 * @property {string[]} licenseTypeList
	 * @property {boolean} isMale
	 * @property {boolean} isFemale
	 * @property {number[]} teamScoreCalcList
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _RaceEditDtoProps} RaceEditDtoProps
	 */
	/**
	 *
	 * @param {RaceEditDtoProps} props
	 * @returns {RaceEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.competitionPartKeyList = props.competitionPartKeyList != null ? props.competitionPartKeyList : [];
		this.date = props.date != null ? new Date(props.date) : null;
		this.startTime = props.startTime;
		this.depotIn = props.depotIn;
		this.depotOut = props.depotOut;
		this.ageGroupList = props.ageGroupList != null ? props.ageGroupList : [];
		this.licenseTypeList = props.licenseTypeList != null ? props.licenseTypeList : [];
		this.isMale = props.isMale != null ? props.isMale : false;
		this.isFemale = props.isFemale != null ? props.isFemale : false;
		this.teamScoreCalcList = props.teamScoreCalcList != null ? props.teamScoreCalcList : [];
		return this;
	}
}

export default RaceEditDto;
