class TableOrderDto {
	constructor() {
		/** @type {string} */
		this.propertyName = null;
		/** @type {boolean} */
		this.ascending = null;
	}

	/**
	 *
	 * @typedef {Object} _TableOrderDtoProps
	 * @property {string} propertyName
	 * @property {boolean} ascending
	 * @typedef {_TableOrderDtoProps} TableOrderDtoProps
	 */
	/**
	 *
	 * @param {TableOrderDtoProps} props
	 * @returns {TableOrderDto}
	 */
	setup(props) {
		this.propertyName = props.propertyName;
		this.ascending = props.ascending;
		return this;
	}
}

export default TableOrderDto;
