const locale = {
	nameAsc: 'Ascending by name',
	nameDesc: 'Descending by name',
	dateAsc: 'Ascending by date',
	dateDesc: 'Descending by date',
	NO_RESULTS: 'No results.',
	NO_RESULTS_FILE: 'No results.',
	SEARCH_RESULTS: 'Search results',
	MAIN_FOLDER: 'Main Folder',
	SELECT_FOLDER: 'Select Folder',
	SELECT: 'Select',
	BACK: 'Back'
};

export default locale;
