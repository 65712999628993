import OrderableNamedParentTableDto from 'app/admin/valueset/dto/OrderableNamedParentTableDto';

class AgeGroupTableDto extends OrderableNamedParentTableDto {
	constructor() {
		super();
		/** @type {number} */
		this.ageMin = null;
		/** @type {number} */
		this.ageMax = null;
		/** @type {boolean} */
		this.child = null;
	}

	setup(props) {
		super.setup(props);
		this.ageMin = props.ageMin;
		this.ageMax = props.ageMax;
		this.child = props.child;
		return this;
	}
}

export default AgeGroupTableDto;
