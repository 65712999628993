import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';
import CompetitionEditDto from 'app/admin/competition/dto/CompetitionEditDto';
import nBossConnection from 'modules/base/service/nBossConnection';
import RefereeQueryDto from '../dto/RefereeQueryDto';
import PersonQueryDto from '../dto/PersonQueryDto';

class CompetitionEditService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'competitionEditControl';
	}

	/**
	 * @return {Promise<CompetitionEditDto>}
	 */
	create() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/create`)
				.then(response => resolve(new CompetitionEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Promise<CompetitionEditDto>}
	 */
	getData(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getData`, {
					id
				})
				.then(response => resolve(new CompetitionEditDto().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {CompetitionEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, {
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {CompetitionEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, {
					id,
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {CompetitionEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	saveAndSubmit(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/saveAndSubmit`, {
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {CompetitionEditDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	updateAndSubmit(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/updateAndSubmit`, {
					id,
					dto
				})
				.then(response => resolve(new EntityValidationResult().setup(response.data)))
				.catch(reject);
		});
	}

	/**
	 * @return {Map<String, NamedDto[]}
	 */
	getFormData() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getFormData`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @return {Map<String, NamedDto[]}
	 */
	getFormDataById(id) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getFormData`, { id })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @param {query} query
	 * @param {number} limit
	 * @return {Map<String, RefereeQueryDto[]}
	 */
	getRefereeSelectionList(query, limit) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getRefereeSelectionList`, { query, limit })
				.then(response => resolve(response.data !== null ? response.data.map(d => new RefereeQueryDto().setup(d.person)) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {query} query
	 * @param {number} limit
	 * @return {Map<String, PersonQueryDto[]}
	 */
	getTechnicalCommitteeSelectionList(query, limit) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getTechnicalCommitteeSelectionList`, { query, limit })
				.then(response => resolve(response.data !== null ? response.data.map(d => new PersonQueryDto().setup(d)) : null))
				.catch(reject);
		});
	}
}

export default new CompetitionEditService();
