const AthleteTransferStatusType = {
	STARTED: 'Elindítva',
	WAIT_FOR_ATHLETE: 'Versenyzőre vár',
	ATHLETE_ACCEPTED: 'Versenyző elfogadta',
	ATHLETE_REJECTED: 'Versenyző elutasította',
	WAIT_FOR_ADMIN: 'Adminisztrátorra vár',
	ADMIN_ACCEPTED: 'Adminisztrátor jóváhagyta',
	ADMIN_REJECTED: 'Adminisztrátor elutasította',
	WAIT_FOR_CLUB: 'Klubra vár',
	CLUB_ACCEPTED: 'Klub elfogadta',
	CLUB_REJECTED: 'Klub elutasította',
	AWAITING_PAYMENT: 'Fizetésre vár',
	UNPAID: 'Fizetetlen',
	FINISHED: 'Lezárva'
};
export default AthleteTransferStatusType;
