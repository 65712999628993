import { Button, Icon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import _ from '@lodash';
import { useEditForm } from 'modules/ui/editform/EditFormProvider';

function EditFormHeader(props) {
	const { setSaveBtnKeyClick } = useEditForm();
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const methods = useFormContext();
	const { watch } = methods;
	const name = watch(_.isUndefined(props.config.watchTitleKey) ? 'name' : props.config.watchTitleKey);
	const theme = useTheme();

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex flex-col items-start max-w-full min-w-0">
				<EditFormHeaderBackLink config={props.config} theme={theme} />
				<div className="flex items-center max-w-full">
					<div className="flex flex-col min-w-0 mx-8 sm:mc-16">
						<motion.div initial={{ x: -20 }} animate={{ x: 0, transition: { delay: 0.3 } }}>
							<Typography className="text-16 sm:text-20 truncate font-semibold">
								{_.isFunction(props.config.title) ? props.config.title(props.data) : name || `${t('NEW')} ${!_.isUndefined(props.config.text) ? props.config.text : t(`${props.config.topic}:${props.config.key}`)}`}
							</Typography>
							<Typography variant="caption" className="font-medium">
								{_.isFunction(props.config.description)
									? props.data
										? props.config.description(props.data)
										: ''
									: `${!_.isUndefined(props.config.text) ? props.config.text : t(`${props.config.topic}:${props.config.key}`)} ${t('DETAILS')}`}
							</Typography>
						</motion.div>
					</div>
				</div>
			</div>
			{props.centerContent ? props.centerContent : null}
			<motion.div className="flex" initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}>
				{props.beforeSaveContent}
				<EditFormHeaderOtherSaveButtonList config={props.config} data={props.data} place="before" onSaveBtnKeyClick={d => setSaveBtnKeyClick(d)} />
				<EditFormSaveButton config={props.config} onClick={() => setSaveBtnKeyClick({ btnKey: 'default' })} />
				<EditFormHeaderOtherSaveButtonList config={props.config} data={props.data} place="after" onSaveBtnKeyClick={d => setSaveBtnKeyClick(d)} />
			</motion.div>
		</div>
	);
}

function EditFormSaveButton(props) {
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const { getValues } = useFormContext();
	let hideSaveButton = false;
	if (_.isBoolean(props.config.hideSaveButton) && props.config.hideSaveButton) hideSaveButton = true;
	else if (_.isFunction(props.config.hideSaveButton)) hideSaveButton = props.config.hideSaveButton(getValues());
	if (!hideSaveButton)
		return (
			<Button className="whitespace-nowrap mx-4" variant="contained" color="secondary" onClick={() => props.onClick()} startIcon={<Icon className="hidden sm:flex">save</Icon>}>
				{t('SAVE')}
			</Button>
		);
	return null;
}

function EditFormHeaderBackLink(props) {
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const history = useHistory();
	if (!(_.isBoolean(props.config.hideBackLink) && props.config.hideBackLink))
		return (
			<motion.div initial={{ x: 20, opacity: 0 }} animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}>
				{_.isFunction(props.config.backLink) ? (
					<Typography className="flex items-center sm:mb-12" component={Link} role="button" to={() => props.config.backLink()} color="inherit">
						<Icon className="text-20">{props.theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}</Icon>
						<span className="hidden sm:flex mx-4 font-medium">{t('BACK')}</span>
					</Typography>
				) : (
					<Typography className="flex items-center sm:mb-12" component={Button} role="button" onClick={() => history.goBack(history)} color="inherit">
						<Icon className="text-20">{props.theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}</Icon>
						<span className="hidden sm:flex mx-4 font-medium">{t('BACK')}</span>
					</Typography>
				)}
			</motion.div>
		);
	return null;
}

function EditFormHeaderOtherSaveButtonList(props) {
	if (props.config.otherSaveButtonList) {
		return props.config.otherSaveButtonList.map(btn =>
			btn.place === props.place && (!_.isFunction(btn.visibleIf) || (props.data && btn.visibleIf(props.data))) ? (
				<EditFormHeaderOtherSaveButton key={btn.key} config={props.config} btn={btn} onSaveBtnKeyClick={d => props.onSaveBtnKeyClick(d)} />
			) : null
		);
	}
	return null;
}

function EditFormHeaderOtherSaveButton(props) {
	const { btn } = props;
	const history = useHistory();
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	return (
		<Button
			key={btn.key}
			className="whitespace-nowrap mx-4"
			variant="contained"
			color={btn.color ? btn.color : 'secondary'}
			onClick={() => (_.isFunction(btn.action) ? btn.action() : _.isBoolean(btn.ignoreSaveMethod) && btn.ignoreSaveMethod === true ? history.push(btn.link(props.data)) : props.onSaveBtnKeyClick({ btnKey: btn.key }))}
			startIcon={<Icon className="hidden sm:flex">{btn.icon}</Icon>}
		>
			{`${!_.isUndefined(btn.text) ? btn.text : t(`${props.config.topic}:${btn.key}`)}`}
		</Button>
	);
}

export default EditFormHeader;
