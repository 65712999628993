import TableCell from '@material-ui/core/TableCell';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';

function DateTimeTableCell(props) {
	const [label, setLabel] = useState(null);

	useEffect(() => {
		if (props.item[props.config.key] != null) {
			setLabel(format(props.item[props.config.key], 'yyyy.MM.dd. HH:mm'));
		} else {
			setLabel('-');
		}
	}, [props.item, props.config]);

	return (
		<TableCell className="p-4 md:p-16" scope="row">
			{label}
		</TableCell>
	);
}

export default DateTimeTableCell;
