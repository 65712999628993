import _ from '@lodash';
import { useState, useEffect, useRef } from 'react';
import { Checkbox, TableCell } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

function TableBulkSelectionCheckbox(props) {
	const dispatch = useDispatch();
	const [checked, setChecked] = useState(false);
	const sessionName = useSelector(rootState => rootState[props.config.key].tableBrowserSession.sessionName);
	const allSelected = useSelector(rootState => rootState[props.config.key].tableBrowserData.allSelected);
	const selected = useSelector(rootState => rootState[props.config.key].tableBrowserData.selected);
	const bulkIsCheckable = _.isFunction(props.config.bulkIsCheckable) ? props.config.bulkIsCheckable(props.item) : true;
	const checkedTimeout = useRef(null);
	const checkedRef = useRef(checked);
	checkedRef.current = checked;

	useEffect(() => {
		return () => {
			if (checkedTimeout.current !== null) {
				clearTimeout(checkedTimeout.current);
				checkedTimeout.current = null;
			}
		};
	}, []);

	useEffect(() => {
		if (checkedTimeout.current === null) {
			setChecked(selected.indexOf(props.item.id) > -1 || (bulkIsCheckable && allSelected));
		}
	}, [allSelected, selected]);

	const handleSelectedChange = () => {
		setChecked(!checked);
		if (checkedTimeout.current !== null) {
			clearTimeout(checkedTimeout.current);
			checkedTimeout.current = null;
		}
		checkedTimeout.current = setTimeout(() => {
			checkedTimeout.current = null;
			props.config.service.selectOne(sessionName, props.item.id, checkedRef.current).then(() => {
				dispatch({ type: `${props.config.key}/data/setSelectedId`, payload: { add: checkedRef.current, id: props.item.id } });
			});
		}, 500);
	};

	return (
		<TableCell padding="checkbox">
			<Checkbox checked={bulkIsCheckable && checked} onChange={() => bulkIsCheckable && handleSelectedChange()} disabled={!bulkIsCheckable} />
		</TableCell>
	);
}

export default TableBulkSelectionCheckbox;
