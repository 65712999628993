import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import React, { useState, useEffect } from 'react';
import { Typography, Button, Dialog, DialogContent, DialogTitle, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@material-ui/core';
import { generatePath } from 'react-router-dom';
import { endOfDay, format, isAfter } from 'date-fns';
import huLocale from 'date-fns/locale/hu';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CompetitionService from '../service/CompetitionService';
import CompetitionEntryService from '../service/CompetitionEntryService';
import CalendarDialogRaceEntryList from './CalendarDialogRaceEntryList';
import CalendarDialogMixEntry from './CalendarDialogMixEntry';
import CalendarDialogClubValtoEntry from './CalendarDialogClubValtoEntry';

const formatDateTime = 'yyyy MMMM d. HH:mm';

const ageGroupList = race => {
	return race.ageGroupList.map(d => d.name).join(', ');
};

const licenseTypeList = race => {
	return race.licenseTypeList.join(', ');
};

const genderList = race => {
	const genders = [];
	if (race.isMale) genders.push('Fiú/Férfi');
	if (race.isFemale) genders.push('Lány/Nő');
	return genders.join(', ');
};

function CalendarDetailsDialog(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const [loading, setLoading] = useState(true);
	const [view, setView] = useState('details');
	const [data, setData] = useState(null);
	const [entryInfoList, setEntryInfoList] = useState([]);
	const [entryRace, setEntryRace] = useState(null);
	const [raceAvailableAthleteList, setRaceAvailableAthleteList] = useState(null);
	const [raceEntryIdList, setRaceEntryIdList] = useState(null);

	useEffect(() => {
		if (props.itemId) {
			setLoading(true);
			CompetitionService.getCalendarDetails(props.itemId).then(d => {
				setData(d);
				if (isAfter(endOfDay(d.regDeadline), new Date())) {
					CompetitionService.getCompetitionEntryInfoList(props.itemId).then(dd => {
						setEntryInfoList(dd || []);
						setLoading(false);
						setView('details');
					});
				} else {
					setEntryInfoList([]);
					setLoading(false);
					setView('details');
				}
			});
		} else {
			setData(null);
		}
	}, [props.itemId]);

	const handleEntryButtonClick = entryInfo => {
		if (user.isClub) {
			setView('raceEntryList');
			setEntryRace(entryInfo);
		} else if (entryInfo.qualificationSpecialType === 'OB_MIX_VALTO') {
			CompetitionEntryService.personEntryMix(entryInfo.competition, entryInfo.race, entryInfo.teamName, entryInfo.teamMember2, entryInfo.teamMember3, entryInfo.teamMember4).then(resp => {
				if (resp) {
					dispatch(showMessage({ message: entryInfo.alreadyEntry ? 'Sikeres visszavonás.' : 'Sikeres nevezés.' }));
					CompetitionService.getCompetitionEntryInfoList(props.itemId).then(dd => {
						setEntryInfoList(dd || []);
					});
				} else {
					dispatch(showMessage({ message: 'Hiba történt a nevezés módosítása során' }));
				}
			});
		} else {
			CompetitionEntryService.personEntry(entryInfo.competition, entryInfo.race, entryInfo.alreadyEntry).then(resp => {
				if (resp) {
					dispatch(showMessage({ message: entryInfo.alreadyEntry ? 'Sikeres visszavonás.' : 'Sikeres nevezés.' }));
					CompetitionService.getCompetitionEntryInfoList(props.itemId).then(dd => {
						setEntryInfoList(dd || []);
					});
				} else {
					dispatch(showMessage({ message: 'Hiba történt a nevezés módosítása során' }));
				}
			});
		}
	};

	useEffect(() => {
		if (entryRace != null && view === 'raceEntryList') {
			setLoading(true);
			CompetitionEntryService.getRaceAvailableAthleteList(entryRace.competition, entryRace.race).then(_raceAvailableAthleteList => {
				CompetitionEntryService.getAlreadyEntryAthleteIdList(entryRace.competition, entryRace.race).then(_raceEntryIdList => {
					setRaceAvailableAthleteList(_raceAvailableAthleteList);
					setRaceEntryIdList(_raceEntryIdList || []);
					setLoading(false);
				});
			});
		}
	}, [view, entryRace]);

	const handleCancelRaceEntryList = () => {
		setRaceAvailableAthleteList(null);
		setRaceEntryIdList(null);
		setEntryRace(null);
		setView('details');
	};

	const saveRaceEntryList = () => {
		setLoading(true);
		CompetitionEntryService.clubEntryList(entryRace.competition, entryRace.race, raceEntryIdList).then(resp => {
			if (resp) {
				dispatch(showMessage({ message: 'Nevezések sikeresen rögzítve' }));
				setRaceAvailableAthleteList(null);
				setRaceEntryIdList(null);
				setEntryRace(null);
				setView('details');
			} else {
				dispatch(showMessage({ message: 'Hiba történt a nevezések rögzítése során' }));
			}
			setLoading(false);
		});
	};

	const getQualificationSpecialType = race => {
		let result = 'OTHER';
		race.competitionPartKeyList.forEach(compPartKey => {
			const compPart = data.competitionPartList.find(d => d.key === compPartKey);
			const qualificationName = compPart && compPart.qualification ? compPart.qualification.name.toLowerCase() : null;
			if (qualificationName != null) {
				if (qualificationName === 'ob mix váltó') result = 'OB_MIX_VALTO';
				else if (qualificationName === 'ob váltó') result = 'OB_VALTO';
				else if (qualificationName === 'váltó') result = 'VALTO';
			}
		});
		return result;
	};

	if (props.itemId && loading) return <CalendarDialogComponent content={<FuseLoading />} onBack={() => props.onClose()} onClose={() => props.onClose()} />;
	if (data != null && view === 'details')
		return (
			<CalendarDialogComponent
				title={<CaleanderHeader data={data} />}
				content={
					<div className="flex flex-col divide-y-1">
						{data.raceList ? (
							<div className="flex flex-col py-4 ">
								<Table className="w-full" aria-label="simple table">
									<TableHead>
										<TableRow className="font-bold">
											<TableCell>Futam</TableCell>
											<TableCell>Korcsoport</TableCell>
											<TableCell>Licensz</TableCell>
											<TableCell>Nem</TableCell>
											<TableCell>Dátum</TableCell>
											{entryInfoList.length > 0 && <TableCell>Nevezés</TableCell>}
										</TableRow>
									</TableHead>
									<TableBody>
										{data.raceList.map((race, idx) => (
											<TableRow key={idx}>
												<TableCell scope="row">{race.name}</TableCell>
												<TableCell scope="row">{ageGroupList(race)}</TableCell>
												<TableCell scope="row">{licenseTypeList(race)}</TableCell>
												<TableCell scope="row">{genderList(race)}</TableCell>
												<TableCell scope="row">{format(race.date, formatDateTime, { locale: huLocale })}</TableCell>
												{entryInfoList.length > 0 && (
													<TableCell scope="row">
														<EntryButton
															isClub={user.isClub}
															qualificationSpecialType={getQualificationSpecialType(race)}
															entryInfo={entryInfoList.find(e => e.race === race.id)}
															onClick={entryInfo => handleEntryButtonClick(entryInfo)}
														/>
													</TableCell>
												)}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</div>
						) : null}
					</div>
				}
				actions={
					<Button className="flex-1" role="button" component="a" href={`#${generatePath('/calendar/view/:id', { id: data.id })}`} color="primary" variant="contained">
						Részletes információ
					</Button>
				}
				onBack={() => props.onClose()}
				onClose={() => props.onClose()}
			/>
		);
	if (data != null && view === 'raceEntryList') {
		return (
			<CalendarDialogComponent
				title={<CaleanderHeader data={data} />}
				content={<CalendarDialogRaceEntryList raceAvailableAthleteList={raceAvailableAthleteList} raceEntryIdList={raceEntryIdList} onChangeRaceEntryIdList={newRaceEntryIdList => setRaceEntryIdList(newRaceEntryIdList)} />}
				actions={
					<Button className="flex-1" role="button" color="primary" variant="contained" onClick={() => saveRaceEntryList()} disabled={loading}>
						Nevezések rögzítése
					</Button>
				}
				onBack={() => handleCancelRaceEntryList()}
				onClose={() => props.onClose()}
			/>
		);
	}
	return <></>;
}

function CalendarDialogComponent(props) {
	const { title, content, actions, onBack, onClose } = props;
	return (
		<Dialog open onClose={onClose} fullWidth maxWidth="lg">
			<DialogTitle id="form-dialog-title" className="shadow-2xl border-b py-16 px-16 md:px-36">
				<div className="flex flex-row items-center">
					<div className="pt-4 flex flex-row gap-8 items-center flex-1">{title}</div>
					<Button className="mx-0 hidden sm:block" onClick={() => onBack()} color="primary" variant="contained">
						Vissza
					</Button>
				</div>
			</DialogTitle>

			<DialogContent className="px-16 md:px-36 py-16">{content}</DialogContent>

			<div className="w-full py-16 md:py-24 border-t px-16 md:px-36 flex flex-col gap-8">{actions}</div>
		</Dialog>
	);
}

function EntryButton(props) {
	const { entryInfo, isClub, onClick, qualificationSpecialType } = props;
	const [showConfirm, setShowConfirm] = useState(false);
	const [showMixEntry, setShowMixEntry] = useState(false);
	const [showClubValtoEntry, setShowClubValtoEntry] = useState(false);

	const handleClick = () => {
		if (isClub && qualificationSpecialType !== 'OTHER') setShowClubValtoEntry(true);
		else if (isClub) onClick(entryInfo);
		else if (qualificationSpecialType === 'OB_MIX_VALTO' && !entryInfo.alreadyEntry) setShowMixEntry(true);
		else setShowConfirm(true);
	};

	const handleMixEntry = mixDto => {
		onClick({ ...entryInfo, ...mixDto, qualificationSpecialType });
		setShowMixEntry(false);
	};

	const handleClubValtoEntry = mixDto => {
		onClick({ ...entryInfo, ...mixDto, qualificationSpecialType });
		showClubValtoEntry(false);
	};

	return (
		<>
			<Button variant="contained" color="primary" onClick={() => handleClick()} disabled={!entryInfo.canEntry} className="whitespace-nowrap">
				{!isClub && entryInfo.alreadyEntry ? 'Nevezés törlése' : 'Nevezés'}
			</Button>
			{showMixEntry && <CalendarDialogMixEntry onYes={dto => handleMixEntry(dto)} onClose={() => setShowMixEntry(false)} qualificationSpecialType={qualificationSpecialType} />}
			{showClubValtoEntry && <CalendarDialogClubValtoEntry onYes={dto => handleClubValtoEntry(dto)} onClose={() => setShowClubValtoEntry(false)} entryInfo={entryInfo} qualificationSpecialType={qualificationSpecialType} />}
			<ConfirmDialog
				show={showConfirm}
				title="Nevezés"
				description={!isClub && entryInfo.alreadyEntry ? 'Biztosan törlöd a nevezésed a versenyre?' : 'Biztosan benevezel a versenyre?'}
				onYes={() => {
					setShowConfirm(false);
					onClick(entryInfo);
				}}
				onClose={() => setShowConfirm(false)}
			/>
		</>
	);
}

function CaleanderHeader(props) {
	const { data } = props;
	return (
		<div className="flex flex-col">
			<div className="flex flex-row">
				<Typography variant="h6" className="font-semibold text-18 sm:text-24 uppercase mr-12">
					{data.name}
				</Typography>
				{data.officialWebsite && (
					<div className="flex flex-row items-center">
						<IconButton size="small" aria-label="open_website" color="inherit" href={data.officialWebsite} target="_blank">
							<OpenInNewIcon fontSize="small" style={{ width: '12px', height: '12px' }} />
						</IconButton>
					</div>
				)}
			</div>

			<div className="flex flex-row text-12">
				<div className="font-bold" style={{ width: '100px' }}>
					Időpont
				</div>
				<div className="font-normal">{format(data.date, formatDateTime, { locale: huLocale }) + (data.endDate ? ` - ${format(data.endDate, formatDateTime, { locale: huLocale })}` : '')}</div>
			</div>

			<div className="flex flex-row text-12">
				<div className="font-bold" style={{ width: '100px' }}>
					Helyszín
				</div>
				<div className="font-normal">{data.address}</div>
			</div>
		</div>
	);
}
export default CalendarDetailsDialog;
