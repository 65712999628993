const UserLogCategoryType = {
	Save: 'Létrehozás',
	Update: 'Módosítás',
	Delete: 'Törlés',
	Login: 'Bejelentkezés',
	SaveProfile: 'Létrehozás',
	UpdateProfile: 'Módosítás',
	DeleteProfile: 'Törlés',
	UpdatePassword: 'Jelszó módosítás',
	ChangeLicenceStatus: 'Licensz státusz változás',
	ChangeRefereeRefQualification: 'Versenybírói engedély változás',
	AthleteTransfer: 'Átigazolás'
};

export default UserLogCategoryType;
