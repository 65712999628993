import { useHistory, Redirect, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import GroupTransactionService from 'app/admin/payment/service/GroupTransactionService';

function RouteInitComponent() {
	const history = useHistory();
	const dispatch = useDispatch();
	const query = new URLSearchParams(window.location.search);
	if (query.get('paymentId') !== null && query.get('path') !== null) {
		GroupTransactionService.getStatusByBarionId(query.get('paymentId')).then(result => {
			if (result !== null && result === 'Paid') {
				dispatch(showMessage({ message: 'A fizetés sikeresen végrehajtva.' }));
			} else {
				dispatch(showMessage({ message: 'Hiba történt a fizetés végrehajtása során.' }));
			}
		});
		window.history.replaceState(null, null, `${process.env.REACT_APP_PUBLIC_URL}#${query.get('path')}`);
		history.replace(query.get('path'));
		return <></>;
	}
	return <Redirect to="/calendar" />;
}
export default withRouter(RouteInitComponent);
