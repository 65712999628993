const regEn = {
	athleteRegTitle: 'Athlete registration',
	back: 'Back',
	login: 'Login',
	lastName: 'Last name',
	firstName: 'First name',
	birthPlace: 'Birth place',
	birthDate: 'Birth date',
	gender: 'Gender',
	email: 'E-mail',
	phoneNumber: 'Phone number',
	nationality: 'Nationality',
	nationalityOther: 'Other nationality',
	sepMothersName: "Mother's name",
	motherLastName: 'Last name',
	motherFirstName: 'First name',
	sepPassword: 'Password',
	password: 'Password',
	passwordAgain: 'Password again',
	sepAddress: 'Address',
	addressZipcode: 'Zipcode',
	addressCity: 'City',
	addressCounty: 'County',
	addressStreet: 'Street',
	addressStreetType: 'Type',
	addressNumber: 'Number',
	addressFloor: 'Floor',
	addressDoor: 'Door',
	profilePicture: 'Profile picture',
	competition: 'Competition',
	accept: 'A Magyar Triatlon Szövetség alapszabályzatát megismertem, tartalmát elfogadom, és magamra kötelező érvényűnek tekintem.',
	registration: 'Registration'
};

export default regEn;
