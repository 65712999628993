import _ from '@lodash';
import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputLabel, MenuItem } from '@material-ui/core';

function TableFilterBooleanIsNullField(props) {
	const { t } = useTranslation(props.config.topic ? ['common', props.config.topic] : 'common');
	const filterList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterList);
	const [value, setValue] = useState('');

	useEffect(() => {
		const index = filterList.findIndex(el => el.propertyName === props.filterConfig.propertyName);
		if (index > -1) {
			setValue(filterList[index].value != null ? filterList[index].value.toString() : '');
		} else {
			setValue('');
		}
	}, [filterList]);

	useEffect(() => {
		const newValue = value.length > 0 ? value === 'true' : null;
		if (newValue !== null) {
			const filterValue = {
				propertyName: props.filterConfig.propertyName,
				type: props.filterConfig.type,
				value: newValue
			};
			props.onFilterChanged(filterValue);
		} else {
			props.onFilterRemoved(props.filterConfig.propertyName);
		}
	}, [value]);

	return (
		<div>
			<InputLabel className="mt-16 w-full">{!_.isUndefined(props.filterConfig.text) ? props.filterConfig.text : t(`${props.config.topic}:${props.filterConfig.propertyName}`)}</InputLabel>
			<Select value={value} onChange={e => setValue(e.target.value)} className="mt-16 w-full">
				<MenuItem value="Mind" />
				<MenuItem value="true">{t('YES')}</MenuItem>
				<MenuItem value="false">{t('NO')}</MenuItem>
			</Select>
		</div>
	);
}

export default TableFilterBooleanIsNullField;
