import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class CompetitorRegistrationDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.chipNumber = null;
		/** @type {Date} */
		this.doctorDate = null;
		/** @type {string} */
		this.doctorDocument = null;
		/** @type {string} */
		this.licenseNumber = null;
	}

	/**
	 * @typedef {Object} _CompetitorRegistrationDtoProps
	 * @property {string} chipNumber
	 * @property {Date} doctorDate
	 * @property {string} doctorDocument
	 * @property {string} licenseNumber
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _CompetitorRegistrationDtoProps} CompetitorRegistrationDtoProps
	 */
	/**
	 *
	 * @param {CompetitorRegistrationDtoProps} props
	 * @returns {CompetitorRegistrationDto}
	 */
	setup(props) {
		super.setup(props);
		this.chipNumber = props.chipNumber;
		this.doctorDate = props.doctorDate != null ? new Date(props.doctorDate) : null;
		this.doctorDocument = props.doctorDocument;
		this.licenseNumber = props.licenseNumber;
		return this;
	}
}

export default CompetitorRegistrationDto;
