import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const schema = yup.object().shape({
	teamName: yup.string().nullable().required('Mező kötelező.'),
	teamMember2: yup.string().nullable().required('Mező kötelező.'),
	teamMember3: yup.string().nullable().required('Mező kötelező.'),
	teamMember4: yup.string().nullable().required('Mező kötelező.')
});

function CalendarDialogMixEntry(props) {
	const { onYes, onClose } = props;
	const methods = useForm({
		mode: 'onChange',
		defaultValues: {
			teamName: null,
			teamMember2: null,
			teamMember3: null,
			teamMember4: null
		},
		resolver: yupResolver(schema)
	});

	const handleSave = dto => {
		onYes(dto);
	};
	return (
		<FormProvider {...methods}>
			<Dialog open fullWidth>
				<DialogTitle disableTypography className="border-b">
					Nevezés Mix váltóra
				</DialogTitle>
				<DialogContent className="p-20">
					<Controller
						control={methods.control}
						name="teamName"
						render={({ field, fieldState }) => (
							<EditFormTextField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'entry' }}
								fieldConfig={{
									key: 'teamName',
									text: 'Csapatnév'
								}}
							/>
						)}
					/>
					<Controller
						control={methods.control}
						name="teamMember2"
						render={({ field, fieldState }) => (
							<EditFormTextField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'entry' }}
								fieldConfig={{
									name: 'teamMember2',
									text: '2. csapattárs neve'
								}}
							/>
						)}
					/>
					<Controller
						control={methods.control}
						name="teamMember3"
						render={({ field, fieldState }) => (
							<EditFormTextField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'entry' }}
								fieldConfig={{
									name: 'teamMember3',
									text: '3. csapattárs neve'
								}}
							/>
						)}
					/>
					<Controller
						control={methods.control}
						name="teamMember4"
						render={({ field, fieldState }) => (
							<EditFormTextField
								field={field}
								fieldState={fieldState}
								config={{ topic: 'entry' }}
								fieldConfig={{
									name: 'teamMember4',
									text: '4. csapattárs neve'
								}}
							/>
						)}
					/>
				</DialogContent>
				<DialogActions className="justify-between border-t p-20">
					<Button
						onClick={() => {
							onClose();
						}}
					>
						Mégse
					</Button>
					<Button onClick={methods.handleSubmit(handleSave)}>Nevezés</Button>
				</DialogActions>
			</Dialog>
		</FormProvider>
	);
}

export default CalendarDialogMixEntry;
