import competitionRouteConfig from 'app/admin/competition/competitionRouteConfig';
import userRouteConfig from './user/userRouteConfig';
import organizationRouteConfig from './organization/organizationRouteConfig';
import personRouteConfig from './person/personRouteConfig';
import profileRouteConfig from './profile/profileRouteConfig';
import paymentRouteConfig from './payment/paymentRouteConfig';
import licenseCostConfig from './licenseCost/licenseCostRouteConfig';
import athleteTransferConfig from './athleteTransfer/athleteTransferConfig';
import contestConfig from './contest/contestConfig';
import valueSetConfig from './valueset/valueSetConfig';
import emailTemplateConfig from './emailTemplate/emailTemplateConfig';
import ageGroupConfig from './ageGroup/ageGroupConfig';

const AdminConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		...userRouteConfig,
		...organizationRouteConfig,
		...personRouteConfig,
		...profileRouteConfig,
		...paymentRouteConfig,
		...licenseCostConfig,
		...competitionRouteConfig,
		...athleteTransferConfig,
		...contestConfig,
		...valueSetConfig,
		...ageGroupConfig,
		...emailTemplateConfig
	]
};

export default AdminConfig;
