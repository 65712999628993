/**
 * Authorization Roles
 */
const authRoles = {
	club: ['Club'],
	organizer: ['Organizer'],
	athlete: ['Athlete'],
	competitor: ['Competitor'],
	referee: ['Referee'],
	trainer: ['Trainer'],
	userProfile: ['UserProfile'],
	userAccount: ['UserAccount'],
	userRole: ['UserRole'],
	payment: ['Payment'],
	licenseCost: ['LicenseCost'],
	competition: ['Competition'],
	technicalCommittee: ['TechnicalCommittee'],
	newCompetition: ['NewCompetition'],
	competitionCalendar: ['CompetitionCalendar'],
	athleteTransfer: ['AthleteTransfer'],
	contest: ['Contest'],
	valueSet: ['ValueSet'],
	emailTemplate: ['EmailTemplate'],
	competitionEntryClub: ['CompetitionEntryClub'],
	onlyGuest: []
};

export default authRoles;
