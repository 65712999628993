import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class CompetitorTableDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.chipNumber = null;
		/** @type {Date} */
		this.doctorDate = null;
		/** @type {string} */
		this.licenseNumber = null;
	}

	/**
	 * @typedef {Object} _CompetitorTableDtoProps
	 * @property {string} chipNumber
	 * @property {Date} doctorDate
	 * @property {string} licenseNumber
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _CompetitorTableDtoProps} CompetitorTableDtoProps
	 */
	/**
	 *
	 * @param {CompetitorTableDtoProps} props
	 * @returns {CompetitorTableDto}
	 */
	setup(props) {
		super.setup(props);
		this.chipNumber = props.chipNumber;
		this.doctorDate = props.doctorDate != null ? new Date(props.doctorDate) : null;
		this.licenseNumber = props.licenseNumber;
		return this;
	}
}

export default CompetitorTableDto;
