import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { motion } from 'framer-motion';

export default function RegistrationHeader(props) {
    return (
        <motion.div initial={{ opacity: 0, scale: 1, y: -100 }} animate={{ opacity: 1, scale: 1, y: 0 }} className="w-full">
            <Card className="w-full flex flex-col md:flex-row overflow-hidden divide-solid divide-y-1 divide-x-1 shadow-2xl">
                <div className="flex items-center shadow-xl justify-center">
                    <img className="w-160 p-16" src="assets/images/logos/mtsz-logo-vector-new.svg" alt="Magyar Triatlon Szövetség" />
                </div>
                <div className="flex flex-col items-center md:flex-1 px-24 py-16 text-center">
                    <h1 className="text-20 font-semibold">{props.title}</h1>
                    {props.subTitle && <p className="mt-8 sm:mt-0">{props.subTitle}</p>}
                </div>
                <div className="flex items-center justify-center shadow-xl">{props.rightChildren && <div className="flex flex-row m-16 gap-8">{props.rightChildren}</div>}</div>
            </Card>
        </motion.div>
    );
}
