import { useEffect, useState } from 'react';
import _ from '@lodash';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import EditFormHelper from './EditFormHelper';
import { fixDependsOn } from './EditFormUtils';
import { useEditForm } from './EditFormProvider';

function EditForm(props) {
	const methods = useFormContext();
	const { tabValue } = useEditForm();
	const { reset } = methods;
	const [fields, setFields] = useState(null);
	const [data, setData] = useState(null);
	const [tabList, setTabList] = useState(null);

	useEffect(() => {
		if (data != null) {
			if (_.isFunction(props.config.tabList)) {
				setTabList(props.config.tabList(data));
			} else if (_.isArray(props.config.tabList)) {
				setTabList(props.config.tabList);
			} else {
				setTabList(null);
			}
			if (typeof props.config.fields === 'function') {
				setFields(props.config.fields(data));
			} else if (Array.isArray(props.config.fields)) {
				setFields([...props.config.fields]);
			} else {
				setFields([]);
			}
		} else {
			props.onInitialized(false);
		}
		if (!_.isUndefined(props.onDataChanged)) props.onDataChanged(data);
	}, [data]);

	useEffect(() => {
		if (!_.isUndefined(props.onTabList)) props.onTabList(tabList);
	}, [tabList]);

	useEffect(() => {
		if (fields != null) {
			reset(data);
			props.onInitialized(true);
		} else {
			props.onInitialized(false);
		}
	}, [fields]);

	return (
		<div>
			<EditFormHelper config={props.config} id={props.id} onInitialized={_data => setData(_data)} onSuccessful={(resp, btnData) => props.onSuccessful(resp, btnData)} />
			{tabList && fields
				? tabList.map((t, i) =>
						tabValue === i ? (
							<div key={t.key} className={tabValue !== i ? 'hidden' : ''}>
								{fieldsByTab(fields, t, i).map(fieldRow => (
									<EditFormFieldRow key={fieldRow.key} fieldRow={fieldRow} config={props.config} />
								))}
							</div>
						) : null
				  )
				: fields
				? fields.map(fieldRow => <EditFormFieldRow key={fieldRow.key} fieldRow={fieldRow} config={props.config} />)
				: null}
		</div>
	);
}

function EditFormFieldRow(props) {
	const { fieldRow } = props;
	const methods = useFormContext();
	const { control } = methods;
	const watchFields = useWatch({
		name: fixDependsOn(fieldRow.dependsOn, null),
		control
	});
	if (_.isUndefined(fieldRow.visibleIf) || fieldRow.visibleIf(watchFields, methods)) {
		if (_.isUndefined(fieldRow.fields) || fieldRow.isControlledInput === false)
			return <Controller key={fieldRow.key} name={fieldRow.key} control={control} render={({ field, fieldState }) => <fieldRow.component field={field} fieldState={fieldState} config={props.config} fieldConfig={fieldRow} />} />;
		return <fieldRow.component key={fieldRow.key} config={props.config} fieldConfig={fieldRow} />;
	}
	return null;
}

const fieldsByTab = (fields, t, i) => {
	return fields.filter(fieldRow => (i === 0 && _.isUndefined(fieldRow.tab)) || (Number.isFinite(fieldRow.tab) && i === fieldRow.tab) || t.key === fieldRow.tab);
};

export default EditForm;
