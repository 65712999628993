import { PersonTableDto } from 'app/admin/person/dto/PersonTableDto';
import nBossConnection from 'modules/base/service/nBossConnection';

class CompetitionEntryService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'competitionEntryControl';
	}

	/**
	 * @param {number} competition
	 * @param {number} race
	 * @param {string} teamName
	 * @param {string} teamMember2
	 * @param {string} teamMember3
	 * @param {string} teamMember4
	 * @return {Promise<boolean>}
	 */
	personEntryMix(competition, race, teamName, teamMember2, teamMember3, teamMember4) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/personEntryMix`, { competition, race, teamName, teamMember2, teamMember3, teamMember4 })
				.then(response => resolve(response.data !== null ? response.data : false))
				.catch(reject);
		});
	}

	/**
	 * @param {number} competition
	 * @param {number} race
	 * @param {boolean} isDelete
	 * @return {Promise<boolean>}
	 */
	personEntry(competition, race, isDelete) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/personEntry`, { competition, race, isDelete })
				.then(response => resolve(response.data !== null ? response.data : false))
				.catch(reject);
		});
	}

	/**
	 * @param {number} competition
	 * @param {number} race
	 * @param {number[]} athleteIdList
	 * @return {Promise<boolean>}
	 */
	clubEntryList(competition, race, athleteIdList) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/clubEntryList`, { competition, race, athleteIdList })
				.then(response => resolve(response.data !== null ? response.data : false))
				.catch(reject);
		});
	}

	/**
	 * @param {number} competition
	 * @param {number} race
	 * @param {string} teamName
	 * @param {number[]} athleteIdList
	 * @return {Promise<boolean>}
	 */
	clubValtoEntry(competition, race, teamName, athleteIdList) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/clubValtoEntry`, { competition, race, teamName, athleteIdList })
				.then(response => resolve(response.data !== null ? response.data : false))
				.catch(reject);
		});
	}

	/**
	 * @param {number} competitionId
	 * @param {number} raceId
	 * @return {Promise<PersonTableDto>}
	 */
	getRaceAvailableAthleteList(competitionId, raceId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getRaceAvailableAthleteList`, { competitionId, raceId })
				.then(response => resolve(response.data != null ? response.data.map(d => new PersonTableDto().setup(d)) : []))
				.catch(reject);
		});
	}

	/**
	 * @param {number} competitionId
	 * @param {number} raceId
	 * @return {Promise<number[]>}
	 */
	getAlreadyEntryAthleteIdList(competitionId, raceId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getAlreadyEntryAthleteIdList`, { competitionId, raceId })
				.then(response => resolve(response.data != null ? response.data : []))
				.catch(reject);
		});
	}

	/**
	 * @param {number} competition
	 * @param {number} race
	 * @return {Promise<Object[]>}
	 */
	getClubValtoEntry(competition, race) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getClubValtoEntry`, { competition, race })
				.then(response => resolve(response.data != null ? response.data : []))
				.catch(reject);
		});
	}

	/**
	 * @param {number} competition
	 * @param {number} race
	 * @return {Promise<boolean>}
	 */
	clubValtoEntryDelete(competition, race) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/clubValtoEntryDelete`, { competition, race })
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new CompetitionEntryService();
