import { Link } from 'react-router-dom';

function PersonDoctorDateExpireNotification() {
	return (
		<div className="pt-4 pl-32">
			<Link to="/profile"> Megnyit</Link>
		</div>
	);
}

export default PersonDoctorDateExpireNotification;
