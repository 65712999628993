import { useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import TableBrowser from 'modules/ui/tablebrowser/TableBrowser';
import AthleteTransferTableService from '../service/AthleteTransferTableService';
import AthleteTransferTableButton from '../component/AthleteTransferTableButton';
import AthleteTransferDescriptionTableButton from '../component/AthleteTransferDescriptionTableButton';
import AthleteTransferPaymentTableButton from '../component/AthleteTransferPaymentTableButton';

const config = data => {
	return {
		key: 'athleteTransferTable',
		text: 'Átigazolások',
		textKey: 'athlete',
		service: AthleteTransferTableService,
		editFormState: item => `/athlete-transfer/edit/${item ? item.id : 'new'}`,
		toolbarAction: ['refresh'],
		toolbarActionList: data.isClub
			? [
					{
						key: 'startAthleteTransfer',
						link: () => '/athlete-transfer-start',
						icon: 'add',
						text: 'Átigazolás indítása'
					}
			  ]
			: [],
		rowAction: /*data.isSuperAdmin ? ['modify'] : */ [],
		columns: [
			{
				key: 'athlete.name',
				text: 'Versenyző',
				type: 'text',
				sortable: false,
				disablePadding: false
			},
			{
				key: 'isAdult',
				text: 'Felnőtt',
				type: 'boolean',
				sortable: false,
				disablePadding: false
			},
			{
				key: 'athlete.cachedLicenseNumber',
				text: 'Licensz',
				type: 'text',
				sortable: false,
				disablePadding: false
			},
			{
				key: 'srcClub.fullName',
				text: 'Átadó egyesület',
				type: 'text',
				sortable: false,
				disablePadding: false
			},
			{
				key: 'targetClub.fullName',
				text: 'Átvevő egyesület',
				type: 'text',
				sortable: false,
				disablePadding: false
			},
			{
				key: 'status',
				text: 'Státusz',
				type: 'text',
				sortable: false,
				disablePadding: false
			},
			{
				key: 'description',
				text: 'Indok',
				component: AthleteTransferDescriptionTableButton,
				sortable: false,
				disablePadding: false
			},
			{
				key: 'created',
				text: 'Létrehozva',
				textKey: 'created',
				type: 'dateTime',
				sortable: false,
				disablePadding: false
			},
			{
				key: 'updated',
				text: 'Módosítva',
				textKey: 'updated',
				type: 'dateTime',
				sortable: false,
				disablePadding: false
			},
			{
				key: 'closed',
				text: 'Lezárt',
				textKey: 'userStatus',
				type: 'boolean',
				sortable: false,
				disablePadding: false
			},
			{
				key: 'paymentMode',
				text: 'Átutalás',
				component: AthleteTransferPaymentTableButton,
				sortable: false,
				disablePadding: false
			},
			{
				key: 'btn',
				text: 'Nyilatkozat',
				component: AthleteTransferTableButton,
				sortable: false,
				disablePadding: false
			}
		]
	};
};

function AthleteTransferTable() {
	const user = useSelector(({ auth }) => auth.user);
	const myConfig = config({ isSuperAdmin: user.isSuperAdmin, accessType: user.accessType, isClub: user.isClub });
	return <TableBrowser config={myConfig} />;
}

export default withReducer('athleteTransferTable', reducer('athleteTransferTable'))(AthleteTransferTable);
