import { authRoles } from 'app/auth';
import LicenseCostTable from './table/LicenseCostTable';
import LicenseCostEditForm from './editform/LicenseCostEditForm';

export default [
	{
		path: '/license-cost/edit/:id',
		component: LicenseCostEditForm,
		auth: authRoles.licenseCost
	},
	{
		path: '/license-cost/:sessionNameParam?',
		component: LicenseCostTable,
		auth: authRoles.licenseCost
	}
];
