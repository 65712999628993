import NamedDto from 'modules/base/dto/NamedDto';

export class CompetitionCalendarListDto extends NamedDto {
	constructor() {
		super();
		/** @type {Date} */
		this.date = null;
		/** @type {Date} */
		this.endDate = null;
		/** @type {NamedDto} */
		this.createdOrg = null;
	}

	/**
	 * @typedef {Object} _CompetitionCalendarListDtoProps
	 * @property {Date} date
	 * @property {Date} endDate
	 * @property {NamedDto} createdOrg
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _CompetitionCalendarListDtoProps} CompetitionCalendarListDtoProps
	 */
	/**
	 *
	 * @param {CompetitionCalendarListDtoProps} props
	 * @returns {CompetitionCalendarListDto}
	 */
	setup(props) {
		super.setup(props);
		this.date = props.date != null ? new Date(props.date) : null;
		this.endDate = props.endDate != null ? new Date(props.endDate) : null;
		this.createdOrg = props.createdOrg != null ? new NamedDto().setup(props.createdOrg) : null;
		return this;
	}
}

export default CompetitionCalendarListDto;
