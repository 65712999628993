import GenericTableDto from 'modules/base/dto/table/GenericTableDto';
import GenericTableService from 'modules/base/service/GenericTableService';
import CompetitionTableDto from '../dto/CompetitionTableDto';

class CompetitionTableService extends GenericTableService {
	constructor() {
		super();
		this.CONTROL = 'competitionTableControl';
	}

	/**
	 * @param {string} session
	 * @param {number} firstResult
	 * @param {number} maxResults
	 * @return {Promise<GenericTableDto<CompetitionTableDto>>}
	 */
	getList(session, firstResult, maxResults) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getList`, {
					session,
					firstResult,
					maxResults
				})
				.then(response => resolve(new GenericTableDto().setup(response.data, CompetitionTableDto)))
				.catch(reject);
		});
	}
}

export default new CompetitionTableService();
