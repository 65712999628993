import { useState, useRef, useEffect } from 'react';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import withReducer from 'app/store/withReducer';
import reducer from './store';
import { toggleNotificationPanel } from './store/stateSlice';
import NotificationService from './service/NotificationService';

function NotificationPanelToggleButton(props) {
	const [status, setStatus] = useState(null);
	const intervalRef = useRef();
	const dispatch = useDispatch();

	useEffect(() => {
		if (intervalRef.current) clearInterval(intervalRef.current);
		NotificationService.getStatus().then(s => {
			setStatus(s);
			intervalRef.current = setInterval(() => NotificationService.getStatus().then(resp => setStatus(resp)), 5000);
		});
		return () => {
			if (intervalRef.current) clearInterval(intervalRef.current);
		};
	}, [dispatch]);

	return (
		<IconButton className="w-40 h-40" onClick={ev => dispatch(toggleNotificationPanel())}>
			<Badge color="secondary" max={99} badgeContent={status ? status.count : 0}>
				{props.children}
			</Badge>
		</IconButton>
	);
}

NotificationPanelToggleButton.defaultProps = {
	children: <Icon>notifications</Icon>
};

export default withReducer('notificationPanel', reducer)(NotificationPanelToggleButton);
