import { Link } from 'react-router-dom';
import { Typography, Card, CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { motion } from 'framer-motion';

export default function RegistrationFeedback(props) {
    return (
        <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }} className="flex max-w-screen-sm w-full rounded-20 shadow-2xl overflow-hidden mt-32">
            <Card className="flex flex-col w-full items-center justify-center shadow-0" square>
                <CardContent className="flex flex-col items-center justify-center w-full p-0 max-w-screen-sm">
                    <div className="w-full py-16 px-16 md:px-36 border-b shadow-lg">
                        <Typography variant="h6" className="pt-4 font-semibold text-18 sm:text-24 uppercase">
                            Sikeres regisztráció
                        </Typography>
                    </div>
                    <div className="w-full py-16 md:py-24 px-16 md:px-36">
                        <div>{props.content}</div>
                    </div>
                    <div className="w-full pt-16 md:pt-24 border-t px-16 md:px-36 flex flex-col sm:flex-row gap-8">
                        <Button component={Link} to="/login" variant="contained" color="primary" aria-label="LOG IN">
                            Bejelentkezés
                        </Button>
                        <Button component={Link} to="/registration" variant="contained" color="secondary" aria-label="REGISTER">
                            Új regisztráció indítása
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </motion.div>
    );
}