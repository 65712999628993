import { useEffect, useState, useRef } from 'react';
import _ from '@lodash';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useTranslation } from 'react-i18next';
import { useEditForm } from 'modules/ui/editform/EditFormProvider';

function EditFormHelper(props) {
	const methods = useFormContext();
	const { saveBtnKeyClick } = useEditForm();
	const dispatch = useDispatch();
	const { t } = useTranslation('editform');
	const { service } = props.config;
	const [data, setData] = useState(undefined);
	const { getValues, clearErrors, setError } = methods;
	const [requestEditAccess, setRequestEditAccess] = useState(null);
	const editAccessTimeout = useRef(null);
	const requestEditAccessRef = useRef(requestEditAccess);
	requestEditAccessRef.current = requestEditAccess;

	useEffect(() => {
		if (saveBtnKeyClick !== null && (requestEditAccess === null || requestEditAccess.accessGranted)) {
			clearErrors();
			const btnConfig = saveBtnKeyClick && saveBtnKeyClick.btnKey && saveBtnKeyClick.btnKey !== 'default' ? props.config.otherSaveButtonList.find(b => b.key === saveBtnKeyClick.btnKey) : null;
			const values = _.isFunction(props.config.valueModifier) ? props.config.valueModifier(getValues(), btnConfig) : getValues();
			if (btnConfig && _.isFunction(btnConfig.saveMethod)) {
				btnConfig.saveMethod(values).then(response => {
					handleResponse(response, saveBtnKeyClick);
				});
			} else if (data.id != null) {
				service.update(data.id, values).then(response => {
					handleResponse(response, saveBtnKeyClick);
				});
			} else {
				service.save(values).then(response => {
					handleResponse(response, saveBtnKeyClick);
				});
			}
		} else if (requestEditAccess !== null) {
			dispatch(showMessage({ message: t('REQUEST_EDIT_ACCESS_FAILED', { activeUserName: requestEditAccess.activeUserName }) }));
		}
	}, [saveBtnKeyClick]);

	const handleResponse = (response, btnData) => {
		if (response.successful) {
			dispatch(showMessage({ message: t('SAVE_SUCCESSFUL') }));
			props.onSuccessful(response, btnData);
		} else {
			const values = getValues();
			let popupShowed = false;
			Object.keys(response.properties).forEach(key => {
				if (response.properties[key].length > 0) {
					//const isPath = key.indexOf('.');
					//const valueKey = isPath ? key.split('.')[0] : key;
					//if (!_.isUndefined(values[valueKey]) && !(_.isArray(values[valueKey]) && isPath)) {
					if (!_.isUndefined(_.get(values, key))) {
						setError(key, {
							type: 'manual',
							message: response.properties[key][0].message
						});
					} else if (!popupShowed) {
						popupShowed = true;
						dispatch(showMessage({ message: response.properties[key][0].message }));
					}
				}
			});
		}
	};

	useEffect(() => {
		if (props.id) {
			if (props.config.useRequestEditAccess) {
				if (editAccessTimeout && editAccessTimeout.current) clearTimeout(editAccessTimeout.current);
				updateRequestEditAccess();
			}
		}
		return () => {
			if (editAccessTimeout && editAccessTimeout.current) {
				clearTimeout(editAccessTimeout.current);
			}
		};
	}, [props.config, props.id]);

	useEffect(() => {
		if (props.id) {
			if (props.id === 'new') {
				if (_.isUndefined(props.config.defaultValue)) {
					service.create().then(response => {
						setData(response);
					});
				} else {
					props.config.defaultValue().then(response => {
						setData(response);
					});
				}
			} else {
				service.getData(props.id).then(response => {
					setData(response);
				});
			}
		}
	}, [props.id]);

	useEffect(() => {
		if (typeof data !== 'undefined') {
			props.onInitialized(data);
		}
	}, [data]);

	useEffect(() => {
		if (requestEditAccess !== null && !requestEditAccess.accessGranted) {
			dispatch(showMessage({ message: t('REQUEST_EDIT_ACCESS_FAILED', { activeUserName: requestEditAccess.activeUserName }) }));
		}
	}, [requestEditAccess]);

	const updateRequestEditAccess = () => {
		if (props.id) {
			if (props.id !== 'new') {
				service.requestEditAccess(props.id).then(resp => {
					if (requestEditAccessRef.current === null || requestEditAccessRef.current.accessGranted !== resp.accessGranted || requestEditAccessRef.current.activeUserName !== resp.activeUserName) {
						setRequestEditAccess(resp);
					}
				});
			} else {
				setRequestEditAccess(null);
			}
			editAccessTimeout.current = setTimeout(() => updateRequestEditAccess(), props.config.requestEditAccessInterval * 1000);
		}
	};

	return <></>;
}

export default EditFormHelper;
