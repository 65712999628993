import _ from '@lodash';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TableVariableDto from 'modules/base/dto/table/TableVariableDto';

function TableBrowserHelper(props) {
	const { service } = props.config;
	const dispatch = useDispatch();
	const needDataReload = useSelector(rootState => rootState[props.config.key].tableBrowserEvent.needDataReload);
	const tableSessionInitialized = useSelector(rootState => rootState[props.config.key].tableBrowserEvent.tableSessionInitialized);
	const filterList = useSelector(rootState => rootState[props.config.key].tableBrowserFilter.filterList);
	const sessionName = useSelector(rootState => rootState[props.config.key].tableBrowserSession.sessionName);

	useEffect(() => {
		dispatch({ type: `${props.config.key}/session/setConfig`, payload: props.config });
		dispatch({ type: `${props.config.key}/session/setService`, payload: props.config.service });
	}, [props.config]);

	useEffect(() => {
		if (!tableSessionInitialized) getInstance();
	}, []);

	const getData = () => {
		service.getList(sessionName, props.rowsPerPage > 0 ? props.page * props.rowsPerPage : 0, props.rowsPerPage > -1 ? props.rowsPerPage : 0).then(result => {
			props.onData(result);
			props.onLoading(false);
		});
	};

	const getInstance = () => {
		service.getInstance(sessionName, true, _.isFunction(props.config.defaultFilter) ? props.config.defaultFilter() : null).then(result => {
			if (Array.isArray(result.orderList) && result.orderList.length > 0) {
				props.onOrder(result.orderList[0]);
			}
			if (Array.isArray(result.filterList) && result.filterList.length > 0) {
				dispatch({ type: `${props.config.key}/filter/setFilterList`, payload: result.filterList });
			}
			if (typeof result.variableMap.recordsPerPage !== 'undefined') {
				props.onRowsPerPage(parseInt(result.variableMap.recordsPerPage.value, 10));
			}
			service.getFilterSelection(sessionName).then(filterSelectionResult => {
				dispatch({ type: `${props.config.key}/filter/setFilterSelection`, payload: filterSelectionResult });
			});
			dispatch({ type: `${props.config.key}/event/setTableSessionInitialized` });
		});
	};

	useEffect(() => {
		if (tableSessionInitialized) {
			getData();
			dispatch({ type: `${props.config.key}/event/dataReloadDone` });
		}
	}, [tableSessionInitialized, props.page]);

	useEffect(() => {
		if (tableSessionInitialized)
			service
				.setVariable(
					sessionName,
					new TableVariableDto().setup({
						name: 'recordsPerPage',
						type: 'int',
						value: props.rowsPerPage
					})
				)
				.then(result => {
					if (result) dispatch({ type: `${props.config.key}/event/setNeedDataReload` });
				});
	}, [props.rowsPerPage]);

	useEffect(() => {
		if (tableSessionInitialized) service.setOrder(sessionName, props.order.propertyName, props.order.ascending).then(() => dispatch({ type: `${props.config.key}/event/setNeedDataReload` }));
	}, [props.order]);

	useEffect(() => {
		if (tableSessionInitialized && needDataReload) {
			getData();
			dispatch({ type: `${props.config.key}/event/dataReloadDone` });
		}
	}, [needDataReload]);

	useEffect(() => {
		if (tableSessionInitialized) {
			service
				.setFilter(sessionName, {
					list: filterList,
					activeCount: filterList.length
				})
				.then(result => {
					if (props.page > 0) props.onPage(0);
					else getData();
				});
		}
	}, [filterList]);

	useEffect(() => {
		if (props.deleteItem != null) {
			if (props.config.handleDelete) {
				props.config.handleDelete(sessionName, props.deleteItem).then(response => {
					if (response === null || response.successful) dispatch({ type: `${props.config.key}/event/setNeedDataReload` });
					props.onDelete(response);
				});
			} else {
				service.deleteById(sessionName, props.deleteItem.id).then(response => {
					if (response === null || response.successful) dispatch({ type: `${props.config.key}/event/setNeedDataReload` });
					props.onDelete(response);
				});
			}
		}
	}, [props.deleteItem]);

	return <></>;
}

export default TableBrowserHelper;
