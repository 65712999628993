import NamedDto from 'modules/base/dto/NamedDto';
import CompetitionEntryTableDto from './CompetitionEntryTableDto';

export class CompetitionPartDetailsDto extends NamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.key = null;
		/** @type {NamedDto} */
		this.sportCategory = null;
		/** @type {NamedDto} */
		this.qualification = null;
		/** @type {NamedDto} */
		this.distance = null;
		/** @type {boolean} */
		this.isTeamCompetiton = null;
	}

	/**
	 * @typedef {Object} _CompetitionPartDetailsDtoProps
	 * @property {string} key
	 * @property {NamedDto} sportCategory
	 * @property {NamedDto} qualification
	 * @property {NamedDto} distance
	 * @property {boolean} isTeamCompetiton
	 * @typedef {import("modules/base/dto/NamedDto").NamedDtoProps & _CompetitionPartDetailsDtoProps} CompetitionPartDetailsDtoProps
	 */
	/**
	 *
	 * @param {CompetitionPartDetailsDtoProps} props
	 * @returns {CompetitionPartDetailsDto}
	 */
	setup(props) {
		super.setup(props);
		this.key = props.key;
		this.sportCategory = props.sportCategory != null ? new NamedDto().setup(props.sportCategory) : null;
		this.qualification = props.qualification != null ? new NamedDto().setup(props.qualification) : null;
		this.distance = props.distance != null ? new NamedDto().setup(props.distance) : null;
		this.isTeamCompetiton = props.isTeamCompetiton != null ? props.isTeamCompetiton : false;
		return this;
	}
}

export default CompetitionPartDetailsDto;
