import nBossConnection from 'modules/base/service/nBossConnection';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import UserEditService from 'app/admin/user/service/UserEditService';
import EditFormRegistrationStatusField from 'modules/ui/editform/fields/EditFormRegistrationStatusField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormImageInputField from 'modules/ui/editform/fields/EditFormImageInputField';

const config = {
	key: 'userEditForm',
	text: 'Hozzáférés',
	textKey: 'user',
	service: UserEditService,
	icon: 'person',
	fields: [
		{
			key: 'name',
			text: 'Név',
			textKey: 'name',
			component: EditFormTextField,
			required: true
		},
		{
			key: 'email',
			text: 'E-mail',
			textKey: 'email',
			component: EditFormTextField,
			required: true
		},
		{
			key: 'profilePicture',
			text: 'Profilkép',
			textKey: 'profilePicture',
			component: EditFormImageInputField,
			imageUrl: file => `${nBossConnection.baseUrl}fileRepository/getFile?key=${file.key}`,
			required: true
		},
		{
			key: 'roleList',
			text: 'Jogkörök',
			textKey: 'roleList',
			component: EditFormSelectField,
			multiple: true,
			items: () => UserEditService.getRoleListSelection()
		},
		{
			key: 'registrationStatus',
			text: 'Aktív',
			textKey: 'registrationStatus',
			component: EditFormRegistrationStatusField
		},
		{
			key: 'password',
			text: 'Jelszó',
			textKey: 'password',
			component: EditFormTextField,
			required: true,
			type: 'password',
			autoComplete: 'new-password'
		},
		{
			key: 'passwordAgain',
			text: 'Jelszó mégegyszer',
			textKey: 'passwordAgain',
			component: EditFormTextField,
			required: true,
			type: 'password',
			autoComplete: 'new-password'
		}
	]
};

function UserEditForm() {
	return <EditFormPage config={config} />;
}

export default UserEditForm;
