import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import { IconButton, Tooltip, TableCell } from '@material-ui/core';

function TableDeleteButton(props) {
	const { t } = useTranslation('tableBrowser');
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

	return (
		<TableCell className="w-auto text-center" padding="none">
			<Tooltip title={t('DELETE')}>
				<span>
					<IconButton
						aria-label="delete"
						onClick={e => {
							setShowDeleteConfirm(true);
							e.preventDefault();
						}}
						disabled={props.item && props.item.protectionLevel && (props.item.protectionLevel === 'CannotBeDeleted' || props.item.protectionLevel === 'CannotBeModified')}
					>
						<DeleteIcon fontSize="small" />
					</IconButton>
				</span>
			</Tooltip>
			<ConfirmDialog show={showDeleteConfirm} title={t('DELETE')} description={t('DELETE_DESC')} onYes={() => props.onDelete()} onClose={() => setShowDeleteConfirm(false)} />
		</TableCell>
	);
}

export default TableDeleteButton;
