import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { submitLogin, loginError } from 'app/auth/store/loginSlice';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
		color: theme.palette.primary.contrastText
	},
	leftSection: {}
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	email: yup
		.string() /*.email('Email formátuma nem megfelelő.')*/
		.required('Email vagy Licensz szám megadása kötelező.'),
	password: yup.string().required('Kérlek add meg a jelszód.').min(4, 'A jelszó túl rövid. Minimum 4 karakter hosszúságú kell legyen.')
});

const defaultValues = {
	email: '',
	password: ''
};

function Login() {
	const classes = useStyles();

	const dispatch = useDispatch();
	const login = useSelector(({ auth }) => auth.login);
	const loginFailed = useSelector(({ auth }) => auth.login);
	const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { isValid, dirtyFields, errors } = formState;

	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		setValue('email', '', { shouldDirty: true, shouldValidate: true });
		setValue('password', '', { shouldDirty: true, shouldValidate: true });
	}, [reset, setValue, trigger]);

	useEffect(() => {
		login.errors.forEach(error => {
			setError(error.type, {
				type: 'manual',
				message: error.message
			});
		});
	}, [login.errors, setError]);

	function onSubmit(model) {
		dispatch(submitLogin(model));
	}

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24')}>
			<motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }} className="flex min-w-320 max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden">
				<Card className={clsx(classes.leftSection, 'flex flex-col w-full max-w-sm items-center justify-center shadow-0')} square>
					<CardContent className="flex flex-col items-center justify-center w-full py-24 max-w-320">
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className="flex items-center pb-24">
								<img className="w-full h-auto mx-12" src="assets/images/logos/mtsz-logo-vector-new.svg" alt="Magyar Triatlon Szövetség" />
							</div>
						</motion.div>

						<div className="w-full">
							<form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
								<Controller
									name="email"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-16"
											type="text"
											error={!!errors.email}
											helperText={errors?.email?.message}
											label="Email vagy Licensz szám"
											InputProps={{
												className: 'pr-4',
												endAdornment: (
													<InputAdornment position="end" className="ml-4">
														<Icon className="text-20 mx-12" color="action">
															email
														</Icon>
													</InputAdornment>
												)
											}}
											variant="outlined"
										/>
									)}
								/>

								<Controller
									name="password"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											className="mb-16"
											label="Jelszó"
											type="password"
											error={!!errors.password}
											helperText={errors?.password?.message}
											variant="outlined"
											InputProps={{
												className: 'pr-4',
												type: showPassword ? 'text' : 'password',
												endAdornment: (
													<InputAdornment position="end" className="ml-4">
														<IconButton onClick={() => setShowPassword(!showPassword)}>
															<Icon className="text-20" color="action">
																{showPassword ? 'visibility' : 'visibility_off'}
															</Icon>
														</IconButton>
													</InputAdornment>
												)
											}}
											required
										/>
									)}
								/>

								<div className="w-full flex flex-col gap-8 mt-8">
									<Button type="submit" variant="contained" color="primary" className="w-full" aria-label="LOG IN" disabled={_.isEmpty(dirtyFields) || !isValid} value="legacy">
										Bejelentkezés
									</Button>
									<Button component={Link} to="/registration" variant="contained" color="secondary" className="w-full" aria-label="REGISTER">
										Regisztráció
									</Button>
								</div>
							</form>
						</div>
					</CardContent>

					<div className="flex flex-col items-center justify-center pb-24">
						<div>
							<span className="font-normal">Elfelejtetted a jelszavad? </span>
							<Link color="secondary" className="font-semibold" to="/password-request">
								Kérj újat!
							</Link>
						</div>
					</div>
				</Card>
			</motion.div>
		</div>
	);
}

export default Login;
