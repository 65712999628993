import _ from '@lodash';
import { useSelector } from 'react-redux';
import { Tooltip, IconButton, TableCell, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';

function TableCustomButton(props) {
	const sessionName = useSelector(rootState => rootState[props.config.key].tableBrowserSession.sessionName);
	return (
		<TableCell className="w-auto text-center" padding="none">
			<Tooltip title={props.button.text}>
				{_.isUndefined(props.button.action) ? (
					<IconButton disabled={!_.isUndefined(props.button.disabledIf) ? props.button.disabledIf(props.item) : false} component={Link} to={() => props.button.link(props.item)}>
						<Icon fontSize="small">{props.button.icon}</Icon>
					</IconButton>
				) : (
					<IconButton disabled={!_.isUndefined(props.button.disabledIf) ? props.button.disabledIf(props.item) : false} onClick={() => props.button.action(props.item, sessionName)}>
						<Icon fontSize="small">{props.button.icon}</Icon>
					</IconButton>
				)}
			</Tooltip>
		</TableCell>
	);
}

export default TableCustomButton;
