import _ from '@lodash';
import { useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import reducer from 'modules/ui/tablebrowser/store';
import EditFormPage from 'modules/ui/editform/EditFormPage';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EditFormSelectField from 'modules/ui/editform/fields/EditFormSelectField';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import EditFormSeparatorField from 'modules/ui/editform/fields/EditFormSeparatorField';
import EditFormDateTimeField from 'modules/ui/editform/fields/EditFormDateTimeField';
import EditFormDateField from 'modules/ui/editform/fields/EditFormDateField';
import EditFormPersonQueryAutoCompleteField from '../component/EditFormPersonQueryAutoCompleteField';
import CompetitionEditService from '../service/CompetitionEditService';
import CompetitionPartEditForm from './CompetitionPartEditForm';
import CompetitionDistanceEditForm from './CompetitionDistanceEditForm';
import RaceEditForm from './RaceEditForm';
import EntryPriceEditForm from './EntryPriceEditForm';
import CompetitionEditFormUpdater from './ComponentEditFormUpdater';

const config = data => {
	const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
	const blockTitleClassName = 'py-8';
	return {
		key: 'competitionEditForm',
		text: 'Megpályázott események',
		textKey: 'competition',
		service: CompetitionEditService,
		editableAccess: data.formData.editableAccess,
		editableFields: data.formData.editableFields,
		hideSaveButton: item => data.accessType === 'Org' && item.submitted,
		otherSaveButtonList: [
			{
				key: 'submit',
				text: 'Beküldés',
				textKey: 'submit',
				icon: 'publish',
				place: 'after',
				color: 'secondary',
				saveMethod: values => (data.id !== 'new' ? CompetitionEditService.updateAndSubmit(data.id, values) : CompetitionEditService.saveAndSubmit(values)),
				confirm: 'Biztosan beküldi feldolgozásra a versenykiírást?',
				visibleIf: item => !item.submitted
			}
		],
		tabList: dto => {
			const tabList = [
				{
					key: 'base',
					text: 'Eseményadatok',
					textKey: 'base',
					errorFields: ['address', 'contest', 'coordLat', 'coordLng', 'date', 'regDeadline']
				},
				{
					key: 'competitionPart',
					text: 'Versenyek',
					textKey: 'competitionPart',
					errorFields: [/^competitionPart/]
				},
				{
					key: 'distanceList',
					text: 'Távok',
					textKey: 'distanceList',
					errorFields: [/^distanceList/]
				},
				{
					key: 'raceList',
					text: 'Futamok',
					textKey: 'raceList',
					errorFields: [/^raceList/]
				},
				{
					key: 'entryPriceList',
					text: 'Nevezési díjak',
					textKey: 'entryPriceList',
					errorFields: ['regDeadline', /^entryPriceList/]
				}
			];
			return tabList;
		},
		fields: dto => {
			const fields = [
				{
					key: 'statusGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepStatus',
							text: 'Státusz',
							textKey: 'sepStatus',
							component: EditFormSeparatorField,
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'adminApproval',
							text: 'Admin értékelése',
							textKey: 'adminApproval',
							component: EditFormSelectField,
							items: data.formData.adminApproval,
							type: 'enum'
						},
						{
							key: 'adminDecisionReason',
							text: 'Admin indoklása',
							textKey: 'adminDecisionReason',
							component: EditFormTextField,
							dependsOn: 'adminApproval',
							visibleIf: adminApproval => adminApproval && (adminApproval === 'DENIED_WITH_MOD' || adminApproval === 'APPROVED_WITH_MOD'),
							multiline: true
						},
						...(data.isSuperAdmin
							? [
									{
										key: 'refGroup',
										component: EditFormGroupFields,
										className: blockClassName,
										dependsOn: 'adminApproval',
										visibleIf: adminApproval => adminApproval === 'APPROVED' || adminApproval === 'APPROVED_WITH_MOD',
										fields: [
											{
												key: 'refDir',
												text: 'MTSZ küldöttek',
												textKey: 'refDir',
												component: EditFormSeparatorField,
												className: blockTitleClassName,
												isControlledInput: false
											},
											{
												key: 'ref1Group',
												component: EditFormGroupFields,
												className: blockClassName,
												fields: [
													{
														key: 'sepRef1',
														text: 'Vezető versenybíró',
														textKey: 'sepRef1',
														component: EditFormSeparatorField,
														className: blockTitleClassName,
														isControlledInput: false
													},
													{
														key: 'refereeName',
														text: 'Név',
														textKey: 'refereeName',
														component: EditFormPersonQueryAutoCompleteField,
														query: (query, limit) => CompetitionEditService.getRefereeSelectionList(query, limit),
														required: true,
														onChange: (v, field, methods) => {
															const { setValue } = methods;
															if (v !== null) {
																setValue('referee', v.id);
																setValue('refereeEmail', v.email);
																setValue('refereePhoneNumber', v.phoneNumber);
															} else {
																setValue('referee', null);
																setValue('refereeEmail', '');
																setValue('refereePhoneNumber', '');
															}
														}
													},
													{
														key: 'refereeEmail',
														text: 'E-mail',
														textKey: 'refereeEmail',
														component: EditFormTextField,
														maxLength: 255,
														required: true
													},
													{
														key: 'refereePhoneNumber',
														text: 'Telefonszám',
														textKey: 'refereePhoneNumber',
														component: EditFormTextField,
														maxLength: 255,
														required: true
													}
												]
											},
											{
												key: 'ref2Group',
												component: EditFormGroupFields,
												className: blockClassName,
												fields: [
													{
														key: 'sepRef2',
														text: 'Technikai felügyelő',
														textKey: 'sepRef2',
														component: EditFormSeparatorField,
														className: blockTitleClassName,
														isControlledInput: false
													},
													{
														key: 'supervisorName',
														text: 'Név',
														textKey: 'supervisorName',
														component: EditFormPersonQueryAutoCompleteField,
														query: (query, limit) => CompetitionEditService.getRefereeSelectionList(query, limit),
														required: true,
														onChange: (v, field, methods) => {
															const { setValue } = methods;
															if (v !== null) {
																setValue('technicalCommittee', v.id);
																setValue('supervisorEmail', v.email);
																setValue('supervisorPhoneNumber', v.phoneNumber);
															} else {
																setValue('technicalCommittee', null);
																setValue('supervisorEmail', '');
																setValue('supervisorPhoneNumber', '');
															}
														}
													},
													{
														key: 'supervisorEmail',
														text: 'E-mail',
														textKey: 'supervisorEmail',
														component: EditFormTextField,
														required: true,
														maxLength: 255
													},
													{
														key: 'supervisorPhoneNumber',
														text: 'Telefonszám',
														textKey: 'supervisorPhoneNumber',
														component: EditFormTextField,
														required: true,
														maxLength: 255
													}
												]
											}
										]
									}
							  ]
							: []),
						{
							key: 'technicalCommitteeApproval',
							text: 'Technikai felügyelő értékelése',
							textKey: 'technicalCommitteeApproval',
							component: EditFormSelectField,
							items: data.formData.technicalCommitteeApproval,
							type: 'enum'
						},
						{
							key: 'technicalCommitteeDecisionReason',
							text: 'Technikai felügyelő indoklása',
							textKey: 'technicalCommitteeDecisionReason',
							component: EditFormTextField,
							dependsOn: 'technicalCommitteeApproval',
							visibleIf: technicalCommitteeApproval => technicalCommitteeApproval && technicalCommitteeApproval === 'APPROVED_WITH_MOD',
							multiline: true
						}
					]
				},
				{
					key: 'baseGroup',
					component: EditFormGroupFields,
					className: blockClassName,
					fields: [
						{
							key: 'sepBase',
							text: 'Esemény adatok',
							textKey: 'sepBase',
							component: EditFormSeparatorField,
							className: blockTitleClassName,
							isControlledInput: false
						},
						{
							key: 'contest',
							text: 'Esemény hivatalos neve',
							textKey: 'contest',
							component: EditFormSelectField,
							required: true,
							items: data.formData.contests
						},
						{
							key: 'address',
							text: 'Eseményközpont címe',
							textKey: 'address',
							component: EditFormTextField,
							maxLength: 255,
							required: true
						},
						{
							key: 'officialWebsite',
							text: 'Esemény weboldala',
							textKey: 'officialWebsite',
							component: EditFormTextField
						},
						{
							key: 'gpsGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col sm:flex-row',
							fields: [
								{
									key: 'coordLat',
									text: 'GPS koordináta LAT',
									textKey: 'coordLat',
									className: 'sm:mr-6',
									component: EditFormTextField,
									required: true,
									maxLength: 50
								},
								{
									key: 'coordLng',
									text: 'GPS koordináta LNG',
									textKey: 'coordLng',
									className: 'sm:ml-6',
									component: EditFormTextField,
									required: true,
									maxLength: 50
								}
							]
						},
						{
							key: 'dateGroup',
							component: EditFormGroupFields,
							className: 'w-full flex flex-col sm:flex-row',
							fields: [
								{
									key: 'date',
									text: 'Esemény időpontja',
									textKey: 'date',
									className: 'sm:mr-6',
									component: EditFormDateTimeField,
									required: true
								},
								{
									key: 'endDate',
									text: 'Esemény befejező napja(ha több napos)',
									className: 'sm:ml-6 sm:mr-6',
									textKey: 'endDate',
									component: EditFormDateTimeField
								},
								{
									key: 'regDeadline',
									text: 'Nevezési határidő',
									className: 'sm:ml-6',
									textKey: 'regDeadline',
									component: EditFormDateField
								}
							]
						},
						{
							key: 'dirGroup',
							component: EditFormGroupFields,
							className: blockClassName,
							fields: [
								{
									key: 'sepDir',
									text: 'Rendező adatai',
									textKey: 'sepDir',
									component: EditFormSeparatorField,
									className: blockTitleClassName,
									isControlledInput: false
								},
								{
									key: 'dir1Group',
									component: EditFormGroupFields,
									className: blockClassName,
									fields: [
										{
											key: 'sepDir1',
											text: '(Versenyszervező - Kapcsolattartó) Tagszervezet',
											textKey: 'sepDir1',
											component: EditFormSeparatorField,
											className: blockTitleClassName,
											isControlledInput: false
										},
										{
											key: 'orgName',
											text: 'Név',
											textKey: 'orgName',
											component: EditFormTextField,
											maxLength: 255
										},
										{
											key: 'orgEmail',
											text: 'E-mail',
											textKey: 'orgEmail',
											component: EditFormTextField,
											maxLength: 255
										},
										{
											key: 'orgPhoneNumber',
											text: 'Telefonszám',
											textKey: 'orgPhoneNumber',
											component: EditFormTextField,
											maxLength: 255
										},
										{
											key: 'orgWebsite',
											text: 'Website',
											textKey: 'orgWebsite',
											component: EditFormTextField,
											maxLength: 2000
										}
									]
								},
								{
									key: 'dir2Group',
									component: EditFormGroupFields,
									className: blockClassName,
									fields: [
										{
											key: 'sepDir2',
											text: 'Versenyigazgató',
											textKey: 'sepDir2',
											component: EditFormSeparatorField,
											className: blockTitleClassName,
											isControlledInput: false
										},
										{
											key: 'directorName',
											text: 'Név',
											textKey: 'directorName',
											component: EditFormTextField,
											maxLength: 255
										},
										{
											key: 'directorEmail',
											text: 'E-mail',
											textKey: 'directorEmail',
											component: EditFormTextField,
											maxLength: 255
										},
										{
											key: 'directorPhoneNumber',
											text: 'Telefonszám',
											textKey: 'directorPhoneNumber',
											component: EditFormTextField,
											maxLength: 255
										}
									]
								},
								{
									key: 'dir3Group',
									component: EditFormGroupFields,
									className: blockClassName,
									fields: [
										{
											key: 'sepDir3',
											text: 'Szervező bizottság elnöke',
											textKey: 'sepDir3',
											component: EditFormSeparatorField,
											className: blockTitleClassName,
											isControlledInput: false
										},
										{
											key: 'orgPresName',
											text: 'Név',
											textKey: 'orgPresName',
											component: EditFormTextField,
											maxLength: 255
										},
										{
											key: 'orgPresEmail',
											text: 'E-mail',
											textKey: 'orgPresEmail',
											component: EditFormTextField,
											maxLength: 255
										},
										{
											key: 'orgPresPhoneNumber',
											text: 'Telefonszám',
											textKey: 'orgPresPhoneNumber',
											component: EditFormTextField,
											maxLength: 255
										}
									]
								}
							]
						},
						{
							key: 'officeGroup',
							component: EditFormGroupFields,
							className: blockClassName,
							fields: [
								{
									key: 'sepOffice',
									text: 'Versenyiroda',
									textKey: 'sepOffice',
									component: EditFormSeparatorField,
									className: blockTitleClassName,
									isControlledInput: false
								},
								{
									key: 'officeAddress',
									text: 'Helye',
									textKey: 'officeAddress',
									component: EditFormTextField
								},
								{
									key: 'officeHours',
									text: 'Nyitvatartása',
									textKey: 'officeHours',
									component: EditFormTextField
								},
								{
									key: 'office2Group',
									component: EditFormGroupFields,
									className: blockClassName,
									fields: [
										{
											key: 'sepOffice2',
											text: 'Felelős személy',
											textKey: 'sepOffice2',
											component: EditFormSeparatorField,
											className: blockTitleClassName,
											isControlledInput: false
										},
										{
											key: 'officeRepName',
											text: 'Név',
											textKey: 'officeRepName',
											component: EditFormTextField
										},
										{
											key: 'officeRepEmail',
											text: 'E-mail',
											textKey: 'officeRepEmail',
											component: EditFormTextField
										},
										{
											key: 'officeRepPhoneNumber',
											text: 'Telefonszám',
											textKey: 'officeRepPhoneNumber',
											component: EditFormTextField
										}
									]
								}
							]
						},
						{
							key: 'techGroup',
							component: EditFormGroupFields,
							className: blockClassName,
							fields: [
								{
									key: 'sepTech',
									text: 'Technikai információk',
									textKey: 'sepTech',
									component: EditFormSeparatorField,
									className: blockTitleClassName,
									isControlledInput: false
								},
								{
									key: 'tech1Group',
									component: EditFormGroupFields,
									className: blockClassName,
									fields: [
										{
											key: 'sepTech1',
											text: 'Technikai tájékoztató',
											textKey: 'sepTech1',
											component: EditFormSeparatorField,
											className: blockTitleClassName,
											isControlledInput: false
										},
										{
											key: 'techAddress',
											text: 'Helye',
											textKey: 'techAddress',
											component: EditFormTextField
										},
										{
											key: 'techDate',
											text: 'Ideje',
											textKey: 'techDate',
											component: EditFormDateTimeField
										}
									]
								},
								{
									key: 'tech2Group',
									component: EditFormGroupFields,
									className: blockClassName,
									fields: [
										{
											key: 'sepTech2',
											text: 'Úszópálya információk',
											textKey: 'sepTech2',
											component: EditFormSeparatorField,
											className: blockTitleClassName,
											isControlledInput: false
										},
										{
											key: 'waterTemp',
											text: 'Várható vízhőmérsékelt',
											textKey: 'waterTemp',
											component: EditFormTextField
										},
										{
											key: 'waterOtherInfo',
											text: 'Egyéb információ',
											textKey: 'waterOtherInfo',
											component: EditFormTextField,
											multiline: true
										}
									]
								},
								{
									key: 'tech3Group',
									component: EditFormGroupFields,
									className: blockClassName,
									fields: [
										{
											key: 'sepTech3',
											text: 'Kerékpárpálya információk',
											textKey: 'sepTech3',
											component: EditFormSeparatorField,
											className: blockTitleClassName,
											isControlledInput: false
										},
										{
											key: 'bikeTrackOtherInfo',
											text: 'Egyéb információ',
											textKey: 'bikeTrackOtherInfo',
											component: EditFormTextField,
											multiline: true
										}
									]
								},
								{
									key: 'tech4Group',
									component: EditFormGroupFields,
									className: blockClassName,
									fields: [
										{
											key: 'sepTech4',
											text: 'Futópálya információk',
											textKey: 'sepTech4',
											component: EditFormSeparatorField,
											className: blockTitleClassName,
											isControlledInput: false
										},
										{
											key: 'runningTrackOtherInfo',
											text: 'Egyéb információ',
											textKey: 'runningTrackOtherInfo',
											component: EditFormTextField,
											multiline: true
										}
									]
								},
								{
									key: 'tech5Group',
									component: EditFormGroupFields,
									className: blockClassName,
									fields: [
										{
											key: 'sepTech5',
											text: 'Időmérés',
											textKey: 'sepTech5',
											component: EditFormSeparatorField,
											className: blockTitleClassName,
											isControlledInput: false
										},
										{
											key: 'timingType',
											text: 'Jellege',
											textKey: 'timingType',
											component: EditFormTextField,
											multiline: true
										},
										{
											key: 'timingTiming',
											text: 'Időmérő',
											textKey: 'timingTiming',
											component: EditFormTextField,
											multiline: true
										}
									]
								},
								{
									key: 'bolyozas',
									text: 'Bolyozás',
									textKey: 'bolyozas',
									component: EditFormSelectField,
									items: [
										{ id: true, name: 'Igen' },
										{ id: false, name: 'Nem' }
									]
								},
								{
									key: 'techOtherInfo',
									text: 'Egyéb információ',
									textKey: 'techOtherInfo',
									component: EditFormTextField,
									multiline: true
								}
							]
						},
						{
							key: 'otherGroup',
							component: EditFormGroupFields,
							className: blockClassName,
							fields: [
								{
									key: 'sepOther',
									text: 'Egyéb',
									textKey: 'sepOther',
									component: EditFormSeparatorField,
									className: blockTitleClassName,
									isControlledInput: false
								},
								{
									key: 'pricing',
									text: 'Díjazás',
									textKey: 'pricing',
									component: EditFormTextField,
									multiline: true
								},
								{
									key: 'parkingInfo',
									text: 'Megközelítési és parkolás információk',
									textKey: 'parkingInfo',
									component: EditFormTextField,
									multiline: true
								},
								{
									key: 'regulations',
									text: 'Verseny szabályzattól eltérő rendelkezések',
									textKey: 'regulations',
									component: EditFormTextField,
									multiline: true
								}
							]
						}
					]
				},
				CompetitionPartEditForm(data),
				CompetitionDistanceEditForm(data),
				RaceEditForm(data),
				...EntryPriceEditForm(data)
			];
			return fields;
		}
	};
};

function CompetitionEditForm() {
	const [formData, setFormData] = useState(null);
	const { id } = useParams();
	const user = useSelector(({ auth }) => auth.user);
	if (formData == null) {
		if (id !== 'new') {
			CompetitionEditService.getFormDataById(id).then(result => setFormData(result));
		} else {
			CompetitionEditService.getFormData().then(result => setFormData(result));
		}
		return <></>;
	}
	const myConfig = config({ id, formData, isSuperAdmin: user.isSuperAdmin, isTechnicalCommittee: user.isTechnicalCommittee, accessType: user.accessType, user: user.data });
	return <EditFormPage config={myConfig} preContent={<CompetitionEditFormUpdater formData={formData} />} />;
}

export default withRouter(withReducer('competitionEntryTable', reducer('competitionEntryTable'))(CompetitionEditForm));
