import KeyedAndNamedDto from 'modules/base/dto/KeyedAndNamedDto';
import RoleServicePermissionDto from './RoleServicePermissionDto';
import RoleContentPermissionDto from './RoleContentPermissionDto';

export class DashboardRoleEditDto extends KeyedAndNamedDto {
	constructor() {
		super();
		/** @type {string} */
		this.title = null;
		/** @type {string} */
		this.adminDescription = null;
		/** @type {boolean} */
		this.isEnabled = null;
		/** @type {boolean} */
		this.isSuperAdmin = null;
		/** @type {Date} */
		this.created = null;
		/** @type {Date} */
		this.updated = null;
		/** @type {number} */
		this.orderIndex = null;
		/** @type {RoleServicePermissionDto[]} */
		this.servicePermissionList = null;
		/** @type {RoleContentPermissionDto[]} */
		this.contentPermissionList = null;
	}

	/**
	 * @typedef {Object} _DashboardRoleEditDtoProps
	 * @property {string} title
	 * @property {string} adminDescription
	 * @property {boolean} isEnabled
	 * @property {boolean} isSuperAdmin
	 * @property {Date} created
	 * @property {Date} updated
	 * @property {number} orderIndex
	 * @property {RoleServicePermissionDto[]} servicePermissionList
	 * @property {RoleContentPermissionDto[]} contentPermissionList
	 * @typedef {import("../KeyedAndNamedDto").KeyedAndNamedDtoProps & _DashboardRoleEditDtoProps} DashboardRoleEditDtoProps
	 */
	/**
	 *
	 * @param {DashboardRoleEditDtoProps} props
	 * @returns {DashboardRoleEditDto}
	 */
	setup(props) {
		super.setup(props);
		this.title = props.title;
		this.adminDescription = props.adminDescription;
		this.isEnabled = props.isEnabled;
		this.isSuperAdmin = props.isSuperAdmin;
		this.created = props.created;
		this.updated = props.updated;
		this.orderIndex = props.orderIndex;
		this.servicePermissionList = props.servicePermissionList != null ? props.servicePermissionList.map(d => new RoleServicePermissionDto().setup(d)) : [];
		this.contentPermissionList = props.contentPermissionList != null ? props.contentPermissionList.map(d => new RoleContentPermissionDto().setup(d)) : [];
		return this;
	}
}

export default DashboardRoleEditDto;
