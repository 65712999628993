import EditFormDateField from 'modules/ui/editform/fields/EditFormDateField';
import EditFormGroupFields from 'modules/ui/editform/subForm/EditFormGroupFields';
import EditFormSubComponent from 'modules/ui/editform/subForm/EditFormSubComponent';
import EditFormSeparatorField from 'modules/ui/editform/fields/EditFormSeparatorField';
import EditFormTextField from 'modules/ui/editform/fields/EditFormTextField';
import EntryPriceSubItemComponent from '../component/EntryPriceSubItemComponent';

const blockClassName = 'rounded-8 border px-16 pt-10 pb-4 mb-16';
const blockTitleClassName = 'py-8';

function EntryPriceEditForm(data) {
	return [
		{
			key: 'preRegGroup',
			component: EditFormGroupFields,
			className: `${blockClassName}`,
			tab: 'entryPriceList',
			fields: [
				{
					key: 'sepPreReg',
					text: 'Nevezési időszakok',
					textKey: 'sepPreReg',
					component: EditFormSeparatorField,
					className: blockTitleClassName,
					isControlledInput: false
				},
				{
					key: 'preRegInnerGroup',
					component: EditFormGroupFields,
					className: 'flex flex-col sm:flex-row',
					fields: [
						{
							key: 'preRegDeadline1',
							text: 'Korai nevezés 1',
							textKey: 'preRegDeadline1',
							className: 'sm:mr-6',
							component: EditFormDateField
						},
						{
							key: 'preRegDeadline2',
							text: 'Korai nevezés 2',
							textKey: 'preRegDeadline2',
							className: 'sm:mr-6',
							component: EditFormDateField
						},
						{
							key: 'preRegDeadline3',
							text: 'Korai nevezés 3',
							textKey: 'preRegDeadline3',
							className: 'sm:mr-6',
							component: EditFormDateField
						},
						{
							key: 'regDeadline',
							text: 'Nevezés',
							textKey: 'regDeadline',
							className: 'sm:mr-6',
							required: true,
							component: EditFormDateField
						}
					]
				}
			]
		},
		{
			key: 'entryPriceList',
			text: 'Nevezési díjak',
			textKey: 'entryPriceList',
			tab: 'entryPriceList',
			component: EditFormSubComponent,
			subItemComponent: EntryPriceSubItemComponent,
			fixedLengthList: true,
			customOrder: fields => {
				const _fields = [];
				data.formData.groupAgeGroups.forEach(groupAgeGroup => {
					fields.forEach(field => {
						if (groupAgeGroup.id === field.groupAgeGroup) _fields.push(field);
					});
				});
				return _fields;
			},
			fields: [
				{
					key: 'mainGroup',
					component: EditFormGroupFields,
					className: 'flex flex-col md:flex-row',
					fields: [
						{
							key: 'preRegAmount1',
							text: 'Korai 1 összeg',
							textKey: 'preRegAmount1',
							className: 'md:mr-6',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'preRegAmount2',
							text: 'Korai 2 összeg',
							textKey: 'preRegAmount2',
							className: 'md:mr-6',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'preRegAmount3',
							text: 'Korai 3 összeg',
							textKey: 'preRegAmount3',
							className: 'md:mr-6',
							component: EditFormTextField,
							type: 'number'
						},
						{
							key: 'entryAmount',
							text: 'Normál összeg',
							textKey: 'entryAmount',
							className: 'md:mr-6',
							component: EditFormTextField,
							type: 'number',
							required: true
						},
						{
							key: 'localAmount',
							text: 'Helyszíni összeg',
							textKey: 'localAmount',
							component: EditFormTextField,
							type: 'number'
						}
					]
				}
			]
		}
	];
}

export default EntryPriceEditForm;
