import _ from '@lodash';
import { useEffect, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { determineEditableAccess } from 'modules/ui/editform/EditFormUtils';
import CompetitionEditService from '../service/CompetitionEditService';

function EditFormPersonQueryAutoCompleteField(props) {
	const methods = useFormContext();
	const { error } = props.fieldState;
	const { value, onChange } = props.field;
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [autocompleteValue, setAutocompleteValue] = useState(null);
	const queryTimeout = useRef(null);
	const [readOnly, setReadOnly] = useState(determineEditableAccess(props.config, props.fieldConfig, props.fieldPrefix));

	const getQuery = () => {
		if (inputValue && inputValue.length > 2) {
			setLoading(true);
			props.fieldConfig.query(inputValue || '', props.fieldConfig.limit || 100).then(resp => {
				setLoading(false);
				if (resp && resp.length > 0) {
					setOptions(resp);
				} else {
					setOptions([]);
					setOpen(false);
				}
			});
		} else {
			setOptions([]);
			setOpen(false);
		}
	};

	const updateByCurrentValue = () => {
		setAutocompleteValue(value);
	};

	useEffect(() => {
		if (open) {
			if (queryTimeout && queryTimeout.current) clearTimeout(queryTimeout.current);
			queryTimeout.current = setTimeout(() => {
				if (open) {
					getQuery();
				}
			}, 500);
		}
	}, [inputValue]);

	const handleAutoCompleteOnChange = (e, v, r) => {
		if (r === 'select-option') {
			onChange(v.name);
			setAutocompleteValue(v.name);
			setOptions([]);
			!_.isUndefined(props.fieldConfig.onChange) ? props.fieldConfig.onChange(v, props.field, methods) : null;
		} else if (r === 'clear') {
			onChange(null);
			setAutocompleteValue(null);
			!_.isUndefined(props.fieldConfig.onChange) ? props.fieldConfig.onChange(null, props.field, methods) : null;
		}
	};

	useEffect(() => {
		if (!open && value && autocompleteValue === null) {
			updateByCurrentValue();
		}
	}, [value]);

	return (
		<Autocomplete
			open={open}
			onOpen={() => {
				if (!readOnly) {
					setOpen(true);
					if (!inputValue || inputValue.length === 0) getQuery();
				}
			}}
			onClose={() => {
				setOpen(false);
			}}
			inputValue={inputValue}
			onInputChange={(e, v) => setInputValue(v)}
			getOptionSelected={(option, val) => option.id === val.id}
			getOptionLabel={option => (_.isString(option) ? option : option.name)}
			defaultValue={null}
			value={autocompleteValue}
			onChange={(e, v, r) => handleAutoCompleteOnChange(e, v, r)}
			options={options}
			loading={loading}
			filterOptions={opt => opt}
			noOptionsText="Nincs találat"
			renderInput={params => (
				<TextField
					{...params}
					variant="outlined"
					className="mt-8 mb-16"
					error={error}
					required={props.fieldConfig.required}
					helperText={error ? error.message : props.fieldConfig.helperText}
					label={props.fieldConfig.text}
					id={props.field.name}
					fullWidth
					InputProps={{
						...params.InputProps,
						readOnly,
						endAdornment: readOnly ? (
							<InputAdornment position="end">
								<Lock fontSize="small" color="disabled" />
							</InputAdornment>
						) : (
							<>
								{loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						)
					}}
				/>
			)}
		/>
	);
}

export default EditFormPersonQueryAutoCompleteField;
