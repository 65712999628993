import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { Typography, Card, CardContent, Icon } from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { motion } from 'framer-motion';
import RegistrationHeader from './RegistrationHeader';
import CompetitorEndMessage from '../common/component/CompetitorEndMessage';

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
		color: theme.palette.primary.contrastText
	},
	leftSection: {}
}));

function RegistrationTypePage() {
	const classes = useStyles();
	const backgroundImageClasses = 'flex-1 bg-cover opacity-10 filter grayscale blur-sm contrast-200';

	return (
		<div className={clsx(classes.root, 'relative flex flex-col items-center min-h-full')}>
			<div className="absolute w-full h-full flex-row hidden sm:flex">
				<div
					className={backgroundImageClasses}
					style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1576858574144-9ae1ebcf5ae5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80)' }}
				/>
				<div
					className={backgroundImageClasses}
					style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1534156218473-c6ecde4f4dab?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)' }}
				/>
			</div>
			<div className="w-full min-h-full flex flex-col gap-32 items-center p-16 md:p-24">
				<RegistrationHeader
					title="Regisztráció típusának kiválasztása"
					subTitle="Kérjük, válaszd ki a regisztráció típusát"
					rightChildren={
						<>
							<Button component={Link} to="/login" variant="contained" color="primary" aria-label="LOG IN">
								Bejelentkezés
							</Button>
							<Button component={Link} to="/password-request" variant="contained" color="secondary" aria-label="FORGOTTEN PASSWORD">
								Elfelejtett jelszó
							</Button>
						</>
					}
				/>
				<div className="w-full flex-1 flex items-center justify-center">
					<div className="w-full flex flex-col md:flex-row items-stretch justify-center gap-16 sm:gap-64">
						<TypeItem title="Szervezeti" icon="group" lead="Szervezetként szeretnék regisztrálni." types={['Klub', 'Versenyzervező']} to="/registration/organization" buttonLabel="Szervezeti regisztráció indítása" />
						<TypeItem
							title="Személyi"
							icon="person"
							lead="Egyénként szeretnék regisztrálni."
							types={[/*'Egyesületi versenyző',  'Egyéni versenyző', */ 'Edző', 'Versenybíró']}
							to="/registration/person"
							buttonLabel="Személyi regisztráció indítása"
						/>
						<TypeItem
							title="Próbáld ki a Triatlont"
							icon="person"
							lead="Kipróbálom a triatlont"
							types={['Regisztráció']}
							to="/registration/athlete"
							buttonLabel="Személyi regisztráció indítása"
							contentLink={{
								label: 'Általános részvételi versenyszabályzat',
								link: 'https://probaldkiatriatlont.hu/'
							}}
						/>
						<TypeItem title="Versenyengedély kiváltás" icon="person" lead="Versenyengedély kiváltás" types={['Licensz kiváltás', 'Registration']} to="/registration/athlete-license" buttonLabel="Személyi regisztráció indítása" />
					</div>
				</div>
			</div>
		</div>
	);
}

function TypeItem(props) {
	const theme = useTheme();
	return (
		<motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }} className="w-full sm:max-w-400">
			<Card className="h-full relative shadow-2xl">
				<CardContent className="h-full flex flex-col w-full text-black p-0">
					<div className="w-full py-16 px-16 md:px-36 border-b shadow-lg ">
						<div className="pt-4 flex flex-row gap-8 items-center">
							{/* <Icon className="font-semibold text-18 sm:text-24">{props.icon}</Icon> */}
							<Typography variant="h6" className="font-semibold text-18 sm:text-24 uppercase">
								{props.title}
							</Typography>
						</div>
					</div>
					<div className="w-full flex-1 py-16 md:py-24 px-16 md:px-36">
						<div className="text-sm font-semibold">{props.lead}</div>
						<ul className="w-full list-disc list-inside mt-8">
							{props.types.map((item, index) => (
								<li key={index}>{item}</li>
							))}
						</ul>
						{props.contentLink && (
							<div className="mt-8">
								<a href={props.contentLink.link} target="_blank" rel="noreferrer" className="font-bold" role="button" style={{ color: theme.palette.secondary.dark }}>
									{props.contentLink.label}
								</a>
							</div>
						)}
					</div>
					<div className="w-full pt-16 md:pt-24 border-t px-16 md:px-36 flex flex-col sm:flex-row gap-8">
						<Button className="w-full" component={Link} to={props.to} variant="contained" aria-label="LOG IN">
							{props.buttonLabel}
						</Button>
					</div>
				</CardContent>
			</Card>
		</motion.div>
	);
}

export default RegistrationTypePage;
