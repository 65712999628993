import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export default class AccessConfigRoleDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {string} */
		this.title = null;
	}

	/**
	 * @typedef {Object} _AccessConfigRoleDtoProps
	 * @property {string} title
	 * @typedef {import("modules/base/dto/IdentifiableDto").IdentifiableDtoProps & _AccessConfigRoleDtoProps} AccessConfigRoleDtoProps
	 */
	/**
	 *
	 * @param {AccessConfigRoleDtoProps} props
	 * @returns {AccessConfigRoleDto}
	 */
	setup(props) {
		super.setup(props);
		this.title = props.title;
		return this;
	}
}
