import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { useDeepCompareEffect } from '@fuse/hooks';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Tabs, Tab, Typography } from '@material-ui/core';
import { motion } from 'framer-motion';
import { useEffect, useState, useRef } from 'react';
import { useParams, withRouter, Prompt, useHistory } from 'react-router-dom';
import _ from '@lodash';
import { useForm, FormProvider } from 'react-hook-form';
import ConfirmDialog from 'modules/ui/component/ConfirmDialog';
import EditFormHeader from './EditFormHeader';
import EditForm from './EditForm';
import EditFormProvider, { useEditForm } from './EditFormProvider';
import EditFormTab from './EditFormTab';

function EditFormPageComponent(props) {
	const history = useHistory();
	const routeParams = useParams();
	const { tabValue, setTabValue } = useEditForm();
	const { t } = useTranslation(props.config.topic ? ['editform', props.config.topic] : 'editform');
	const [id, setId] = useState(null);
	const [initStatus, setInitStatus] = useState('loading');
	const [showLeaveConfirmDialog, setShowLeaveConfirmDialog] = useState(false);
	const [lastLocation, setLastLocation] = useState(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const [tabList, setTabList] = useState(null);
	const [data, setData] = useState(null);

	const methods = useForm({
		mode: 'onChange',
		defaultValues: {}
	});
	const { formState } = methods;

	useDeepCompareEffect(() => {
		function updateDataState() {
			setInitStatus('loading');
			//setTabValue(props.startTabValue || 0);
			setConfirmedNavigation(false);
			setId(routeParams.id);
		}
		updateDataState();
	}, [routeParams]);

	const handleFormInitialized = result => {
		if (result) {
			setInitStatus('done');
		} else {
			setInitStatus('noData');
		}
	};

	const handleFormSuccessful = (result, btnData) => {
		setConfirmedNavigation(true);
		const btnConfig = btnData && btnData.btnKey && btnData.btnKey !== 'default' ? props.config.otherSaveButtonList.find(b => b.key === btnData.btnKey) : null;
		if (props.onSave) props.onSave(result, btnData);
		if (btnConfig && _.isFunction(btnConfig.link)) {
			props.history.push(props.config.otherSaveButtonList.find(item => item.key === btnData.btnKey).link(result));
		} else if (_.isBoolean(props.config.noNavigationAfterSave) && props.config.noNavigationAfterSave) {
			if (id === 'new') {
				props.history.replace(props.config.noNavigationAfterSaveNewLink(result));
			} else {
				setInitStatus('loading');
				setConfirmedNavigation(false);
				setId(null);
				setId(routeParams.id);
			}
		} else if (_.isFunction(props.config.saveLink)) {
			props.history.push(props.config.saveLink(result));
		} else if (_.isFunction(props.config.backLink)) {
			props.history.push(props.config.backLink(result));
		} else {
			props.history.goBack();
		}
	};

	const handleBlockedNavigation = nextLocation => {
		if (!confirmedNavigation && formState.isDirty && Object.keys(formState.dirtyFields).length > 0) {
			setShowLeaveConfirmDialog(true);
			setLastLocation(nextLocation);
			return false;
		}
		return true;
	};

	const handleConfirmNavigationClick = () => {
		setShowLeaveConfirmDialog(false);
		setConfirmedNavigation(true);
	};

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			history.push(lastLocation.pathname);
		}
	}, [confirmedNavigation, lastLocation]);

	useEffect(() => {
		if (tabList) {
			if (props.startTabValue) {
				const tabIndex = tabList.findIndex(tab => props.startTabValue === tab.key);
				setTabValue(tabIndex > -1 ? tabIndex : 0);
			} else {
				setTabValue(0);
			}
		}
	}, [tabList]);

	useEffect(() => {
		if (data !== null && props.onDataChanged) {
			props.onDataChanged(data, methods);
		}
	}, [data]);

	return (
		<FormProvider {...methods}>
			<FusePageCarded
				classes={{
					root: initStatus !== 'done' ? 'hidden' : null,
					toolbar: 'p-0',
					header: 'min-h-72 h-72 sm:h-136 sm:min-h-136'
				}}
				header={<EditFormHeader config={props.config} id={id} data={data} beforeSaveContent={props.beforeSaveContent} centerContent={props.headerCenterContent} />}
				contentToolbar={
					tabList ? (
						<Tabs value={tabValue} onChange={(e, v) => setTabValue(v)} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" classes={{ root: 'w-full h-64' }}>
							{tabList.map((tab, i) => (
								<EditFormTab key={i} config={props.config} tab={tab} />
							))}
						</Tabs>
					) : null
				}
				content={
					<div className="p-16 sm:p-24">
						{props.preContent ? props.preContent : null}
						<EditForm
							config={props.config}
							id={id}
							onInitialized={result => handleFormInitialized(result)}
							onSuccessful={(result, btnData) => handleFormSuccessful(result, btnData)}
							onTabList={tList => setTabList(tList)}
							onDataChanged={d => setData(d)}
						/>
						{props.postContent ? props.postContent : null}
						<Prompt when message={nextLocation => handleBlockedNavigation(nextLocation)} />
						<ConfirmDialog show={showLeaveConfirmDialog} title={t('LEAVE_CONFIRM')} description={t('LEAVE_CONFIRM_MESSAGE')} onYes={() => handleConfirmNavigationClick()} onClose={() => setShowLeaveConfirmDialog(false)} />
					</div>
				}
			/>
			{initStatus === 'loading' ? <FuseLoading /> : null}
			{initStatus === 'noData' ? (
				<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.1 } }} className="flex flex-col flex-1 items-center justify-center h-full">
					<Typography color="textSecondary" variant="h5">
						{t('NO_DATAS')}
					</Typography>
					<Button className="mt-24" variant="outlined" color="inherit" onClick={() => props.history.goBack()}>
						{t('BACK_TO_PREV_PAGE')}
					</Button>
				</motion.div>
			) : null}
		</FormProvider>
	);
}

function EditFormPage(props) {
	return (
		<EditFormProvider>
			<EditFormPageComponent {...props} />
		</EditFormProvider>
	);
}

export default withRouter(EditFormPage);
