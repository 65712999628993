import NamedDto from 'modules/base/dto/NamedDto';
import nBossConnection from 'modules/base/service/nBossConnection';

class CountryService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'countryControl';
	}

	/**
	 * @param {string} query
	 * @param {number} limit
	 * @return {Promise<NamedDto[]>}
	 */
	getCountrySelection(query, limit) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getCountrySelection`, { query, limit })
				.then(response => resolve(response.data != null ? response.data.map(d => new NamedDto().setup(d)) : []))
				.catch(reject);
		});
	}
}

export default new CountryService();
