import TableOrderDto from './TableOrderDto';

export class TableOrderListDto {
	constructor() {
		/** @type {TableOrderDto[]} */
		this.list = null;
	}

	/**
	 *
	 * @typedef {Object} _TableOrderListDtoProps
	 * @property {TableOrderDto[]} list
	 * @typedef {_TableOrderListDtoProps} TableOrderListDtoProps
	 */
	/**
	 *
	 * @param {TableOrderListDtoProps} props
	 * @returns {TableOrderListDto}
	 */
	setup(props) {
		this.list = props.list != null ? props.list.map(d => new TableOrderDto().setup(d)) : null;
		return this;
	}
}

export default TableOrderListDto;
